import React, { useState } from "react";
import DealerList from "../dealer-list/dealerlist";
import PaidApplicationBrowse from "./padApplication";
import UnPaidApplicationBrowse from "./unpaidApplication";
import { useSelector } from "react-redux";

const Indiaindex = () => {
	const [selectedIndex, setSelectedIndex] = useState(1);
	const userRightData = useSelector((state) => state.userRightData.value);
	const handleIndex = (index) => {
		setSelectedIndex(index);
	};

	return (
		<div className="application_wrapper">
			<div className="application_tabs">
				<div className="container-fluid">
					<ul className="nav nav-tabs nav-tabs-line">
						<h5>India </h5>
						{userRightData[5]?.valid ? (
							<li className="nav-item">
								<a
									className={
										`nav-link ` + (selectedIndex === 0 ? "active" : "")
									}
									onClick={() => {
										setSelectedIndex(0);
									}}
								>
									Paid Application
								</a>
							</li>
						) : (
							""
						)}
						{userRightData[6]?.valid ? (
							<li className="nav-item">
								<a
									className={
										`nav-link ` + (selectedIndex === 1 ? "active" : "")
									}
									onClick={() => {
										handleIndex(1);
									}}
								>
									Unpaid Application
								</a>
							</li>
						) : (
							""
						)}
					</ul>
				</div>
			</div>

			<div className="tab-content">
				{selectedIndex === 0 ? (
					userRightData[5]?.valid ? (
						<PaidApplicationBrowse />
					) : (
						""
					)
				) : userRightData[6]?.valid ? (
					<UnPaidApplicationBrowse />
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default Indiaindex;
