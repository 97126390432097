import moment from "moment";
import React, { useState, useEffect ,useMemo} from "react";
import { Form, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import BrijCustomDataTable from "../../components/brijDataTable";
import ButtonGroup from "react-bootstrap/ButtonGroup";

const UnPaidApplicationBrowse = () => {
  const [value, setValue] = useState('');
  const pageTitle = useLocation().pathname.split("/")[2];



  const [bodyQuery, setbodyQuery] = useState({
    passport_no: "",
    application_id: "",
    global_application_id: "",
    visa_service: "",
    email: "",
    contact: "",
    application_type: "",
    visitor_id: "",
    website_url: "",
    status: "",
    pageSize: 15,
    pageNo: 1,
  });

  const [apibody, setapibody] = useState({
    from_date: null,
    to_date: null,
    payment_from_date: null,
    payment_to_date: null,
    agent_id: null,
  });
  const handleChange = (e) => {
    setbodyQuery({ ...bodyQuery, [e.target.name]: e.target.value });
  };

  const handleChangess = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };
  const handleSendMail = (email) => {
    axios
      .post(`${process.env.REACT_APP_LOCAL_DOMAIN}/mailformate/sendMailformate`, {email:email ,  email_formate: value })
      .then((result) => {
        if (result.status === 200) {
          showSucessToast(" email send successfully");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = useMemo(() => [
    {
      id: "date",
      name: "Travel Date",
      selector: (row) => {
        return moment(row.date).format("DD/MM/YYYY");
      },
      sortable: true,
      minWidth: "150px",
    },
    {
      id: "date2",
      name: "Expire Date",
      selector: (row) => {
        return moment(row.date).format("DD/MM/YYYY");
      },
      sortable: true,
      minWidth: "150px",
    },
    {
      id: "Ticket Id",
      name: "Application ID",
      selector: (row) => {
        return (
          <div>
            <span> {row.temporary_id} </span>
          </div>
        );
      },
      sortable: true,
      minWidth: "250px",
    },
    {
      id: "email",
      name: "Email",
      selector: (row) => {
        return (
          <div>
            <span> {row.email} </span>
          </div>
        );
      },
      sortable: true,
      minWidth: "300px",
    },
    {
      id: "Mail Send ",
      name: "Mail Send",

      selector: (row) => {
        return (
          <div className="tbl__mailsent">
            send<sup>{row.full_mail_count}</sup>
          </div>
        );
      },
      sortable: true,
      minWidth: "100px",
    },
    {
      id: "Mail formate",
      name: "Mail formate",
      selector: (row) => {
        return (
          <Form.Select
                onChange={handleChangess}
                name="mail formate"
                size="md"
              >
                <option value={""}>mails</option>
                <option>mail-1</option>
                <option>mail-2</option>
                <option>mail-3</option>
                <option>mail-4</option>
              </Form.Select>
        );
      },
      sortable: true,
      minWidth: "200px",
    },
    {
      id: "action",
      name: "Action",
      selector: (row) => {
        return (

          <>
            <ButtonGroup>
              <Button onClick={(e) => handleSendMail({ email: row.email})}
              >
                send
              </Button>
            </ButtonGroup>
          </>
        );
      },
      sortable: true,
      width: "180px",
    },
  ], [value]);
 



 

 

 


  

 

  return (
    <div className="browse-list">
      <div className="application_form_wrapper">
        <div className="container-fluid">
          <div className="row">
                      <div className="col-md-4">
              <Form.Control
                value={bodyQuery.email}
                onChange={handleChange}
                type="text"
                name="email"
                placeholder="Email"
                size="md"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <BrijCustomDataTable
          columns={columns}
          url="/cm/main_Browse"
          apiBody={apibody}
          paramsBody={bodyQuery}
        
        />
        </div>
    </div>
  );
};

export default UnPaidApplicationBrowse;
