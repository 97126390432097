import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  BrijDateRange,
  showErrorToast,
  showSucessToast,
} from "../../components/common";
import moment from "moment";

import { commonAPICall } from "../../services/services";
function SrilankaPopup({ showtype, handleshowType, data }) {
  const [show, setShow] = useState(false);
  const [remarks, setRemarks] = useState([]);
  const [formdata, setFormdata] = useState({
    tran_id: 0,
    application_id: data,
    remarks: "",
  });
  const handleSubmitremarks = async () => {
    try {
      const obj = { ...formdata };
      obj.application_id = data;
      const result = await commonAPICall(
        "/sri/SL_Application_Remarks_Insert",
        "post",
        obj
      );
      if (result.status == 200) {
        setFormdata({ ...formdata, remarks: "" });
        previewremarks();
        showSucessToast("Remarks Add successfully");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const handleClose = () => {
    setShow(false);
    handleshowType(false);
  };
  const previewremarks = async () => {
    try {
      const result = await commonAPICall(
        "/sri/SL_Application_remarks_preview",
        "post",
        { application_id: data }
      );
      if (result.valid) {
        setRemarks(result.data);
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  useEffect(() => {
    setShow(showtype);
    previewremarks();
  }, [showtype]);

  const handlechanger = (event) => {
    setFormdata({ ...formdata, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Remark </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="remarks_list_box">
            {remarks.length > 0
              ? remarks.map((val, ind) => {
                return (
                  <div className="remarks_items">
                    <div className="cont">
                      <h3> {val.remarks_by}</h3>
                      <p>{val.remarks} </p>
                    </div>
                    <div className="datetime">
                        <span>
                          {moment(
                            val.datetime
                              ? val.datetime.replace("Z", "")
                              : null
                          ).format("DD/MM/YYYY")}{" "}
                        </span>
                        <span>
                          {moment(
                            val.datetime
                              ? val.datetime.replace("Z", "")
                              : null
                          ).format("hh:mm:ss A")}
                        </span>
                      </div>
                  </div>
                );
              })
              : " "}
          </div>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <h5>Add Remark * </h5>
              <Form.Control
                as="textarea"
                type="text"
                name="remarks"
                onChange={handlechanger}
                value={formdata.remarks}
                className="w-100 border"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" size="sm" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" size="sm" onClick={handleSubmitremarks}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default SrilankaPopup;
