import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { commonAPICall } from '../services/services';
import { ExcelExport } from '../components/excelExport';
import XLSX from "sheetjs-style";
function IndiaFee() {
  //  country data  start
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const handleUpdateFee = (e, event) => {
    const arrData = [...countryData];
    const index = countryData.indexOf(e);
    arrData[index][event.target.name] = event.target.value?event.target.value:null;
    setCountryData(arrData);
  };
  const countryDataList = async () => {
    try {
      const result = await commonAPICall('/ind/In_list_country', 'post', {});
      setCountryData(result.data);
      setFilter(result.data);
    } catch (err) {
      console.error(err);
    }
  };
  const handleSubmit = async () => {
    try {
      const result = await commonAPICall(
        '/ind/masters_country_update_bulk',
        'post',
        {bulkList:countryData}
      );
      if (result.valid) {
        countryDataList();
        toast.success('Country fee  updated successfully');
      }
    } catch (err) {
      console.error(err);
    }
  };
  const countryColumns = [
    {
      id: 'Country Name ',
      name: 'country name ',
      selector: (row) => row.country_name,
      sortable: true,
      width: '200px',
    },
    {
      id: 'Country ID  ',
      name: ' Country ID  ',
      omit: false,
      selector: (row) => row.country_id,
      sortable: true,
      width: '120px',
    },
    {
      id: 'Country Code ',
      name: ' Country Code   ',
      omit: false,
      selector: (row) => row.country_code,
      sortable: true,
      width: '130px',
    },
    {
      id: 'tourist',
      name: 'Tourist',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman"
            name="tourist"
            type="number"
            value={row.tourist}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: 'tourist_urgent',
      name: 'Tourist Urgent',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="tourist_urgent"
            value={row.tourist_urgent}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },

    {
      id: 'tourist_30',
      name: 'tourist_30',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="tourist_30"
            value={row.tourist_30}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: 'tourist_1y',
      name: 'tourist_1y',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="tourist_1y"
            value={row.tourist_1y}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: 'tourist_5y',
      name: 'tourist_5y',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="tourist_5y"
            value={row.tourist_5y}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: 'tourist_urgent_30',
      name: 'tourist_urgent_30',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="tourist_urgent_30"
            value={row.tourist_urgent_30}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: 'tourist_urgent_1y',
      name: 'tourist_urgent_1y',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="tourist_urgent_1y"
            value={row.tourist_urgent_1y}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: 'tourist_urgent_5y',
      name: 'tourist_urgent_5y',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="tourist_urgent_5y"
            value={row.tourist_urgent_5y}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: 'business',
      name: 'business',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="business"
            value={row.business}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: 'business_urgent',
      name: 'business_urgent',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="business_urgent"
            value={row.business_urgent}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: 'medical',
      name: 'medical',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="medical"
            value={row.medical}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: 'medical_urgent',
      name: 'medical_urgent',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="medical_urgent"
            value={row.medical_urgent}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: 'govt_fees',
      name: 'govt_fees',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="govt_fees"
            value={row.govt_fees}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: 'tran_charges_per',
      name: 'tran_charges_per',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="tran_charges_per"
            value={row.tran_charges_per}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: 'turkey',
      name: 'turkey',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="turkey"
            value={row.turkey}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: 'sl_tourist_individual',
      name: 'sl_tourist_individual',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="sl_tourist_individual"
            value={row.sl_tourist_individual}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },

    {
      id: 'sl_tourist_individual_urgent',
      name: 'sl_tourist_individual_urgent',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="sl_tourist_individual_urgent"
            value={row.sl_tourist_individual_urgent}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: 'sl_business_individual',
      name: 'sl_business_individual',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="sl_business_individual"
            value={row.sl_business_individual}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: 'sl_business_individual_urgent',
      name: 'sl_business_individual_urgent',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="sl_business_individual_urgent"
            value={row.sl_business_individual_urgent}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: 'sl_tourist_child',
      name: 'sl_tourist_child',
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="sl_tourist_child"
            value={row.sl_tourist_child}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    // {
    //   id: 'Save',
    //   name: 'Save',
    //   omit: false,
    //   selector: (row) => {
    //     return (
    //       // <Button variant="info" onClick={() => handleShow(row)}>
    //       <Button variant="info" onClick={handleSubmit}>
    //         Save
    //       </Button>
    //     );
    //   },
    //   sortable: true,
    // },
  ];
  useEffect(() => {
    const result = countryData.filter((tabledatas) => {
      return tabledatas.country_name
        .toLowerCase()
        .match(search.toLocaleLowerCase());
    }); 
    setFilter(result);
  }, [search]);
  useEffect(() => {
    countryDataList();
  }, []);

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      console.log(parsedData, "paresdData");
      setCountryData(parsedData);
      setFilter(parsedData)
    };
  };
  const actionsMemo = React.useMemo(
    () => (
      <div className='d-flex align-items-center gap-2'>
        <ExcelExport excelData={countryData} fileName={'vietnam'} />
          <input
            type="file"
            id='import'
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            helptext="excel"
            className='w-50'
          />
     <button onClick={handleSubmit}>Save</button>
      </div>
    ),
    [countryData]
  );
  return (
    <div className="settings_wrapper">
      <div>
        {" "}
        <h5 className="text-center "> India Country fee Update</h5>
      </div>
      <div>
        <DataTable
          columns={countryColumns}
          // data={countryData}
          pagination
          title="Country List with Price"
          data={filter}
          highlightOnHover
          fixedHeaderScrollHeight="400px"
          subHeader
          actions={actionsMemo}
          subHeaderComponent={
            <input
              type="text"
              placeholder="Search Country"
              className="w-25 form-control"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          }
        />
      </div>
    </div>
  );
}

export default IndiaFee;
