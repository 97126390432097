import React from "react";
import { Outlet } from "react-router-dom";

export const LayoutPreview = () => {
  return (
    <div className="erp-layout">
      <Outlet />
    </div>
  );
};
