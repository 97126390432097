import moment from "moment";
import React, { useState, useEffect } from "react";
import { Form, Button, Col, Dropdown } from "react-bootstrap";
import BrijCustomDataTable from "../../components/brijDataTable";
import {
  BrijDateRange,
  showErrorToast,
  showSucessToast,
} from "../../components/common";
import { selectionRange } from "../../components/constant";
import { commonAPICall } from "../../services/services";
import { previewLiveUrl } from "../../config";
import SrilankaPopup from "./SrilankaPopup";
import Modal from "react-bootstrap/Modal";
import { FaCog } from "react-icons/fa";

const UnPaidApplicationBrowse = () => {
  const [paymentData, setpaymentData] = useState([]);
  const [application, setapplication] = useState();
  const [salesExecutiveList, setSalesExecutiveList] = useState([]);
  const [dateFilterRange, setDateFilterRange] = useState({
    selection: selectionRange,
  });
  const [jsonUpdate, setjsonUpdate] = useState(0);
  const [applicationStatus, setApplicationStatus] = useState([]);
  const [selectStatusApplication, setselectStatusApplication] = useState({
    tran_id: 0,
  });
  const [salesStatus, setsalesStatus] = useState([]);
  const [selectSalesStatus, setselectSalesStatus] = useState({
    tran_id: 0,
  });
  const [paidData, setPaidData] = useState({
    amount: null,
    id: null,
  });
  const [showApplicationFee, setShowApplicationFee] = useState(false);
  const handleSubmitApplicationFee = async () => {
    try {
      const result = await commonAPICall(
        "/sri/move_to_unpaid_payment_insert",
        "post",
        paidData
      );
      showSucessToast("Moved to Paid Successfully");
      setShowApplicationFee(false);
      setjsonUpdate(jsonUpdate - 1);
    } catch (err) {
      showErrorToast(err);
    }
  };
  const [showType, setshowType] = useState(false);
  const [subBodyQuery, setsubBodyQuery] = useState({
    application_id: "",
    passport_no: "",
    email: "",
  });
  const [bodyQuery, setbodyQuery] = useState({
    application_id: "",
    passport_no: "",
    email: "",
    contact: "",
    global_application_id: "",
    from_date: "",
    to_date: "",
    travel_from_date: "",
    travel_to_date: "",
    payment_from_date: "",
    payment_to_date: "",
    application_type: "",
    visa_service: "",
    agent_id: "",
    website_url: "",
    sales_manager_status: "",
    sales_status: "",
    deo_manager_status: "",
    deo_status: "",
    visa_status: "",
    filter_column: "",
    search: "",
    pageSize: 15,
    pageNo: 1,
  });

  const [apibody, setapibody] = useState({
    from_date: null,
    to_date: null,
    travel_from_date: null,
    travel_to_date: null,
    agent_id: null,
  });

  const handlechangeSub = (e) => {
    const obj = { application_id: "", passport_no: "", email: "" };
    obj[e.target.name] = e.target.value;
    setsubBodyQuery(obj);
    if (e.key === "Enter") {
      setbodyQuery({
        ...bodyQuery,
        application_id: obj.application_id,
        passport_no: obj.passport_no,
        email: obj.email,
      });
      setsubBodyQuery({ application_id: "", passport_no: "", email: "" });
    }
  };
  const handleShowMoveToPaid = (row) => {
    setPaidData(row);
    setShowApplicationFee(true);
  };
  // const dateFilter = (date) => {
  //   setDateFilterRange(date);
  // };

  const openRemarks = (e, tran_id) => {
    setapplication(tran_id);
    setshowType(!showType);
  };

  // const handlePreviewAction = (e, temporary_id, tran_id) => {
  //   const url = `${previewLiveUrl}/preview/Srilanka/${temporary_id}/${tran_id}`;
  //   const windowOptions = "width=600,height=600,scrollbars=yes";
  //   window.open(url, "_blank", windowOptions);
  // };

  let previewWindow = null;

  const handlePreviewAction = (temporary_id, tran_id) => {
    const url = `${previewLiveUrl}/preview/Srilanka/${temporary_id}/${tran_id}`;
    const windowOptions = "width=600,height=600,scrollbars=yes";
    // window.open(url, "_blank", windowOptions);
    if (!previewWindow || previewWindow.closed) {
      previewWindow = window.open(url, "_blank", windowOptions);
    } else {
      // If the window is minimized, bring it to the front
      if (previewWindow.outerWidth === 0) {
        previewWindow.focus();
      }
      // Refresh the content
      previewWindow.location.href = url;
    }
  };

  const handleShowType = (type) => {
    setshowType(type);
  };
  const paymentApiCall = async () => {
    try {
      const result = await commonAPICall("/taj/list_payment_amount", "post", {
        from_date: apibody.from_date,
        to_date: apibody.to_date,
      });
      setpaymentData(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const handleChange = (e) => {
    setbodyQuery({ ...bodyQuery, [e.target.name]: e.target.value });
  };

  const handleChangeBody = (e) => {
    setapibody({ ...apibody, [e.target.name]: e.target.value });
  };

  const dateFilter = (date, type) => {
    const startDate = moment(date.startDate).format("MM/DD/YYYY");
    const endDate = moment(date.endDate).format("MM/DD/YYYY");
    if (type[2] === "application") {
      setapibody({ ...apibody, from_date: startDate, to_date: endDate });
    }
    if (type[2] === "travel") {
      setapibody({
        ...apibody,
        travel_from_date: startDate,
        travel_to_date: endDate,
      });
    }
  };

  const updateAllSalesExecutiveList = async () => {
    try {
      const result = await commonAPICall("/sri/list_sales_users", "get", "");

      setSalesExecutiveList(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const selectBrowseSalesStatus = async () => {
    try {
      const result = await commonAPICall(
        "/sri/sl_browse_application_sales_status",
        "get",
        ""
      );
      setsalesStatus(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const updateSalesStatus = async (e) => {
    try {
      const result = await commonAPICall(
        "/sri/sl_update_sales_status",
        "post",
        { tran_id: selectSalesStatus.tran_id, status: e.target.value }
      );
      if (result.status === 200) {
        setbodyQuery({ ...bodyQuery, reload: Math.random() * 3 });
        showSucessToast("Sales Status Updated");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const updateDeoManagerStatus = async (e) => {
    try {
      const result = await commonAPICall("/sri/SL_update_deo_status", "post", {
        tran_id: selectStatusApplication?.tran_id,
        status: e.target.value,
      });
      if (result.status === 200) {
        setbodyQuery({ ...bodyQuery, reload: Math.random() * 3 });
        showSucessToast("Application Status Updated");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const selectBrowseApplicationStatus = async () => {
    try {
      const result = await commonAPICall(
        "/sri/SL_browse_application_status",
        "get",
        ""
      );
      setApplicationStatus(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const updateApplicationStatus = async (element) => {
    try {
      const body = {
        tran_id: selectStatusApplication.tran_id,
        status: element.target.value,
      };
      setselectStatusApplication({
        ...selectStatusApplication,
        deo_status: element.target.value,
      });
      const result = await commonAPICall(
        "/update_application_status",
        "post",
        body
      );
      if (result.status === 200) {
        showSucessToast("Application Status Update");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  useEffect(() => {
    updateAllSalesExecutiveList();
    selectBrowseSalesStatus();
    selectBrowseApplicationStatus();
  }, []);

  useEffect(() => {
    paymentApiCall();
    // updateAllSalesExecutiveList();
  }, [apibody.from_date, apibody.to_date]);

  const columns = [
    {
      id: "Application Date",
      name: "Application Date",
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(row.datetime.replace("Z", "")).format("DD/MM/YYYY")}
            <span>
              {" "}
              {moment(row.datetime.replace("Z", "")).format("hh:mm:ss A")}
            </span>
          </div>
        );
      },

      sortable: true,
      omit: false,
      minWidth: "115px",
    },
    {
      id: "exp_date_of_arrival",
      name: "Travel Date",
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(
              row.exp_date_of_arrival
                ? row.exp_date_of_arrival
                : null
            ).format("DD/MM/YYYY")}
            {/* <span> {moment(row.exp_date_of_arrival ? row.exp_date_of_arrival.replace("Z", "") : null).format("hh:mm:ss A")}</span> */}
          </div>
        );
      },
      sortable: true,
      omit: false,
      minWidth: "103px",
    },
    {
      id: "Application Id",
      name: "Application ID",
      selector: (row) => row.temporary_id,
      sortable: true,
      omit: false,
      minWidth: "129px",
    },
    {
      id: "Application type",
      name: "Application Type",
      selector: (row) => row.visa_service.split(" ")[0],
      sortable: true,
      omit: false,
      minWidth: "110px",
    },
    {
      id: "Applicant Name",
      name: "Application Name",
      selector: (row) => row.Name,
      cell: (row) => <div style={{ whiteSpace: "normal" }}>{row.Name}</div>,
      sortable: true,
      omit: false,
      minWidth: "150px",
    },
    {
      id: "Passport No",
      name: "Passport No.",
      selector: (row) => row.passport_no,
      sortable: true,
      omit: false,
      minWidth: "110px",
    },
    {
      id: "Visa service",
      name: "Visa Service",
      selector: (row) => row.application_type,
      sortable: true,
      omit: false,
      minWidth: "130px",
    },
    {
      id: "Applicant Email",
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => <div style={{ whiteSpace: "normal" }}>{row.email}</div>,
      sortable: true,
      omit: false,
      minWidth: "180px",
    },
    {
      id: "sales_manager_status",
      name: "Sales Manager Status",
      selector: (row) => row.sales_manager_status,
      sortable: true,
      omit: false,
      minWidth: "150px",
    },
    {
      id: "Sales Status",
      name: "Sales Status",
      selector: (row) => row.sales_status,
      sortable: true,
      omit: false,
      minWidth: "193px",
      selector: (row) => {
        return (
          <div className="w-100">
            {selectSalesStatus?.tran_id !== row.tran_id ? (
              <p
                className="grid_select_value"
                onMouseOver={() => setselectSalesStatus(row)}
              >
                {row.sales_status ? row.sales_status : "N/A"}
              </p>
            ) : (
              <select
                name="sale Status"
                className="grid_select_input"
                value={selectSalesStatus?.sales_status}
                onChange={updateSalesStatus}
                onMouseOut={() =>
                  setselectSalesStatus({ ...selectSalesStatus, tran_id: 0 })
                }
              >
                <option value={""}>N/A</option>
                <option value={"Fresh Application"}>Fresh Application</option>
                <option value={"Will Pay Soon"}>Will Pay Soon</option>
                <option value={"No Call Response"}>No Call Response</option>
                <option value={"Voice Message"}>Voice Message</option>
                <option value={"Close"}>Close</option>
                <option value={"Others"}>Others</option>
              </select>
            )}
          </div>
        );
      },
    },


    {
      id: "deo_manager_status",
      name: "DEO Manager Status",
      selector: (row) => (
        <>{row.deo_manager_status ? row.deo_manager_status : "N/A"}</>
      ),
      sortable: true,
      omit: false,
      minWidth: "140px",
    },


    {
      id: "Application Status",
      name: "Application Status",
      omit: false,
      selector: (row) => {
        return (
          <div className="w-100">
            {selectStatusApplication?.tran_id !== row.tran_id ? (
              <p
                className="grid_select_value"
                onMouseOver={() => setselectStatusApplication(row)}
              >
                {row.deo_status ? row.deo_status : "N/A"}
              </p>
            ) : (
              <select
                name="sale Status"
                className="grid_select_input"
                value={selectStatusApplication?.deo_status}
                onChange={updateDeoManagerStatus}
                onMouseOut={() =>
                  setselectStatusApplication({
                    ...selectStatusApplication,
                    tran_id: 0,
                  })
                }
              >
                <option value={""}>N/A</option>
                <option value={"Fresh Application"}>Fresh Application</option>
                <option value={"Complete Application"}>
                  Complete Application
                </option>
                <option value={"Pending"}>Pending</option>
                <option value={"Wants to Cancle"}>Wants to Cancle</option>
                <option value={"Refund"}>Refund</option>
                <option value={"Hold"}>Hold</option>
                <option value={"Already have a ETA No"}>
                  Already have a ETA No
                </option>
                <option value={"Late Arrival"}>Late Arrival</option>
              </select>
            )}
          </div>
        );
      },
      sortable: true,
      minWidth: "193px",
    },
    {
      id: "sales_user_name",
      name: "Sales Executive",
      sortable: true,
      omit: false,
      minWidth: "120px",
      selector: (row) => {
        return (
          <div className="table__dttime text-start">
            {row.sales_user_name}
            {row.sales_manager_status === "Assigned" ? (
              <span className="bg-primary">
                {moment(row.sales_assign_datetime).format("DD/MM/YYYY")}
              </span>
            ) : null}
          </div>
        );
      },
    },
    {
      id: "Payment",
      name: "Payment",
      selector: (row) => row.payment_status,
      sortable: true,
      omit: false,
      minWidth: "90px",
    },
    {
      id: "Mail status",
      name: "Mail status",
      selector: (row) => {
        return (
          <div className="tbl__mailsent">
            send<sup>{row.partial_mail_count}</sup>
          </div>
        );
      },
      sortable: true,
      omit: false,
      minWidth: "97px",
    },
    {
      id: "Last Active",
      name: "Last Active",
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(
              row.last_active ? row.last_active.replace("Z", "") : null
            ).format("DD/MM/YYYY")}
            <span>
              {" "}
              {moment(
                row.last_active ? row.last_active.replace("Z", "") : null
              ).format("hh:mm:ss A")}
            </span>
          </div>
        );
      },
      sortable: true,
      omit: false,
      minWidth: "98px",
    },
    {
      id: "Site",
      name: "Site",
      selector: (row) => row.website_url,
      sortable: true,
      omit: false,
      minWidth: "130px",
    },

    {
      id: "move_to_paid",
      name: "Move to Paid",
      omit: false,
      selector: (row) => {
        return (
          <div className="customBtn_p">
            <Button
              size="sm"
              variant="danger"
              onClick={() => handleShowMoveToPaid(row)}
            >
              Move to Paid
            </Button>
          </div>
        );
      },
      sortable: true,
      minWidth: "116px",
    },
    {
      id: "action",
      name: "Action",
      omit: false,
      selector: (row) => {
        return (
          <>
            <Dropdown className="action_dropdown">
              <Dropdown.Toggle variant="dark" size="sm">
                <FaCog />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={(e) => openRemarks(e, row.tran_id)}>
                  Remarks
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={(e) =>
                    handlePreviewAction(row.temporary_id, row.tran_id)
                  }
                >
                  View
                </Dropdown.Item>
                <Dropdown.Item onClick={() => { }}>Delete</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
      sortable: true,
      minWidth: "70px",
    },
  ];

  return (
    <div className="browse-list">
      <div className="application_form_wrapper">
        <div className="container-fluid">
          <div className="row">
            <Col md={2} className="mb-1">
              <Form.Control
                size="sm"
                value={subBodyQuery.application_id}
                onChange={handlechangeSub}
                onKeyUp={handlechangeSub}
                type="text"
                name="application_id"
                placeholder="Application ID"
              />
            </Col>
            <Col md={2} className="mb-1">
              <Form.Control
                size="sm"
                value={subBodyQuery.passport_no}
                onChange={handlechangeSub}
                onKeyUp={handlechangeSub}
                type="text"
                name="passport_no"
                placeholder="Passport No."
              />
            </Col>
            <Col md={2} className="mb-1">
              <Form.Control
                size="sm"
                value={subBodyQuery.email}
                onChange={handlechangeSub}
                onKeyUp={handlechangeSub}
                type="text"
                name="email"
                placeholder="Email"
              />
            </Col>

            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                name="application_type"
                onChange={handleChange}
              >
                <option value={""}>All Visa Type</option>
                <option value="Tourist">Tourist Individual</option>
                <option value="Business">Business Individual</option>
              </Form.Select>
            </Col>


            <Col md={2} className="mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Application"
                pickClass={"right"}
              />
            </Col>


            <Col md={2} className="mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Travel"
                pickClass={"right"}
              />
            </Col>


            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                name="agent_id"
                onChange={handleChangeBody}
              >
                <option value={""}>All Sales Agents</option>
                {salesExecutiveList.map((value) => (
                  <option value={value.user_id} key={value.user_id}>
                    {value.full_name}
                  </option>
                ))}
              </Form.Select>
            </Col>

            <Col md={2} className="mb-1">
              <Form.Select
                name="sales_manager_status"
                size="sm"
                onChange={handleChange}
              >
                <option value={""}>All Sales Manager Status</option>
                <option value="Not Assigned">Not Assigned</option>
                <option value="Assigned">Assigned</option>
              </Form.Select>
            </Col>

            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                name="sales_status"
                onChange={handleChange}
              >
                <option value="">All Sales Status</option>
                <option value="Fresh Application">Fresh Application</option>
                <option value="Will Pay Soon">Will Pay Soon</option>
                <option value="No Call Response">No Call Response</option>
                <option value="Voice Message">Voice Message</option>
                <option value="Paid">Paid</option>
                <option value="Close">Close</option>
                <option value="Others">Others</option>
              </Form.Select>
            </Col>

            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                onChange={handleChange}
                name="deo_manager_status"
              >
                <option value={""}>All DEO Manager Status</option>
                <option value="Not Assigned">Not Assigned</option>
                <option value="Assigned">Assigned</option>
              </Form.Select>
            </Col>


            <Col md={2} className="mb-1">
              <Form.Select size="sm"
                name="deo_status"
                onChange={handleChange}
              >
                <option value={""}>All Application Status</option>
                <option value={"Fresh Application"}>Fresh Application</option>
                <option value={"Complete Application"}>
                  Complete Application
                </option>
                <option value={"Pending"}>Pending</option>
                <option value={"Wants to Cancle"}>Wants to Cancle</option>
                <option value={"Refund"}>Refund</option>
                <option value={"Hold"}>Hold</option>
                <option value={"Late Arrival"}>Late Arrival</option>
              </Form.Select>
            </Col>
          </div>
        </div>
      </div>
      
      <div className="table-responsive">
        <BrijCustomDataTable
          reloadGrid={jsonUpdate}
          columns={columns}
          url="/sri/sl_main_browse"
          apiBody={apibody}
          paramsBody={bodyQuery}
          assingUrl="/sri/SL_Application_assign_insert"
          type={"unpaid"}
        />

        <Modal
          show={showApplicationFee}
          onHide={() => setShowApplicationFee(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title className="fs-5">
              Application Fee : <span>{paidData.temporary_id}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center gap-3">
              <h5 className="flex-1">Amount:</h5>
              <Form.Control
                size="sm"
                type="number"
                value={paidData.application_amount}
                onChange={(e) =>
                  setPaidData({
                    ...paidData,
                    application_amount: e.target.value,
                  })
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              size="sm"
              onClick={() => setShowApplicationFee(false)}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              variant="success"
              onClick={handleSubmitApplicationFee}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <SrilankaPopup
        showtype={showType}
        handleshowType={handleShowType}
        data={application}
      />
    </div>
  );
};

export default UnPaidApplicationBrowse;
