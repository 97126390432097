import React, { useState, useEffect } from "react";
import { commonAPICall } from "../../services/services";
import moment from "moment";
import FileSaver from "file-saver";
export default function preview() {
  console.log();
  const [applicationViewData, setApplicationViewData] = useState([]);
  useEffect(() => {
    const previewData = async () => {
      const id = window.location.pathname.split("/");
      try {
        const result = await commonAPICall(
          "/tur/Turkey_Application_Main_View",
          "post",
          {
            temporary_id: id[3],
          }
        );

        setApplicationViewData(result.data);
      } catch (err) {
        console.error(err);
      }
    };
    previewData();
  }, []);

  const downloadURI = (e, fileUrl, download) => {
    e.preventDefault(); // Prevent browser's default download stuff...
    FileSaver.saveAs(fileUrl, download);
  };

  return (
    <>
      <div className="preview_table_wrapper">
        <div className="title_box">
          <h3>Your eTV Application Details</h3>
        </div>

        {applicationViewData.map((data) => (
          <div className="datatab_d">
            <div className="viewdata"> Application id no. : - </div>
            <div className="viewdata"> {data.temporary_id}</div>

            <div className="viewdata"> Application Type : - </div>
            <div className="viewdata"> {data.application_type}</div>

            <div className="viewdata"> Country/Nationality : - </div>
            <div className="viewdata"> {data.country_name}</div>

            <div className="viewdata"> Travel Document : - </div>
            <div className="viewdata"> {data.document}</div>

            <div className="viewdata">Arrival Date in Turkey : - </div>
            <div className="viewdata">
              {" "}
              {moment(data.arrival_date).format("DD/MM/YYYY")}
            </div>

            <div className="viewdata">Given/First Name(s) : - </div>
            <div className="viewdata"> {data.first_name}</div>

            <div className="viewdata">Surname(s) : - </div>
            <div className="viewdata"> {data.surname}</div>

            <div className="viewdata">Date of Birth : - </div>
            <div className="viewdata">
              {" "}
              {moment(data.dob).format("DD/MM/YYYY")}
            </div>

            <div className="viewdata">Place of Birth : - </div>
            <div className="viewdata">
              {" "}
              {moment(data.country_of_birth).format("DD/MM/YYY")}
            </div>

            <div className="viewdata">Mother`s Name : - </div>
            <div className="viewdata"> {data.mothers_name}</div>

            <div className="viewdata"> Father's Name : - </div>
            <div className="viewdata"> {data.fathers_name}</div>

            <div className="viewdata"> Passport Number : - </div>
            <div className="viewdata"> {data.passport_number}</div>

            <div className="viewdata"> Passport Issue Date : - </div>
            <div className="viewdata">
              {" "}
              {moment(data.passport_issue_date).format("DD/MM/YYYY")}
            </div>

            <div className="viewdata"> Passport Expiry Date : - </div>
            <div className="viewdata">
              {" "}
              {moment(data.passport_expiry_date).format("DD/MM/YYYY")}
            </div>

            <div className="viewdata"> Type of Supporting Document : - </div>
            <div className="viewdata"> {data.type_of_supporting_document}</div>

            <div className="viewdata"> Supporting Document from : - </div>
            <div className="viewdata"> {data.supporting_document_from}</div>

            <div className="viewdata"> Supporting Document Number : - </div>
            <div className="viewdata"> {data.supporting_document_no}</div>

            <div className="viewdata">
              {" "}
              Supporting Document Expiry Date : -{" "}
            </div>
            <div className="viewdata"> {data.country_of_birth}</div>

            <div className="viewdata"> E-mail Address: - </div>
            <div className="viewdata"> {data.email}</div>

            <div className="viewdata">Address : - </div>
            <div className="viewdata"> {data.country_name}</div>

            <div className="viewdata"> Telephone Number : - </div>
            <div className="viewdata"> {data.telephone}</div>

            <div className="viewdata">
              {" "}
              I declare that the information I have given in this application is
              truthful, complete and correct.: -{" "}
            </div>
            <div className="viewdata">
              {" "}
              {data.I_declare_information_truthful ? "yes" : "No"}
            </div>

            <div className="viewdata">
              {" "}
              I have read and understood the terms and conditions and privacy
              policy. : -{" "}
            </div>
            <div className="viewdata">
              {" "}
              {data.I_declare_information_truthful_show ? "yes" : "No"}
            </div>

            <div className="viewdata"> IP ADDRESS : - </div>
            <div className="viewdata"> {data.ip_address}</div>

            <div className=" headergt ">
              <h5>Document Details</h5>

              <div className="Application_photo border-0">
                <h6> Uploaded document</h6>
                <img
                  src={`https://api.evisa-tr.org/UploadTurkey/${data.temporary_id}/${data.image}`}
                  width={150}
                ></img>
                <button
                className="bg-light border rounded-2 mt-3"
                  onClick={(e) =>
                    downloadURI(
                      e,
                      `https://api.evisa-tr.org/UploadTurkey/${data.temporary_id}/${data.image}`,
                      data.image
                    )
                  }
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
