import moment from "moment";
import React, { useState, useEffect } from "react";
import { Form, Button, Dropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { FaCog, FaEdit, FaTrash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import BrijCustomDataTable from "../../components/brijDataTable";
import { BrijDateRange, showErrorToast } from "../../components/common";
import { selectionRange } from "../../components/constant";
import { commonAPICall } from "../../services/services";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { previewLiveUrl } from "../../config";
import Modal from "react-bootstrap/Modal";
import ComboRemark from "./ComboPop";
const UnPaidApplicationBrowse = () => {
  const [paymentData, setpaymentData] = useState([]);
  const [saleStatus, setSaleStatus] = useState([]);
  const [application, setapplication] = useState("");
  const [dateFilterRange, setDateFilterRange] = useState({
    selection: selectionRange,
  });
  const [tempid, setTempid] = useState({
    temporary_id: " ",
    tran_id: "",
  });
  const [bodyQuery, setbodyQuery] = useState({
    passport_no: "",
    application_id: "",
    global_application_id: "",
    visa_service: "",
    email: "",
    nationality: "",
    contact: "",
    application_type: "",
    visitor_id: "",
    website_url: "",
    status: "",
    pageSize: 15,
    pageNo: 1,
    agent_id: "",
    refresh: "",
  });
  const [apibody, setapibody] = useState({
    from_date: null,
    to_date: null,
    payment_from_date: null,
    payment_to_date: null,
    travel_to_date: null,
    travel_from_date: null,
  });
  const updateApplicationSaleStatus = async () => {
    try {
      const result = await commonAPICall(
        "/cm/CM_browse_application_sales_status",
        "get",
        ""
      );

      setSaleStatus(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };
  const handleShow = (id, amount) => {
    setPaidData({ ...paidData, id: id, amount: amount });
    setShow(true);
  };
  const openRemarks = (tran_id) => {
    setapplication(tran_id);
    setshowType(!showType);
  };
  const handleButtonClick = (temporary_id, tran_id) => {
    setTempid({ ...tempid, temporary_id: temporary_id, tran_id: tran_id });
    const url = `${previewLiveUrl}/preview/combodia/${temporary_id}/${tran_id}`;
    const windowOptions = "width=600,height=400,scrollbars=yes";
    window.open(url, "_blank", windowOptions);
  };
  const handleShowType = (type) => {
    setshowType(type);
  };
  const [paidData, setPaidData] = useState({
    amount: null,
    id: null,
  });

  const handleSubmit = async () => {
    try {
      const result = await commonAPICall("/cm/extra_payment_update", "post", {
        temporary_id: paidData.id,
        amount: paidData.amount,
      });
      if (result.valid) {
        setbodyQuery({ ...bodyQuery, refresh: Math.random() });
        setShow(false);
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const [show, setShow] = useState(false);
  const [showType, setshowType] = useState(false);

  // const paymentApiCall = async () => {
  //   try {
  //     const result = await commonAPICall("/cm/list_payment_amount", "post", {
  //       from_date: apibody.from_date,
  //       to_date: apibody.to_date,
  //     });
  //     setpaymentData(result.data);
  //   } catch (err) {
  //     showErrorToast(err);
  //   }
  // };

  // useEffect(() => {
  //   paymentApiCall();
  // }, [apibody.from_date, apibody.to_date]);

  const [subBodyQuery, setsubBodyQuery] = useState({
    application_id: "",
    passport_no: "",
    email: "",
    nationality: "",
  });

  const handlechangeSub = (e) => {
    const obj = {
      application_id: "",
      passport_no: "",
      email: "",
      nationality: "",
    };
    obj[e.target.name] = e.target.value;
    setsubBodyQuery(obj);
    if (e.key === "Enter") {
      setbodyQuery({
        ...bodyQuery,
        application_id: obj.application_id,
        passport_no: obj.passport_no,
        email: obj.email,
        nationality: obj.nationality,
      });
      setsubBodyQuery({
        application_id: "",
        passport_no: "",
        email: "",
        nationality: "",
      });
    }
  };

  const handleChange = (e) => {
    setbodyQuery({ ...bodyQuery, [e.target.name]: e.target.value });
  };

  const dateFilter = (date, type) => {
    const startDate = moment(date.startDate).format("MM/DD/YYYY");
    const endDate = moment(date.endDate).format("MM/DD/YYYY");
    if (type[2] === "application") {
      setapibody({ ...apibody, from_date: startDate, to_date: endDate });
    }
    if (type[2] === "payment") {
      setapibody({
        ...apibody,
        payment_from_date: startDate,
        payment_to_date: endDate,
      });
    }
    if (type[2] === "travel") {
      setapibody({
        ...apibody,
        travel_from_date: startDate,
        travel_to_date: endDate,
      });
    }
  };
  const columns = [
    {
      id: "datetime",
      name: "Application Date",
      selector: (row) => moment(row.datetime).format("MM/DD/YYYY"),
      sortable: true,
      minWidth: "140px",
    },
    {
      id: "exp_date_of_arrival",
      name: "Date of Travel",
      selector: (row) => {
        return moment(row.exp_date_of_arrival).format("DD/MM/YYYY");
      },
      sortable: true,
      minWidth: "120px",
    },
    {
      id: "temporary_id",
      name: "Application ID",
      selector: (row) => row.temporary_id,
      sortable: true,
      minWidth: "180px",
    },
    // {
    // 	id: "Name",
    // 	name: "Application Name",

    // 	sortable: true,
    // 	minWidth: "150px",
    // },
    {
      id: "Applicant Name",
      name: "Applicant Name",
      omit: false,
      selector: (row) => (row.Name ? row.Name : "N/A"),
      cell: (row) => (
        <div style={{ whiteSpace: "normal" }}>{row.Name.toUpperCase()}</div>
      ),
      sortable: true,
      minWidth: "150px",
    },
    {
      id: "application_type",
      name: "Application Type",
      selector: (row) => row.application_type,
      sortable: true,
      minWidth: "150px",
    },
    {
      id: "passport_no",
      name: "Passport No.",
      selector: (row) => row.passport_no,
      sortable: true,
      minWidth: "150px",
    },
    {
      id: "visa_service",
      name: "Visa Services",
      minWidth: "150px",
      selector: (row) => row.visa_service,
      sortable: true,
    },
    {
      id: "email",
      name: "Email",
      cell: (row) => <div style={{ whiteSpace: "normal" }}>{row.email}</div>,
      sortable: true,
      minWidth: "250px",
    },
    {
      id: "mobile",
      name: "Mobile No",
      selector: (row) => row.contact_no,
      sortable: true,
      minWidth: "105px",
    },
    {
      id: "nationality",
      name: "Nationality",
      selector: (row) => (row.nationality ? row.nationality : "N/A"),
      sortable: true,
      minWidth: "110px",
    },
    {
      id: "sales_manager_status",
      name: "Sales Manager Status",
      selector: (row) => row.sales_manager_status,
      sortable: true,
      minWidth: "170px",
    },
    {
      id: "sales_status",
      name: "Sales Status",
      selector: (row) => row.sales_status,
      sortable: true,
      minWidth: "180px",
    },
    {
      id: "sales_user_name",
      name: "Sales Executive Name",
      selector: (row) => row.sales_user_name,
      sortable: true,
      minWidth: "260px",
    },
    {
      id: "last_active",
      name: "Last Active",
      minWidth: "98px",
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(row.last_active).format("DD/MM/YYYY")}
            <span> {moment(row.last_active).format("hh:mm:ss A")}</span>
          </div>
        );
      },
      sortable: true,
    },
    {
      id: "move_to_paid",
      name: "Move to paid",
      omit: false,
      selector: (row) => {
        return (
          <div className="customBtn_p">
            <Button
              size="sm"
              variant="danger"
              onClick={() => handleShow(row.temporary_id, row.pay_amount)}
            >
              Move to Paid
            </Button>
          </div>
        );
      },
      sortable: true,
      width: "auto",
      center: true,
    },
    {
      id: "Ticket Id",
      name: "Ticket Id",
      selector: (row) => row.temporary_id,
      sortable: true,
      minWidth: "140px",
    },

    {
      id: "payment",
      name: "Payment",
      selector: (row) => row.payment,
      sortable: true,
      minWidth: "80px",
    },
    // {
    //   id: "payment",
    //   name: "Sales Executive",
    //   selector: (row) => {
    //     return (
    //       <div className="table__dttime">
    //         {row.sales_user_name}
    //         <span>
    //           {row.sales_assign_datetime
    //             ? moment(row.sales_assign_datetime.replace("Z", "")).format(
    //                 "DD/MM/YYYY"
    //               ) +
    //               " " +
    //               moment(row.sales_assign_datetime.replace("Z", "")).format(
    //                 "hh:mm:ss A"
    //               )
    //             : ""}
    //         </span>
    //       </div>
    //     );
    //   },
    //   sortable: true,
    //   minWidth: "180px",
    // },
    {
      id: "visa_priority",
      name: "Visa Priority",
      minWidth: "110px",
      selector: (row) => row.visa_priority,
      sortable: true,
    },
    {
      id: "action",
      name: "Action",
      selector: (row) => {
        return (
          <>
            <Dropdown className="action_dropdown">
              <Dropdown.Toggle variant="success" size="sm">
                <FaCog />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => openRemarks(row.tran_id)}>
                  Remarks
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) =>
                    handleButtonClick(row.temporary_id, row.tran_id)
                  }
                >
                  View
                </Dropdown.Item>
                <Dropdown.Item onClick={() => {}}>Delete</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
      sortable: true,
      width: "70px",
    },
  ];
  useEffect(() => {
    updateApplicationSaleStatus();
  }, []);
  return (
    <div className="browse-list">
      <div className="application_form_wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 mb-1">
              <Form.Control
                value={subBodyQuery.application_id}
                onChange={handlechangeSub}
                onKeyUp={handlechangeSub}
                type="text"
                name="application_id"
                placeholder="Application Id"
                size="sm"
              />
            </div>
            <div className="col-md-2 mb-1">
              <Form.Control
                value={subBodyQuery.passport_no}
                onChange={handlechangeSub}
                onKeyUp={handlechangeSub}
                type="text"
                name="passport_no"
                placeholder="Passport No."
                size="sm"
              />
            </div>
            <div className="col-md-4 mb-1">
              <Form.Control
                value={subBodyQuery.email}
                onChange={handlechangeSub}
                onKeyUp={handlechangeSub}
                type="text"
                name="email"
                placeholder="Email"
                size="sm"
              />
            </div>
            <div className="col mb-1">
              <Form.Control
                value={subBodyQuery.nationality}
                onChange={handlechangeSub}
                onKeyUp={handlechangeSub}
                type="text"
                name="nationality"
                placeholder="Nationality"
                size="sm"
              />
            </div>
            <div className="col-md-2 mb-1">
              <Form.Select
                onChange={handleChange}
                name="visa_priority"
                size="sm"
              >
                <option value={""}>All Application Type</option>
                <option value={"Normal"}>Normal Application</option>
                <option value={"urgent"}>Urgent Application</option>
              </Form.Select>
            </div>
            <div className="col-md-2 mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Application"
                pickClass={"left"}
              />
            </div>
            <div className="col-md-2 mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Travel"
                pickClass={"left"}
              />
            </div>
            <div className="col-md-2 mb-1">
              <Form.Select
                name="sales_manager_status"
                onChange={handleChange}
                size="sm"
              >
                <option value={""}>All Sales Manager Status</option>
                <option>Not Assigned</option>
                <option>Assigned</option>
              </Form.Select>
            </div>
            <div className="col-md-2 mb-1">
              <Form.Select
                name="sales_status"
                onChange={handleChange}
                size="sm"
              >
                <option value={""}>All Sales Status</option>
                {saleStatus.map((value) => (
                  <option value={value.status_name}>{value.status_name}</option>
                ))}
              </Form.Select>
            </div>
            {/* <div className="col-md-2 mb-1">
              <Form.Select name="deo_status" onChange={handleChange}>
                <option value={""}> All Deo Status </option>
                {applicationStatus.map((value) => (<option value={value.status_name}>{value.status_name}</option>)
                )}
              </Form.Select>
            </div> */}
            {/* <div className="col-md-2 mb-1">
              <Form.Select
                name="deo_manager_status"
                onChange={handleChange}
                size="sm"
              >
                <option value={""}>All Deo Manager Status</option>
                <option>Not Assigned</option>
                <option>Assigned</option>
              </Form.Select> 
             </div> */}
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <BrijCustomDataTable
          columns={columns}
          url="/cm/main_Browse"
          apiBody={apibody}
          paramsBody={bodyQuery}
          assingUrl="/cm/CM_Application_assign_insert"
          type={"unpaid"}
        />
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title className="fs-5">
              {" "}
              Application Id : <span>{paidData.id}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center gap-3">
              <h5 className="flex-1">Amount:</h5>
              <Form.Control
                type="number"
                value={paidData.amount}
                onChange={(e) =>
                  setPaidData({ ...paidData, amount: e.target.value })
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" size="sm" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button variant="success" size="sm" onClick={handleSubmit}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <ComboRemark
        showtype={showType}
        handleshowType={handleShowType}
        data={application}
      />
    </div>
  );
};

export default UnPaidApplicationBrowse;
