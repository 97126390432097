import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import PaidApplicationBrowse from "./PaidApplication";
import UnPaidApplicationBrowse from "./UnpaidApplication";

export default function MailCombodia() {
  const validation=localStorage.getItem("department");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleIndex = (index) => {
    setSelectedIndex(index);
  }; 

  return (
    <div className="application_wrapper">
      <div className="application_tabs">
        <div className="container-fluid">
          <ul className="nav nav-tabs nav-tabs-line">
            <h5>Combodia </h5>
             <li className="nav-item">
              <a
                className={`nav-link ` + (selectedIndex === 0 ? "active" : "")}
                onClick={() => handleIndex(0)}
              >
                Paid Application
              </a>
            </li>
    <li className="nav-item">
              <a
                className={`nav-link ` + (selectedIndex === 1 ? "active" : "")}
                onClick={() => handleIndex(1)}
              >
                Unpaid Application
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="tab-content">
        {selectedIndex === 0 ? (
          <PaidApplicationBrowse />
        ) : (
          <UnPaidApplicationBrowse />
        )}
      </div>
    </div>
  );
}
