import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import moment from "moment";

import Modal from "react-bootstrap/Modal";
import {
	BrijDateRange,
	showErrorToast,
	showSucessToast,
} from "../../components/common";
import { commonAPICall } from "../../services/services";
function ComboRemark({ showtype, handleshowType, data }) {
	const [show, setShow] = useState(false);
	const [remarks, setRemarks] = useState([]);
	const [formdata, setFormdata] = useState({
		tran_id: 0,
		application_id: data,
		remarks: "",
	});
	const handleSubmitremarks = async () => {
		try {
			const obj = { ...formdata };
			obj.application_id = data;
			const result = await commonAPICall(
				"/cm/application_remarks_insert",
				"post",
				obj
			);
			if (result.status == 200) {
				previewremarks();
				showSucessToast("Remarks Add successfully");
				// setShow(false);
				handleshowType(false);
			}
		} catch (err) {
			showErrorToast(err);
		}
	};
	const previewremarks = async () => {
		try {
			setRemarks([]);
			const result = await commonAPICall(
				"/cm/application_remarks_preview",
				"post",
				{ application_id: data }
			);
			if (result.valid) {
				setRemarks(result.data);
			}
		} catch (err) {
			showErrorToast(err);
		}
	};
	const handlechanger = (event) => {
		setFormdata({ ...formdata, [event.target.name]: event.target.value });
	};
	const handleClose = () => {
		setShow(false);
		handleshowType(false);
	};
	// const handleShow = () => setShow(true);
	useEffect(() => {
		setShow(showtype);
		previewremarks();
		setFormdata({ ...formdata, remarks: "" });
	}, [showtype]);
	return (
		<>
			<Modal show={show} onHide={handleClose} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>Remarks * </Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="remarks_list_box">
						{remarks.length > 0
							? remarks.map((val, ind) => {
									return (
										<>
											<h3> {val.remarks_by}</h3>
											<p>
												{" "}
												<span>{val.remarks} </span>{" "}
												<span>
													{moment(data.datetime).format("DD/MM/YYYY")}{" "}
												</span>{" "}
												,
												<span>
													{" "}
													{moment(data.datetime).format("hh:mm:ss A")}
												</span>{" "}
											</p>{" "}
											<hr></hr>
										</>
									);
							  })
							: " "}
					</div>
					<Form>
						<Form.Group
							className="mb-3"
							controlId="exampleForm.ControlTextarea1"
						>
							<h5> Add Remarks * </h5>
							<Form.Control
								as="textarea"
								name="remarks"
								onChange={handlechanger}
								value={formdata.remarks}
								className="w-100 p-2 border"

								style={{ height: "100px" }}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" size="sm" onClick={handleClose}>
						Close
					</Button>
					<Button variant="success" size="sm" onClick={handleSubmitremarks}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default ComboRemark;
