import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Table, Button } from "react-bootstrap";
import { showErrorToast, showSucessToast } from "../../components/common";
import { commonAPICall } from "../../services/services";

function MailSendPopup({ data, showMailSend, handleMailShowType }) {
	const [show, setShow] = useState(false);

	const sendUserMailHandler = async () => {
		try {
			const result = await commonAPICall("/ind/send_email", "post", data);
			if (result.status === 200) {
				showSucessToast("Mail sent")
				handleMailShowType(false);
			}
		} catch (err) {
			showErrorToast(err);
		}
	};

	useEffect(() => {
		setShow(showMailSend);
	}, [showMailSend]);
	return (
		<>
			<Modal
				show={show}
				onHide={() => {
					setShow(false);
					handleMailShowType(false);
				}}
				size="lg"
			>
				<Modal.Body>
					<Row>
						<Col md={12}>
							<Table className="m-0">
								<tbody>
									<tr
										style={{
											verticalAlign: "middle",
											backgroundColor: "#f4f4f4",
										}}
									>
										<td>
											<img
												src="/assets/images/etouristlogo.png"
												alt="etourist"
												style={{ height: "70px" }}
											/>
										</td>
										<td></td>
										<td className="text-end">
											<strong style={{ color: "#00a0de" }}>Call:</strong>{" "}
											+918826170874
											<br />
											<strong style={{ color: "#00a0de" }}>Email:</strong>{" "}
											info@indiansvisa.org.in
										</td>
									</tr>
									<tr>
										<td
											colSpan={3}
											style={{
												backgroundColor: "#00a0de",
												color: "#fff",
												fontSize: "17px",
												padding: "20px",
											}}
										>
											<span style={{ fontSize: "19px" }}>Dear Applicant,</span>{" "}
											<br /> We thank You for applying for eVisa to India. We
											appreciate your interest in Visiting India. The details
											are as follows:-
										</td>
									</tr>
									<tr>
										<td
											style={{
												backgroundColor: "#1d659a",
												color: "#fff",
												padding: "20px",
												width: "33.33%",
											}}
										>
											Application ID:{" "}
											<span
												style={{
													color: "yellow",
													fontWeight: 500,
													display: "block",
												}}
											>
												{data.temporary_id}
											</span>
										</td>
										<td
											style={{
												backgroundColor: "#1d659a",
												color: "#fff",
												padding: "20px",
												borderRight: "solid 1px #f2f2f2",
												borderLeft: "solid 1px #f2f2f2",
												width: "33.33%",
											}}
										>
											Application Type:{" "}
											<span
												style={{
													color: "yellow",
													fontWeight: 500,
													display: "block",
												}}
											>
												{data.application_type}
											</span>
										</td>
										<td
											style={{
												backgroundColor: "#1d659a",
												color: "#fff",
												padding: "20px",
												width: "33.33%",
											}}
										>
											Visa Type:{" "}
											<span
												style={{
													color: "yellow",
													fontWeight: 500,
													display: "block",
												}}
											>
												{data.visa_service}
											</span>
										</td>
									</tr>
									<tr style={{ backgroundColor: "#f5f6f6", fontSize: 14 }}>
										<td colSpan={3} style={{ padding: "20px 20px 0" }}>
											<p>
												Please note that we have received your application for
												evisa to India which is partially filled. Kindly note
												down the temporary application ID{" "}
												<strong>{data.temporary_id}</strong>.
											</p>
											<p>
												In this connection, you are suggested to kindly complete
												your application at
												https://indiavisasonline.org.in/applyvisa/PartialFilled
												so that we may able to process your application and
												delivered your visa result within the time frame.
											</p>
											<p>
												This temporary application-id is valid for 48 hours.
											</p>
											<p>
												<strong>Very Important Note:</strong> You must make the
												payment within 24-48 hours after submitting the
												application online otherwise, the application may get
												rejected and you may not be able to apply for another
												week. For any assistance please call the 24/7 help desk
												number before submitting a{" "}
												<span style={{ color: "red", fontWeight: 500 }}>
													duplicate application
												</span>{" "}
												. The eVisa fee once paid is non-refundable for any
												reason.
												<span style={{ color: "red", fontWeight: 500 }}>
													Please do not submit more than one application{" "}
												</span>
												because it may lead to rejection of all your
												applications submitted.
											</p>
										</td>
									</tr>
									<tr
										style={{
											backgroundColor: "#006699",
											color: "#fff",
											textAlign: "center",
											fontSize: "14px",
											fontWeight: 300,
											lineHeight: "1.7",
										}}
									>
										<td colSpan={3} style={{ padding: "30px 20px" }}>
											Please feel free to contact or email for any other
											questions on concerns. <br />
											Kindly Regards, Application Team <br />
											<strong>
												Contact No: +918826170874 | Email:
												info@indiansvisa.org.in |
												<span style={{ color: "yellow", marginLeft: "5px" }}>
													www.indiavisasonline.org.in
												</span>
											</strong>
											<br />
											<strong>IP Addess: {data.ip_address}</strong>
										</td>
									</tr>
								</tbody>
							</Table>
						</Col>
						<Col md={12} className="d-flex gap-1 justify-content-end mt-3">
							<Button
								size="sm"
								variant="danger"
								onClick={() => handleMailShowType(false)}
							>
								Cancel
							</Button>
							<Button size="sm" variant="success" onClick={sendUserMailHandler}>
								Send
							</Button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default MailSendPopup;
