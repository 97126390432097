import React, { useState, useEffect } from "react";
import { commonAPICall } from "../../services/services";
import { Row, Table } from "react-bootstrap";
import FileSaver from "file-saver";
import moment from "moment";
import { showErrorToast } from "../../components/common";
export default function PreviewIndia() {
  const [applicationViewData, setApplicationViewData] = useState([]);
  const [applicationDetail, setapplicationDetail] = useState({});
  const [saarcCountry, setsaarcCountry] = useState([]);
  const previewData = async () => {
    const id = window.location.pathname.split("/");
    try {
      const result = await commonAPICall(
        "/ind/Application_full_preview_india",
        "post",
        {
          temporary_id: id[3],
        }
      );
      setApplicationViewData(result.data);
      setapplicationDetail(result.data[0]);
    } catch (err) {
      console.error(err);
    }
  };

  const previewSaarcData = async () => {
    const id = window.location.pathname.split("/");
    try {
      const result = await commonAPICall(
        "/ind/Application_full_preview_india_sub",
        "post",
        {
          temporary_id: id[3],
        }
      );
      setsaarcCountry(result.data);
    } catch (err) {
      console.error(err);
    }
  };
  //passport details
  // const passportDetailsData = async () => {
  // 	const id = window.location.pathname.split("/");
  // 	try {
  // 		const result = await commonAPICall('/ind/Application_applicant_passport_details_view', 'post', {
  // 			temporary_id: id[3],
  // 		});
  // 		setpassportDetailsViewData(result.data);
  // 	} catch (err) {
  // 		showErrorToast(err);
  // 	}
  // }

  useEffect(() => {
    previewData();
    previewSaarcData();
    // additionalQuestionData();
    // familyDetailsData();
    // passportDetailsData();
    // passportDocumentData();
    // visaDetailsData();
    // saarcDetailsData();
  }, []);

  const downloadURI = (e, fileUrl, download) => {
    e.preventDefault(); // Prevent browser's default download stuff...
    FileSaver.saveAs(fileUrl, download);
  };

  const switchFunction = (value) => {
    let obj = {};
    switch (value) {
      case "eBUSINESS Visa(for 356 Days)":
        (obj.duration = "1 Year"), (obj.no_of_entries = "Multiple");
        break;
      case "eMEDICAL Visa(for 60 Days)":
        (obj.duration = "60 days"), (obj.no_of_entries = "Tripple");
        break;
      case "eMEDICAL ATTENDANT Visa(for 60 Days)":
        (obj.duration = "60 days"), (obj.no_of_entries = "Tripple");
        break;
      case "eTourist Visa(for 30 Days)":
        (obj.duration = "30 days"), (obj.no_of_entries = "Double");
        break;
      case "eTourist Visa(for 1 Year)":
        (obj.duration = "1 Year"), (obj.no_of_entries = "Multiple");
        break;
      case "eTourist Visa(for 5 Years)":
        (obj.duration = "5 Year"), (obj.no_of_entries = "Multiple");
        break;
      case "eCONFERENCE Visa(for 30 Days)":
        (obj.duration = "30 Days"), (obj.no_of_entries = "Double");
        break;
      default:
        (obj.duration = ""), (obj.no_of_entries = "");
        break;
    }
    return obj;
  };

  const switchVisaServiceType = (value) => {
    let visaServiceTypeJsx;
    console.log(value);
    switch (value) {
      case "MEETING FRIENDS/RELATIVES":
        visaServiceTypeJsx = (
          <>
            <div className="viewdata orange">
              Details of Purpose "MEETING FRIENDS/RELATIVES"
            </div>
            <div className="viewdata">Details of the Friend/Relative</div>
            <div className="viewdata">
              {applicationDetail.meeting_details_of_friend_relative}
            </div>

            <div className="viewdata">Address</div>
            <div className="viewdata">{applicationDetail.meeting_address}</div>

            <div className="viewdata">State</div>
            <div className="viewdata">{applicationDetail.meeting_state}</div>

            <div className="viewdata">District</div>
            <div className="viewdata">{applicationDetail.meeting_district}</div>

            <div className="viewdata">Phone no</div>
            <div className="viewdata">{applicationDetail.meeting_phone_no}</div>
          </>
        );
        break;
      case "SHORT TERM YOGA PROGRAMME":
        visaServiceTypeJsx = (
          <>
            <div className="viewdata orange">
              Details of Purpose "SHORT TERM YOGA PROGRAMME"
            </div>
            <div className="viewdata">Name of the Institute in India</div>
            <div className="viewdata">
              {applicationDetail.yoga_name_institute}
            </div>

            <div className="viewdata">Address</div>
            <div className="viewdata">{applicationDetail.yoga_address}</div>

            <div className="viewdata">State</div>
            <div className="viewdata">{applicationDetail.yoga_state}</div>

            <div className="viewdata">District</div>
            <div className="viewdata">{applicationDetail.yoga_district}</div>

            <div className="viewdata">Phone no</div>
            <div className="viewdata">{applicationDetail.yoga_phone_no}</div>
          </>
        );
        break;

      case "TO SET UP INDUSTRIAL/BUSINESS VENTURE":
        visaServiceTypeJsx = (
          <>
            <div className="viewdata orange">
              Details of Purpose "TO SET UP INDUSTRIAL/BUSINESS VENTURE"
            </div>
            <div className="viewdata" style={{ gridColumn: "1/3" }}>
              <strong>Details of the Applicants Company</strong>
            </div>
            <div className="viewdata">Name</div>
            <div className="viewdata">
              {applicationDetail.set_up_industrial_business_name_institute}
            </div>
            <div className="viewdata">Address, Phone No</div>
            <div className="viewdata">
              {applicationDetail.set_up_industrial_business_address_phone_no}
            </div>
            <div className="viewdata">Name of Business/Product</div>
            <div className="viewdata">
              {
                applicationDetail.set_up_industrial_business_nature_of_business_product
              }
            </div>
            <div className="viewdata">Website</div>
            <div className="viewdata">
              {applicationDetail.set_up_industrial_business_website}
            </div>
          </>
        );
        break;
      case "SALE/PURCHASE/TRADE":
        visaServiceTypeJsx = (
          <>
            <div className="viewdata orange">
              Details of Purpose "SALE/PURCHASE/TRADE"
            </div>
            <div className="viewdata">Name</div>
            <div className="viewdata">
              {applicationDetail.sale_purchase_trade_name_institute}
            </div>
            <div className="viewdata">Address, Phone No</div>
            <div className="viewdata">
              {applicationDetail.sale_purchase_trade_address_phone_no}
            </div>
            <div className="viewdata">Name of Business/Product</div>
            <div className="viewdata">
              {applicationDetail.sale_purchase_trade_nature_of_business_product}
            </div>
            <div className="viewdata">Website</div>
            <div className="viewdata">
              {applicationDetail.sale_purchase_trade_website}
            </div>
          </>
        );
        break;
      case "ATTEND TECHNICAL/BUSINESS MEETINGS":
        visaServiceTypeJsx = (
          <>
            <div className="viewdata orange">
              Details of Purpose "ATTEND TECHNICAL/BUSINESS MEETINGS"
            </div>
            <div className="viewdata" style={{ gridColumn: "1/3" }}>
              <strong>Details of the Applicants Company</strong>
            </div>
            <div className="viewdata">Name</div>
            <div className="viewdata">
              {applicationDetail.attend_meeting_applicant_company_name}
            </div>
            <div className="viewdata">Address,Phone no</div>
            <div className="viewdata">
              {
                applicationDetail.attend_meeting_applicant_company_address_phone_no
              }
            </div>
            <div className="viewdata">Website</div>
            <div className="viewdata">
              {applicationDetail.attend_meeting_applicant_company_website}
            </div>
          </>
        );
        break;
      case "TO RECRUIT MANPOWER":
        visaServiceTypeJsx = (
          <>
            <div className="viewdata orange">
              Details of Purpose "TO RECRUIT MANPOWER"
            </div>
            <div className="viewdata">Name</div>
            <div className="viewdata">
              {applicationDetail.recruit_manpower_name_institute}
            </div>

            <div className="viewdata">Address,Phone no</div>
            <div className="viewdata">
              {applicationDetail.recruit_manpower_address_phone_no}
            </div>
            <div className="viewdata">Website</div>
            <div className="viewdata">
              {applicationDetail.recruit_manpower_website}
            </div>
            <div className="viewdata">
              Name and contact number of the company representative in India
            </div>
            <div className="viewdata">
              {
                applicationDetail.recruit_manpower_name_and_contact_of_company_representative
              }
            </div>
            <div className="viewdata">Nature of Job for which recruiting</div>
            <div className="viewdata">
              {applicationDetail.recruit_manpower_nature_of_job}
            </div>
            <div className="viewdata">
              Places where recruitment is to be conducted
            </div>
            <div className="viewdata">
              {applicationDetail.recruit_manpower_place_recruitment}
            </div>
          </>
        );
        break;
      case "PARTICIPATION IN EXHIBITIONS,BUSINESS/TRADE FAIRS":
        visaServiceTypeJsx = (
          <>
            <div className="viewdata orange">
              Details of Purpose "PARTICIPATION IN EXHIBITIONS,BUSINESS/TRADE
              FAIRS"
            </div>
            <div className="viewdata">Name</div>
            <div className="viewdata">
              {applicationDetail.participation_name}
            </div>

            <div className="viewdata">Address,Phone no</div>
            <div className="viewdata">
              {applicationDetail.participation_address_phone_no}
            </div>
            <div className="viewdata">Website</div>
            <div className="viewdata">
              {applicationDetail.participation_website}
            </div>
            <div className="viewdata">
              Name and address of the Exhibition/trade fair
            </div>
            <div className="viewdata">
              {
                applicationDetail.participation_name_address_of_exhibition_trade_fair
              }
            </div>
          </>
        );
        break;
      case "EXPERT/SPECIALIST IN CONNECTION WITH AN ONGOING PROJECT":
        visaServiceTypeJsx = (
          <>
            <div className="viewdata orange">
              Details of Purpose "EXPERT/SPECIALIST IN CONNECTION WITH AN
              ONGOING PROJECT"
            </div>
            <div className="viewdata" style={{ gridColumn: "1/3" }}>
              <strong>Details of the Applicants Company/FIRM</strong>
            </div>
            <div className="viewdata">Name</div>
            <div className="viewdata">
              {applicationDetail.expert_specialist_name}
            </div>

            <div className="viewdata">Address,Phone no</div>
            <div className="viewdata">
              {applicationDetail.expert_specialist_address_phone_no}
            </div>
            <div className="viewdata">Website</div>
            <div className="viewdata">
              {applicationDetail.expert_specialist_website}
            </div>
            <div className="viewdata" style={{ gridColumn: "1/3" }}>
              <strong>Details of the Indian firm</strong>
            </div>
            <div className="viewdata">Name</div>
            <div className="viewdata">
              {applicationDetail.expert_specialist_details_name}
            </div>

            <div className="viewdata">Address,Phone no</div>
            <div className="viewdata">
              {applicationDetail.expert_specialist_details_address_phone_no}
            </div>
            <div className="viewdata">Website</div>
            <div className="viewdata">
              {applicationDetail.expert_specialist_details_website}
            </div>
          </>
        );
        break;
      case "CONDUCTING TOURS":
        visaServiceTypeJsx = (
          <>
            <div className="viewdata orange">
              Details of Purpose "CONDUCTING TOURS"
            </div>
            <div className="viewdata">
              Name and address of the Travel Agency in native country
            </div>
            <div className="viewdata">
              {applicationDetail.conducting_name_address_of_travel_agency}
            </div>

            <div className="viewdata">Cities to be visited during the tour</div>
            <div className="viewdata">
              {applicationDetail.conducting_cities_to_be_visited}
            </div>
            <div className="viewdata" style={{ gridColumn: "1/3" }}>
              <strong>Details of the Travel agent/associate in India</strong>
            </div>
            <div className="viewdata">Name</div>
            <div className="viewdata">
              {applicationDetail.conducting_agent_associate_name}
            </div>

            <div className="viewdata">Address,Phone no</div>
            <div className="viewdata">
              {applicationDetail.conducting_agent_associate_address_phone_no}
            </div>
          </>
        );
        break;
      case "TO DELIVER LECTURE/S UNDER GLOBAL INITIATIVE FOR ACADEMIC NETWORKS (GIAN)":
        visaServiceTypeJsx = (
          <>
            <div className="viewdata orange">
              Details of Purpose "TO DELIVER LECTURE/S UNDER GLOBAL INITIATIVE
              FOR ACADEMIC NETWORKS (GIAN)"
            </div>
            <div className="viewdata">
              Name and address of the Travel Agency in native country
            </div>
            <div className="viewdata">
              {applicationDetail.conducting_name_address_of_travel_agency}
            </div>

            <div className="viewdata">Cities to be visited during the tour</div>
            <div className="viewdata">
              {applicationDetail.conducting_cities_to_be_visited}
            </div>
            <div className="viewdata" style={{ gridColumn: "1/3" }}>
              <strong>Details of the Travel agent/associate in India</strong>
            </div>
            <div className="viewdata">Name</div>
            <div className="viewdata">
              {applicationDetail.conducting_agent_associate_name}
            </div>

            <div className="viewdata">Address,Phone no</div>
            <div className="viewdata">
              {applicationDetail.conducting_agent_associate_address_phone_no}
            </div>
          </>
        );
        break;
      default:
        visaServiceTypeJsx = "";
        break;
    }
    return visaServiceTypeJsx;
  };

  const switchVisaService = (value) => {
    let visaServiceJsx;
    switch (value) {
      case "eMEDICAL VISA":
        visaServiceJsx = (
          <>
            <div className="viewdata orange">
              {" "}
              Details of Purpose "SHORT TERM MEDICAL TREATMENT OF SELF"{" "}
            </div>

            <div className="viewdata">
              Name of the Hospital where Medical treatment is to be carried out
            </div>
            <div className="viewdata">
              {applicationDetail.medical_name_hospital}
            </div>
            <div className="viewdata">Address of Hospital</div>
            <div className="viewdata">
              {applicationDetail.medical_hospital_address}
            </div>
            <div className="viewdata">State</div>
            <div className="viewdata">{applicationDetail.medical_state}</div>
            <div className="viewdata">District</div>
            <div className="viewdata">{applicationDetail.medical_district}</div>
            <div className="viewdata">Phone No.</div>
            <div className="viewdata">{applicationDetail.medical_phone_no}</div>
            <div className="viewdata">Type of Medical Treatment required</div>
            <div className="viewdata">
              {applicationDetail.medical_type_of_medical}
            </div>
            <div className="viewdata">
              Places where recruitment is to be conducted
            </div>
            <div className="viewdata">
              {applicationDetail.recruit_manpower_place_recruitment}
            </div>
            <div className="viewdata">Address,Phone no</div>
            <div className="viewdata">
              {applicationDetail.recruit_manpower_address_phone_no}
            </div>
          </>
        );
        break;
      case "eMEDICAL ATTENDANT VISA":
        visaServiceJsx = (
          <>
            <div className="viewdata orange">
              TO ACCOMPANY PATIENT TRAVELLING TO INDIA ON EMEDICAL VISA
            </div>
            <div className="viewdata">
              Name of the principal e-Medical Visa holder(i.e. the patient)
            </div>
            <div className="viewdata">{applicationDetail.emedical_name}</div>
            <div className="viewdata">
              Visa No./Application id of principal e-Medical Visa holder
            </div>
            <div className="viewdata">
              {applicationDetail.emedical_visa_appilcation_id}
            </div>
            <div className="viewdata">
              Application id of principal e-Medical Visa holder
            </div>
            <div className="viewdata">
              {applicationDetail.emedical_application_id}
            </div>
            <div className="viewdata">
              Passport number of principal e-Medical Visa holder
            </div>
            <div className="viewdata">
              {applicationDetail.emedical_passport_no}
            </div>
            <div className="viewdata">
              Date of birth of principal e-Medical Visa holder
            </div>
            <div className="viewdata">
              {applicationDetail.emedical_dob && moment(applicationDetail.emedical_dob).format("DD/MM/YYYY")}
            </div>
            <div className="viewdata">
              Nationality of principal e-Medical Visa holder
            </div>
            <div className="viewdata">
              {applicationDetail.emedical_nationality}
            </div>
          </>
        );
        break;
      case "eCONFERENCE VISA":
        visaServiceJsx = (
          <>
            <div className="viewdata orange">
              Details of Purpose "TO ATTEND A CONFERENCE/SEMINAR/WORKSHOP
              ORGANIZED BY A MINISTRY OR DEPARTMENT OF THE GOVERNMENT OF
              INDIA,STATE GOVERNMENTS OR UT ADMINISTRATIONS AND THEIR
              SUBORDINATE/ ATTACHED ORGANIZATIONS AND PSUS"
            </div>
            <div className="viewdata">Name/subject of the conference</div>
            <div className="viewdata">{applicationDetail.conference_name}</div>
            <div className="viewdata" style={{ gridColumn: "1/3" }}>
              <strong>Duration of conference</strong>
            </div>
            <div className="viewdata">Start date</div>
            <div className="viewdata">
              {applicationDetail.conference_duration_sdate && moment(applicationDetail.conference_duration_sdate).format(
                "DD/MM/YYYY"
              )}
            </div>
            <div className="viewdata">End date</div>
            <div className="viewdata">
              {applicationDetail.conference_duration_edate && moment(applicationDetail.conference_duration_edate).format(
                "DD/MM/YYYY"
              )}
            </div>
            <div className="viewdata" style={{ gridColumn: "1/3" }}>
              <strong>Venue of conference</strong>
            </div>
            <div className="viewdata">Address</div>
            <div className="viewdata">
              {applicationDetail.conference_venue_address}
            </div>
            <div className="viewdata">State</div>
            <div className="viewdata">
              {applicationDetail.conference_venue_state}
            </div>
            <div className="viewdata">District</div>
            <div className="viewdata">
              {applicationDetail.conference_venue_district}
            </div>
            <div className="viewdata">Pincode</div>
            <div className="viewdata">
              {applicationDetail.conference_venue_pincode}
            </div>
            <div className="viewdata" style={{ gridColumn: "1/3" }}>
              <strong>Details of organizer of conference</strong>
            </div>
            <div className="viewdata">Name of organizer</div>
            <div className="viewdata">
              {applicationDetail.conference_details_organizer_name}
            </div>
            <div className="viewdata">Address</div>
            <div className="viewdata">
              {applicationDetail.conference_details_organizer_address}
            </div>
            <div className="viewdata">Phone no</div>
            <div className="viewdata">
              {applicationDetail.conference_details_organizer_phone_no}
            </div>
            <div className="viewdata">Email id</div>
            <div className="viewdata">
              {applicationDetail.conference_details_organizer_email_id}
            </div>
          </>
        );
        break;
      default:
        visaServiceJsx = "";
        break;
    }
    return visaServiceJsx;
  };

  return (
    <>
      <div className="preview_table_wrapper">
        <div className="title_box">
          <h3>Your eTV Application Details</h3>
        </div>

        {applicationViewData.map((data, i) => (
          <div className="datatab_d bg-light border" key={i}>
            <div
              className="viewdata text-white"
              style={{ backgroundColor: "orangered" }}
            >
              {" "}
              Application id no.{" "}
            </div>
            <div
              className="viewdata text-white"
              style={{ backgroundColor: "orangered" }}
            >
              {" "}
              {data.temporary_id}
            </div>

            <div className="viewdata"> How long for eTOURIST VISA </div>
            <div className="viewdata"> {data.visa_tourist_days}</div>

            <div className="viewdata">Application Type </div>
            <div className="viewdata"> {data.application_type}</div>

            <div className="viewdata">Passport Type </div>
            <div className="viewdata"> {data.passport_type}</div>

            <div className="viewdata">Port of Arrival in India </div>
            <div className="viewdata"> {data.port_of_arrival}</div>

            <div className="viewdata">Nationality </div>
            <div className="viewdata"> {data.nationality}</div>

            <div className="viewdata">Date of Birth </div>
            <div className="viewdata">
              {" "}
              {data.date_of_birth && moment(data.date_of_birth).format("DD/MM/YYYY")}
            </div>

            <div className="viewdata">Email Address </div>
            <div className="viewdata"> {data.email}</div>

            <div className="viewdata"> Expected Date journey </div>
            <div className="viewdata">
              {" "}
              {data.exp_date_of_arrival && moment(data.exp_date_of_arrival).format("DD/MM/YYYY")}
            </div>

            <div className="viewdata"> Visa Type </div>
            <div className="viewdata"> {data.visa_service}</div>

            <div className="viewdata"> Contact Number - </div>
            <div className="viewdata">
              {" "}
              {data.contact_country_code + data.contact_no}
            </div>

            <div className="viewdata"> Surname </div>
            <div className="viewdata"> {data.surname}</div>

            <div className="viewdata"> Given Name/s </div>
            <div className="viewdata"> {data.given_name}</div>

            <div className="viewdata"> Have you ever changed your name? </div>
            <div className="viewdata">
              {" "}
              {data.have_changed_name === "True" ? "Yes" : "No"}
            </div>

            <div className="viewdata"> Gender </div>
            <div className="viewdata"> {data.gender}</div>

            <div className="viewdata"> Town/City of birth </div>
            <div className="viewdata"> {data.town_city_of_birth}</div>

            <div className="viewdata"> Country of birth </div>
            <div className="viewdata"> {data.country_of_birth}</div>

            <div className="viewdata"> Citizenship/National Id No. </div>
            <div className="viewdata"> {data.citizenship_national_id}</div>

            <div className="viewdata">Religion </div>
            <div className="viewdata"> {data.religion}</div>

            <div className="viewdata"> Visible identification marks </div>
            <div className="viewdata"> {data.visible_identification_marks}</div>

            <div className="viewdata"> Educational Qualification </div>
            <div className="viewdata"> {data.education_qualification}</div>

            <div className="viewdata">
              {" "}
              Did you acquire Nationality by birth or by naturalization?{" "}
            </div>
            <div className="viewdata">
              {" "}
              {data.nationality_birth_naturalization}
            </div>

            {data.nationality_birth_naturalization === "Naturalization" && (
              <>
                <div className="viewdata"> Prev. Nationality </div>
                <div className="viewdata"> {data.previous_nationality}</div>
              </>
            )}

            <div className="viewdata">
              {" "}
              Have you lived for at least two years in the country where you are
              applying visa?{" "}
            </div>
            <div className="viewdata">
              {" "}
              {data.leave_for_2_years ? "Yes" : "No"}
            </div>

            <div className="viewdata orange"> Passport Details </div>

            <div className="viewdata"> Passport Number </div>
            <div className="viewdata"> {data.passport_no}</div>

            <div className="viewdata"> Place of Issue </div>
            <div className="viewdata">{data.place_of_issue}</div>

            <div className="viewdata"> Date of Issue </div>
            <div className="viewdata">
              {" "}
              {data.date_of_issue && moment(data.date_of_issue).format("DD/MM/YYYY")}
            </div>

            <div className="viewdata"> Date of Expiry </div>
            <div className="viewdata">
              {" "}
              {data.date_of_expiry && moment(data.date_of_expiry).format("DD/MM/YYYY")}
            </div>

            <div className="viewdata">
              {" "}
              Any other valid Passport/Identity Certificate(IC) held,{" "}
            </div>
            <div className="viewdata">
              {" "}
              {data.other_valid_passport ? "Yes" : "No"}
            </div>

            {data.other_valid_passport && (
              <>
                <div className="viewdata">Country of Issue</div>
                <div className="viewdata">{data.other_country_of_issue}</div>
                <div className="viewdata">Passport/IC No.</div>
                <div className="viewdata">{data.other_passport_no}</div>
                <div className="viewdata">Date of Issue</div>
                <div className="viewdata">
                  {data.other_date_of_issue && moment(data.other_date_of_issue).format("DD/MM/YYYY")}
                </div>
                <div className="viewdata">Place of Issue</div>
                <div className="viewdata">{data.other_place_of_issue}</div>
                <div className="viewdata">Nationality mentioned therein</div>
                <div className="viewdata">
                  {data.nationality_in_other_passport}
                </div>
              </>
            )}

            <div className="viewdata orange"> Applicant's Address Details </div>

            <div className="viewdata"> House No./Street </div>
            <div className="viewdata"> {data.house_street_no}</div>
            <div className="viewdata"> Village/Town/City </div>
            <div className="viewdata"> {data.village_town_city}</div>
            <div className="viewdata"> State/Province/District </div>
            <div className="viewdata"> {data.state_province_district}</div>
            <div className="viewdata"> Postal/Zip Code </div>
            <div className="viewdata"> {data.postal_zipcode}</div>
            <div className="viewdata"> Country </div>
            <div className="viewdata"> {data.country}</div>
            <div className="viewdata"> Phone No. </div>
            <div className="viewdata"> {data.phone_no}</div>
            <div className="viewdata"> Mobile No. </div>
            <div className="viewdata"> {data.mobile_no}</div>
            <div className="viewdata"> Permanent Address </div>
            <div className="viewdata"> {data.xxxx}</div>
            <div className="viewdata"> House No./Street </div>
            <div className="viewdata"> {data.house_street_no}</div>
            <div className="viewdata"> Village/Town/City </div>
            <div className="viewdata"> {data.village_town_city}</div>
            <div className="viewdata"> State/Province/District </div>
            <div className="viewdata"> {data.state_province_district}</div>

            <div className="viewdata orange"> Father's Details </div>

            <div className="viewdata"> Name </div>
            <div className="viewdata"> {data.father_name}</div>
            <div className="viewdata">Nationality </div>
            <div className="viewdata"> {data.father_nationality}</div>
            <div className="viewdata">Previous Nationality </div>
            <div className="viewdata"> {data.previous_father_nationality}</div>
            <div className="viewdata">Place of birth </div>
            <div className="viewdata"> {data.father_place_of_birth}</div>
            <div className="viewdata">Country of birth </div>
            <div className="viewdata"> {data.father_country_of_birth}</div>

            <div className="viewdata orange"> Mother's Details </div>

            <div className="viewdata"> Name </div>
            <div className="viewdata"> {data.mother_name}</div>
            <div className="viewdata">Nationality </div>
            <div className="viewdata"> {data.mother_nationality}</div>
            <div className="viewdata">Previous Nationality </div>
            <div className="viewdata"> {data.previous_mother_nationality}</div>
            <div className="viewdata">Place of birth </div>
            <div className="viewdata"> {data.mother_place_of_birth}</div>
            <div className="viewdata">Country of birth </div>
            <div className="viewdata"> {data.mother_country_of_birth}</div>

            {data.applicant_marital_status === "MARRIED" && (
              <>
                <div className="viewdata orange"> Spouse's Details </div>

                <div className="viewdata"> Name </div>
                <div className="viewdata"> {data.spouse_name}</div>
                <div className="viewdata">Nationality </div>
                <div className="viewdata"> {data.spouse_nationality}</div>
                <div className="viewdata">Previous Nationality </div>
                <div className="viewdata">
                  {" "}
                  {data.spouse_previous_nationality}
                </div>
                <div className="viewdata">Place of birth </div>
                <div className="viewdata"> {data.spouse_place_of_birth}</div>
                <div className="viewdata">Country of birth </div>
                <div className="viewdata"> {data.spouse_country_of_birth}</div>
                <div className="viewdata">
                  Were your Grandfather/ Grandmother (paternal/maternal)
                  Pakistan Nationals or Belong to Pakistan held area. -{" "}
                </div>
                <div className="viewdata">
                  {" "}
                  {data.grandparent_belongs_pakistan}
                </div>
              </>
            )}

            <div className="viewdata orange">
              {" "}
              Profession / Occupation Details of Applicant{" "}
            </div>

            <div className="viewdata"> Present Occupation </div>
            <div className="viewdata"> {data.spouse_occupation}</div>

            <div className="viewdata">Employer Name/business </div>
            <div className="viewdata">
              {" "}
              {data.spouse_parent_employer_business}
            </div>

            <div className="viewdata">Designation </div>
            <div className="viewdata">
              {" "}
              {data.spouse_parent_employer_designation}
            </div>

            <div className="viewdata">Address </div>
            <div className="viewdata">
              {" "}
              {data.spouse_parent_employer_address}
            </div>

            <div className="viewdata"> Phone </div>
            <div className="viewdata"> {data.spouse_parent_employer_phone}</div>

            <div className="viewdata">Past Occupation, if any </div>
            <div className="viewdata"> {data.spouse_past_occupation}</div>

            <div className="viewdata">
              Are/were you in a Military/Semi-Military/Police/Security.
              Organization? If yes,give details{" "}
            </div>
            <div className="viewdata">
              {" "}
              {data.in_security_organization === "False" ? "No" : "Yes"}
            </div>

            {data.in_security_organization === "False" ? (
              ""
            ) : (
              <>
                <div className="viewdata">Organization</div>
                <div className="viewdata">{data.security_organization}</div>
                <div className="viewdata">Designation</div>
                <div className="viewdata">{data.security_designation}</div>
                <div className="viewdata">Rank</div>
                <div className="viewdata">{data.security_rank}</div>
                <div className="viewdata">Place of Posting</div>
                <div className="viewdata">{data.security_place_of_posting}</div>
              </>
            )}

            <div className="viewdata orange"> Details of Visa Sought </div>

            <div className="viewdata"> Visa Type </div>
            <div className="viewdata"> eVisa</div>

            <div className="viewdata"> Visa Service </div>
            <div className="viewdata"> {data.visa_service}</div>

            <div className="viewdata"> Places to be visited1 </div>
            <div className="viewdata"> {data.places_visited}</div>

            <div className="viewdata"> Places to be visited2 </div>
            <div className="viewdata"> {data.places_visited_2}</div>

            <div className="viewdata"> Duration of Visa </div>
            <div className="viewdata">
              {switchFunction(data.visa_tourist_days).duration}
            </div>

            <div className="viewdata"> No. of Entries </div>
            <div className="viewdata">
              {" "}
              {switchFunction(data.visa_tourist_days).no_of_entries}
            </div>

            <div className="viewdata"> Purpose of Visit </div>
            <div className="viewdata"> {data.visa_service_type}</div>

            <div className="viewdata"> Port of Exit from India </div>
            <div className="viewdata"> {data.exp_port_of_exit}</div>

            <div className="viewdata orange">
              {" "}
              Previous Visa/Currently valid Visa Details{" "}
            </div>

            <div className="viewdata">
              {" "}
              Have you ever visited India before?{" "}
            </div>
            <div className="viewdata">
              {" "}
              {data.ever_visit_india ? "Yes" : "No"}
            </div>

            {data.ever_visit_india && (
              <>
                <div className="viewdata"> Address </div>
                <div className="viewdata"> {data.pre_visit_address}</div>

                <div className="viewdata"> Address </div>
                <div className="viewdata"> {data.pre_visit_address1}</div>

                <div className="viewdata"> Address </div>
                <div className="viewdata"> {data.pre_visit_address2}</div>

                <div className="viewdata">
                  {" "}
                  Cities previously visited in India{" "}
                </div>
                <div className="viewdata"> {data.pre_visit_cities}</div>

                <div className="viewdata">
                  {" "}
                  Last Indian Visa No/Currently valid Indian Visa No.{" "}
                </div>
                <div className="viewdata"> {data.pre_visit_last_visa_no}</div>

                <div className="viewdata"> Old Visa Type </div>
                <div className="viewdata"> {data.pre_visit_type_of_visa}</div>

                <div className="viewdata"> Old Visa Issue Place </div>
                <div className="viewdata"> {data.pre_visit_place_of_issue}</div>

                <div className="viewdata"> Old Visa Issue Date </div>
                <div className="viewdata">
                  {" "}
                  {data.pre_visit_date_of_issue &&
                    moment(data.pre_visit_date_of_issue).format("DD/MM/YYYY")}
                </div>

                <div className="viewdata">
                  {" "}
                  Has permission to visit or to extend stay in India previously
                  been refused?{" "}
                </div>
                <div className="viewdata">
                  {" "}
                  {data.pre_visit_permission_refused ? "Yes" : "No"}
                </div>
                <div className="viewdata">
                  Places where recruitment is to be conducted
                </div>
                <div className="viewdata">
                  {data.recruit_manpower_place_recruitment}
                </div>
                <div className="viewdata">Address,Phone no</div>
                <div className="viewdata">
                  {data.recruit_manpower_address_phone_no}
                </div>
              </>
            )}
{/* 
            {data.meeting_details_of_friend_relative && (
              <>
                <div className="viewdata orange">
                  Details of Purpose "MEETING FRIENDS/RELATIVES"{" "}
                </div>

                <div className="viewdata">Details of the Friend/Relative</div>
                <div className="viewdata">
                  {data.meeting_details_of_friend_relative}
                </div>
                <div className="viewdata">Address</div>
                <div className="viewdata">{data.meeting_address}</div>
                <div className="viewdata">State</div>
                <div className="viewdata">{data.meeting_state}</div>
                <div className="viewdata">District</div>
                <div className="viewdata">{data.meeting_district}</div>
                <div className="viewdata">Phone no</div>
                <div className="viewdata">{data.meeting_phone_no}</div>
                <div className="viewdata">
                  Places where recruitment is to be conducted
                </div>
                <div className="viewdata"></div>
                <div className="viewdata">Address,Phone no</div>
                <div className="viewdata"></div>
              </>
            )} */}

            {switchVisaService(data.visa_service)}

            {switchVisaServiceType(data.visa_service_type)}

            <div className="viewdata orange"> Other Information </div>

            <div className="viewdata"> Countries Visited in Last 10 years </div>
            <div className="viewdata"> {data.country_visited_10_years}</div>

            <div className="viewdata orange"> SAARC Country Visit Details </div>

            <div className="viewdata">
              {" "}
              Have you visited SAARC countries (except your own country) during
              last 3 years?{" "}
            </div>
            <div className="viewdata"> {data.visited_saarc ? "Yes" : "No"}</div>
            {data.visited_saarc && (
              <div className="viewdata" style={{ gridColumn: "1/3" }}>
                <Table bordered>
                  <thead>
                    <tr style={{ backgroundColor: "#ddd" }}>
                      <th>Name of SAARC Country</th>
                      <th>Year</th>
                      <th>No. of Visits</th>
                    </tr>
                  </thead>
                  <tbody>
                    {saarcCountry.map((el, i) => (
                      <tr key={i}>
                        <td>{el.saarc_country}</td>
                        <td>{el.year}</td>
                        <td>{el.no_of_visits}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}

            <div className="viewdata orange">Reference </div>

            <div className="viewdata"> Reference Name in India </div>
            <div className="viewdata"> {data.reference_name_in_india}</div>

            <div className="viewdata"> Address </div>
            <div className="viewdata"> {data.reference_address}</div>

            <div className="viewdata"> Address </div>
            <div className="viewdata"> {data.reference_address1}</div>

            <div className="viewdata"> Phone </div>
            <div className="viewdata"> {data.reference_phone}</div>

            <div className="viewdata"> Reference Name in your country </div>
            <div className="viewdata"> {data.reference_name_in_iceland}</div>

            <div className="viewdata"> Address </div>
            <div className="viewdata"> {data.reference_iceland_address}</div>

            <div className="viewdata"> Address </div>
            <div className="viewdata"> {data.reference_iceland_address1}</div>

            <div className="viewdata"> Phone </div>
            <div className="viewdata"> {data.reference_iceland_phone}</div>

            <div className="viewdata"> IP ADDRESS </div>
            <div className="viewdata"> {data.ip_address}</div>

            <div className="viewdata orange"> Additional Question Details </div>

            <div className="viewdata">
              {" "}
              Have you ever been arrested/ prosecuted/ convicted by Court of Law
              of any country?{" "}
            </div>
            <div className="viewdata">
              {" "}
              {data.arrested_prosecuted_convicted ? "Yes" : "No"}
            </div>

            <div className="viewdata">
              {" "}
              Have you ever been refused entry / deported by any country
              including India?{" "}
            </div>
            <div className="viewdata">
              {" "}
              {data.refused_entry_deported ? "Yes" : "No"}
            </div>

            <div className="viewdata">
              {" "}
              Have you ever been engaged in Human trafficking/ Drug trafficking/
              Child abuse/ Crime against women/ Economic offense / Financial
              fraud?{" "}
            </div>
            <div className="viewdata">
              {" "}
              {data.engaged_human_trafficking_drug ? "Yes" : "No"}
            </div>

            <div className="viewdata">
              {" "}
              Have you ever been engaged in Cyber crime/ Terrorist activities/
              Sabotage/ Espionage/ Genocide/ Political killing/ other act of
              violence?{" "}
            </div>
            <div className="viewdata">
              {" "}
              {data.engaged_cyber_crime_terrorist_activities ? "Yes" : "No"}
            </div>

            <div className="viewdata">
              {" "}
              Have you ever by any means or medium, expressed views that justify
              or glorify terrorist violence or that may encourage others to
              terrorist acts or other serious criminal acts{" "}
            </div>
            <div className="viewdata">
              {" "}
              {data.means_medium_expressed_views_justify_glorify_terrorist
                ? "Yes"
                : "No"}
            </div>

            <div className="viewdata">
              {" "}
              Have you sought asylum (political or otherwise)in any country?{" "}
            </div>
            <div className="viewdata">
              {" "}
              {data.have_you_sought_asylum ? "Yes" : "No"}
            </div>

            <div className="viewdata orange">Documents</div>

            <div className="viewdata"> user Image </div>
            <div className="viewdata">
              {" "}
              <img
                width={150}
                src={`https://api.indiavisasonline.org.in/${data.image_path}`}
              ></img>{" "}
              <button
                onClick={(e) =>
                  downloadURI(
                    e,
                    `https://api.indiavisasonline.org.in/${data.image_path}`,
                    data.image_path
                  )
                }
              >
                Download
              </button>
            </div>
            <>
              {data.document1_name && (
                <>
                  <div className="viewdata">
                    Copy of Passport page containing personal particulars:{" "}
                  </div>
                  <div className="viewdata">
                    <img
                      width={150}
                      src={`https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document1_name}`}
                    ></img>{" "}
                    <button
                      onClick={(e) =>
                        downloadURI(
                          e,
                          `https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document1_name}`,
                          data.document1_name
                        )
                      }
                    >
                      Download
                    </button>
                  </div>
                </>
              )}
            </>
            <>
              {data.document2_name && (
                <>
                  <div className="viewdata">Invitation on Organizer</div>
                  <div className="viewdata">
                    <img
                      width={150}
                      src={`https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document2_name}`}
                    ></img>{" "}
                    <button
                      onClick={(e) =>
                        downloadURI(
                          e,
                          `https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document2_name}`,
                          data.document2_name
                        )
                      }
                    >
                      Download
                    </button>
                  </div>
                </>
              )}
            </>
            <>
              {data.document3_name && (
                <>
                  <div className="viewdata">Political Clearance from MEA</div>
                  <div className="viewdata">
                    <img
                      width={150}
                      src={`https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document3_name}`}
                    ></img>{" "}
                    <button
                      onClick={(e) =>
                        downloadURI(
                          e,
                          `https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document3_name}`,
                          data.document3_name
                        )
                      }
                    >
                      Download
                    </button>
                  </div>
                </>
              )}
            </>
            <>
              {data.document4_name && (
                <>
                  <div className="viewdata">Event Clearance</div>
                  <div className="viewdata">
                    <img
                      width={150}
                      src={`https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document4_name}`}
                    ></img>{" "}
                    <button
                      onClick={(e) =>
                        downloadURI(
                          e,
                          `https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document4_name}`,
                          data.document4_name
                        )
                      }
                    >
                      Download
                    </button>
                  </div>
                </>
              )}
            </>
            <>
              {data.document5_name && (
                <>
                  <div className="viewdata">Copy of letter from hospital</div>
                  <div className="viewdata">
                    <img
                      width={150}
                      src={`https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document5_name}`}
                    ></img>{" "}
                    <button
                      onClick={(e) =>
                        downloadURI(
                          e,
                          `https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document5_name}`,
                          data.document5_name
                        )
                      }
                    >
                      Download
                    </button>
                  </div>
                </>
              )}
            </>
            <>
              {data.document6_name && (
                <>
                  <div className="viewdata">
                    Invitation of host insitute of the foreign faculty
                  </div>
                  <div className="viewdata">
                    <img
                      width={150}
                      src={`https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document6_name}`}
                    ></img>{" "}
                    <button
                      onClick={(e) =>
                        downloadURI(
                          e,
                          `https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document6_name}`,
                          data.document6_name
                        )
                      }
                    >
                      Download
                    </button>
                  </div>
                </>
              )}
            </>
            <>
              {data.document7_name && (
                <>
                  <div className="viewdata">
                    Copy of the section order under GIAN issued by the National
                    Coordinating Insitute viz. IIT Kharagpur
                  </div>
                  <div className="viewdata">
                    <img
                      width={150}
                      src={`https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document7_name}`}
                    ></img>{" "}
                    <button
                      onClick={(e) =>
                        downloadURI(
                          e,
                          `https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document7_name}`,
                          data.document7_name
                        )
                      }
                    >
                      Download
                    </button>
                  </div>
                </>
              )}
            </>
            <>
              {data.document8_name && (
                <>
                  <div className="viewdata">
                    Copy of the synopsis of the courses to be take up by the
                    faculty
                  </div>
                  <div className="viewdata">
                    <img
                      width={150}
                      src={`https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document8_name}`}
                    ></img>{" "}
                    <button
                      onClick={(e) =>
                        downloadURI(
                          e,
                          `https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document8_name}`,
                          data.document8_name
                        )
                      }
                    >
                      Download
                    </button>
                  </div>
                </>
              )}
            </>

            <>
              {data.document9_name && (
                <>
                  <div className="viewdata">Business Card: </div>
                  <div className="viewdata">
                    <img
                      width={150}
                      src={
                        `https://api.indiavisasonline.org.in/Upload/` +
                        data.temporary_id +
                        "/" +
                        data.document9_name
                      }
                    ></img>{" "}
                    <button
                      onClick={(e) =>
                        downloadURI(
                          e,
                          `https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document9_name}`
                        )
                      }
                    >
                      Download
                    </button>
                  </div>
                </>
              )}
            </>
            <>
              {data.document10_name && (
                <>
                  <div className="viewdata">
                    Copy of letter from insitute or organisation or hospital
                    concerned on it's letter-head
                  </div>
                  <div className="viewdata">
                    <img
                      width={150}
                      src={`https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document10_name}`}
                    ></img>{" "}
                    <button
                      onClick={(e) =>
                        downloadURI(
                          e,
                          `https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document10_name}`,
                          data.document10_name
                        )
                      }
                    >
                      Download
                    </button>
                  </div>
                </>
              )}
            </>
            <>
              {data.document11_name && (
                <>
                  <div className="viewdata">
                    Copy of letter from organisation concerned on its
                    letter-head.
                  </div>
                  <div className="viewdata">
                    <img
                      width={150}
                      src={`https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document11_name}`}
                    ></img>{" "}
                    <button
                      onClick={(e) =>
                        downloadURI(
                          e,
                          `https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document11_name}`,
                          data.document11_name
                        )
                      }
                    >
                      Download
                    </button>
                  </div>
                </>
              )}
            </>
            <>
              {data.document12_name && (
                <>
                  <div className="viewdata">Business Invitation Letter: </div>
                  <div className="viewdata">
                    <img
                      width={150}
                      src={
                        `https://api.indiavisasonline.org.in/Upload/` +
                        data.temporary_id +
                        "/" +
                        data.document12_name
                      }
                    ></img>{" "}
                    <button
                      onClick={(e) =>
                        downloadURI(
                          e,
                          `https://api.indiavisasonline.org.in/Upload/${data.temporary_id}/${data.document12_name}`,
                          data.document12_name
                        )
                      }
                    >
                      Download
                    </button>
                  </div>
                </>
              )}
            </>
          </div>
        ))}
      </div>
    </>
  );
}




<th>No</th>
                      