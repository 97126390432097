import React, { useState, useEffect } from "react";
import { commonAPICall } from "../../services/services";
import moment from "moment";
import Table from "react-bootstrap/Table";
import {
  BrijDateRange,
  DropDownButton,
  showErrorToast,
  showSucessToast,
} from "../../components/common";

export default function preview() {
  const [ticketBookingAllDetail, setTicketBookingAllDetail] = useState([]);
  const [ticketBookingData, setTicketBookingData] = useState([]);
  const [person, setPerson] = useState([]);
  useEffect(() => {
    const previewData = async () => {
      const id = window.location.pathname.split("/");
      try {
        const result = await commonAPICall(
          "/taj/preview_ticket_booking_detail",
          "post",
          {
            tran_id: id[4],
          }
        );
        setTicketBookingAllDetail(result.ticketBookingAllDetail);
        setTicketBookingData(result.ticketBookingData);
      } catch (err) {
        showErrorToast(err);
      }
    };
    previewData();
  }, []);

  useEffect(() => {
    const personName = async () => {
      const id = window.location.pathname.split("/");
      try {
        const result = await commonAPICall(
          "/taj/TM_Application_preview_person_name",
          "post",
          {
            tran_id: id[4],
          }
        );
        setPerson(result.data);
      } catch (err) {
        showErrorToast(err);
      }
    };
    personName();
  }, []);

  return (
    <div className="preview_table_wrapper">
      <div className="title_box">
        <h1>Your eTV Application Details</h1>
      </div>

      {ticketBookingData.map((data) => (
        <div className="datatab_d">
          <div className="viewdata text-white" style={{backgroundColor: 'orangered'}}> Application id no. : - </div>
          <div className="viewdata text-white" style={{backgroundColor: 'orangered'}}> {data.id_no}</div>
          <div className="viewdata">Email : - </div>
          <div className="viewdata"> {data.email}</div>
          <div className="viewdata"> Mobile Number : - </div>
          <div className="viewdata"> {data.mobile_no}</div>
          <div className="viewdata"> Nationality : - </div>
          <div className="viewdata"> {data.nationality}</div>
          <div className="viewdata">Temporary id : - </div>
          <div className="viewdata"> {data.id_no}</div>
          <div className="viewdata"> Id type : - </div>
          <div className="viewdata"> {data.id_type}</div>
          <div className="viewdata"> Booking Date : - </div>
          <div className="viewdata">
            {" "}
            {moment(data.date).format("DD/MM/YYYY")}
          </div>
        </div>
      ))}
      <div className="tableHead title_box">
        <h5>Monument Details </h5>
      </div>
      {ticketBookingAllDetail.map((details) => (
        <div className="w-100">
          <Table striped bordered>
            <thead>
              <tr>
                <td>Visit date</td>
                <th>City name</th>
                <th>City code</th>
                <th>Monument</th>
                <th>Visit time</th>
                <th>No of child</th>
                <th>No of Adult</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> {moment(details.visit_date).format("DD/MM/YYYY")}</td>
                <td> {details.city_name}</td>
                <td> {details.city_code}</td>
                <td> {details.monument_name}</td>
                <td> {details.visit_time}</td>
                <td> {details.no_of_child}</td>
                <td> {details.no_of_adult}</td>
                <td> {details.amount}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      ))}
      {/* <div className="tableHead title_box">
        <h5>Personal Details</h5>
      </div> */}
      <Table striped bordered className="bg-white mt-4">
        <thead>
          <tr className="text-center bg-primary text-white">
            <th colSpan={2}>Personal Details</th>
          </tr>
          <tr>
            <th>Type</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {person.length > 0
            ? person.map((val, ind) => {
                return (
                  <>
                    <tr>
                      <td>{val.type}</td>
                      <td>{val.name}</td>
                    </tr>
                    {/* <p>
                      {" "}
                      <span></span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp; <span> </span>{" "}
                    </p> */}
                  </>
                );
              })
            : " "}
        </tbody>
      </Table>
     
    </div>
  );
}
