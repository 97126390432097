import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import moment from "moment";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { commonAPICall } from "../../services/services";
import {
  BrijDateRange,
  showErrorToast,
  showSucessToast,
} from "../../components/common";
const user_id = JSON.parse(localStorage.getItem("auth"))?.user_id;

function TajRemark({ showtype, handleshowType, data }) {
  const [show, setShow] = useState(false);
  const [Remarks, setRemarks] = useState([]);
  const [formdata, setFormdata] = useState({
    tran_id: 0,
    application_id: "",
    remarks: "",
    user_id: user_id,
  });

  const handleSubmitremarks = async () => {
    try {
      const obj = { ...formdata };
      obj.application_id = data;
      const result = await commonAPICall(
        "/taj/TM_Application_Remarks_Insert",
        "post",
        obj
      );
      if (result.status == 200) {
        previewremarks();
        showSucessToast("Remarks Add successfully");
      }
      setShow(false);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const previewremarks = async () => {
    try {
      const result = await commonAPICall(
        "/taj/TM_Application_remarks_preview",
        "post",
        { application_id: data }
      );
      if (result.valid) {
        setRemarks(result.data);
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const handlechanger = (event) => {
    setFormdata({ ...formdata, [event.target.name]: event.target.value });
  };

  const handleClose = () => {
    setShow(false);
    handleshowType(false);
  };

  useEffect(() => {
    setShow(showtype);
    previewremarks();
  }, [showtype]);

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Remark * </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="remarks_list_box">
        {Remarks.length > 0
                ? Remarks.map((val, ind) => {
                    return (
                      <>
                        <h3> {val.remarks_by}</h3>
                        <p>
                          {" "}
                          <span>{val.remarks} </span>{" "}
                          <span>
                            {moment(data.datetime).format("DD/MM/YYYY")}{" "}
                          </span>{" "}
                          ,
                          <span>
                            {" "}
                            {moment(data.datetime).format("hh:mm:ss A")}
                          </span>{" "}
                        </p>{" "}
                        <hr></hr>
                      </>
                    );
                  })
                : " "}
        </div>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              
              <Modal.Title>Add Remark * </Modal.Title>
              <Form.Control
                as="textarea"
                name="remarks"
                onChange={handlechanger}
                value={formdata.remarks}
                style={{ height: "100px" }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button  size="sm" variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button size="sm"  variant="success" onClick={handleSubmitremarks}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TajRemark;
