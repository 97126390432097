import moment from "moment";
import React, { useState, useEffect } from "react";
import { Form, Button, Col, Dropdown, Row } from "react-bootstrap";
import BrijCustomDataTable from "../../components/brijDataTable";
import { FaCog } from "react-icons/fa";
import {
  BrijDateRange,
  showErrorToast,
  showSucessToast,
} from "../../components/common";
import { selectionRange } from "../../components/constant";
import { commonAPICall } from "../../services/services";
import TurkeyPopup from "./TurkeyPopup";
import Modal from "react-bootstrap/Modal";
import { mainUrl, previewLiveUrl } from "../../config";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import MailSendPopup from "./MailSendPopup";
import { toast } from "react-toastify";

const UnPaidApplicationBrowse = () => {
  const [salesExecutiveList, setSalesExecutiveList] = useState([]);
  const [paymentData, setpaymentData] = useState([]);
  const [excelData, setexcelData] = useState([]);
  const [dateFilterRange, setDateFilterRange] = useState({
    selection: selectionRange,
  });
  const [showAdditionalFee, setshowAdditionalFee] = useState(false);
  const [selectVisaStatus, setselectVisaStatus] = useState({
    tran_id: 0,
  });
  const [selectSalesStatus, setselectSalesStatus] = useState({
    tran_id: 0,
  });
  const [selectStatusApplication, setselectStatusApplication] = useState({
    tran_id: 0,
  });
  const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);
  const [deleteConfirmData, setdeleteConfirmData] = useState({});
  const [application, setapplication] = useState();
  const [showApplicationFee, setShowApplicationFee] = useState(false);
  const [paidData, setPaidData] = useState({
    amount: null,
    id: null,
  });
  const [subBodyQuery, setsubBodyQuery] = useState({
    application_id: "",
    passport_no: "",
    email: "",
  });
  const [showMailSend, setshowMailSend] = useState(false);
  const [sendMailData, setsendMailData] = useState({});

  const [show, setShow] = useState(false);
  const [showType, setshowType] = useState(false);
  const [bodyQuery, setbodyQuery] = useState({
    application_id: "",
    application_color: "",
    passport_no: "",
    email: "",
    website_url: "",
    application_type: "",
    agent_id: "",
    sales_manager_status: "",
    sales_status: "",
    visa_service: "",
    deo_status: "",
    nationality: "",
    visitor_id: "",
    deo_manager_status: "",
    payment_amount: 0, //
    filter_column: "",
    search: "",
    pageSize: 15,
    pageNo: 1,
  });

  const [apibody, setapibody] = useState({
    from_date: null,
    to_date: null,
    payment_from_date: null,
    payment_to_date: null,
    travel_from_date: null,
    travel_to_date: null,
    agent_id: null,
  });

  const handleSubmit = async () => {
    try {
      const result = await commonAPICall("/taj/extra_payment_update", "post", {
        temporary_id: 0,
        amount: paidData.amount,
      });
      setShow(false);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const updateAllSalesExecutiveList = async () => {
    try {
      const result = await commonAPICall("/tur/list_sales_users", "get", "");

      setSalesExecutiveList(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const updateVisaStatus = async (e) => {
    try {
      const result = await commonAPICall("/tur/update_visa_status", "post", {
        tran_id: selectVisaStatus.tran_id,
        status: e.target.value,
      });
      if (result.status === 200) {
        setbodyQuery({ ...bodyQuery, reload: Math.random() * 3 });
        showSucessToast("Visa Status Updated");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const updateSalesStatus = async (e) => {
    try {
      const result = await commonAPICall("/tur/update_sales_status", "post", {
        tran_id: selectSalesStatus.tran_id,
        status: e.target.value,
      });
      if (result.status === 200) {
        setbodyQuery({ ...bodyQuery, reload: Math.random() * 3 });
        showSucessToast("Sales Status Updated");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const updateDeoManagerStatus = async (e) => {
    try {
      const result = await commonAPICall("/tur/update_deo_status", "post", {
        tran_id: selectStatusApplication?.tran_id,
        status: e.target.value,
      });
      if (result.status === 200) {
        setbodyQuery({ ...bodyQuery, reload: Math.random() * 3 });
        showSucessToast("Application Status Updated");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  useEffect(() => {
    updateAllSalesExecutiveList();
  }, []);

  const handlechangeSub = (e) => {
    const obj = { application_id: "", passport_no: "", email: "" };
    obj[e.target.name] = e.target.value;
    setsubBodyQuery(obj);
    if (e.key === "Enter") {
      setbodyQuery({
        ...bodyQuery,
        application_id: obj.application_id,
        passport_no: obj.passport_no,
        email: obj.email,
      });
      setsubBodyQuery({ application_id: "", passport_no: "", email: "" });
    }
  };

  const handleShow = (id, amount) => {
    setPaidData({ ...paidData, id: id, amount: amount });
    setShow(true);
  };

  const handeleExcelData = (data) => {
    setexcelData(data);
  };

  const handleButtonClick = (e, temporary_id, tran_id) => {
    const url = `${mainUrl}/preview/Turkey/${temporary_id}/${tran_id}`;
    const windowOptions = "width=600,height=400,scrollbars=yes";
    window.open(url, "_blank", windowOptions);
  };
  const openRemarks = (tran_id) => {
    setapplication(tran_id);
    setshowType(!showType);
  };

  const handleShowType = (type) => {
    setshowType(type);
  };
  const paymentApiCall = async () => {
    try {
      const result = await commonAPICall("/taj/list_payment_amount", "post", {
        from_date: apibody.from_date,
        to_date: apibody.to_date,
      });
      setpaymentData(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  useEffect(() => {
    paymentApiCall();
  }, [apibody.from_date, apibody.to_date]);

  const handleChange = (e) => {
    setbodyQuery({ ...bodyQuery, [e.target.name]: e.target.value });
  };

  const handleMailSend = (row) => {
    setsendMailData(row);
    setshowMailSend(true);
  };
  const dateFilter = (date, type) => {
    const startDate = moment(date.startDate).format("MM/DD/YYYY");
    const endDate = moment(date.endDate).format("MM/DD/YYYY");
    if (type[2] === "application") {
      setapibody({ ...apibody, from_date: startDate, to_date: endDate });
    }
    if (type[2] === "travel") {
      setapibody({
        ...apibody,
        travel_from_date: startDate,
        travel_to_date: endDate,
      });
    }
  };

  let previewWindow = null;

  const handlePreviewAction = (temporary_id, tran_id) => {
    const url = `${previewLiveUrl}/preview/turkey/${temporary_id}/${tran_id}`;
    const windowOptions = "width=600,height=600,scrollbars=yes";
    if (!previewWindow || previewWindow.closed) {
      previewWindow = window.open(url, "_blank", windowOptions);
    } else {
      // If the window is minimized, bring it to the front
      if (previewWindow.outerWidth === 0) {
        previewWindow.focus();
      }
      // Refresh the content
      previewWindow.location.href = url;
    }
  };

  const handleShowAddictionalFee = (id, amount) => {
    setshowAdditionalFee(true);
  };

  const handleShowMoveToPaid = (row) => {
    setPaidData(row);
    setShowApplicationFee(true);
  };

  const handleSubmitApplicationFee = async () => {
    try {
      const result = await commonAPICall(
        "/tur/move_to_unpaid_payment_insert",
        "post",
        paidData
      );
      setshowAdditionalFee(false);
      setShowApplicationFee(false);
    } catch (err) {
      showErrorToast(err);
    }
  };
  const handleDelete = (row) => {
    setshowDeleteConfirm(true);
    setdeleteConfirmData(row);
  };

  const deletApplicationHandler = async (id) => {
    try {
      const result = await commonAPICall(
        `/tur/application_delete/${id}`,
        "delete",
        ""
      );
      if (result.status === 200) {
        setbodyQuery({ ...bodyQuery, apibody: bodyQuery.apibody + 1 });
        setshowDeleteConfirm(false);
        toast.success("Removed successfully");
      }
    } catch (err) {
      console.error(err);
    }
  };


  const columns = [
    {
      id: "Application Date",
      name: "Application Date",
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(row.datetime).format("DD/MM/YYYY")}
            <span> {moment(row.datetime).format("hh:mm A")}</span>
          </div>
        );
      },
      sortable: true,
      omit: false,
      minWidth: "115px",
    },
    {
      id: "Travel Date",
      name: "Travel Date",
      selector: (row) => {
        console.log('TRAVEL DATE', row.exp_date_of_arrival);
        return (
          <div className="table__dttime">
            {moment(row.exp_date_of_arrival.replace("Z", "")).format("DD/MM/YYYY")}
          </div>
        );
      },
      sortable: true,
      omit: false,
      minWidth: "75px",
    },
    {
      id: "Application Id",
      name: "Application ID",
      selector: (row) => row.temporary_id,
      sortable: true,
      omit: false,
      minWidth: "129px",
    },

    {
      id: "Application Name",
      name: "Application Name",
      selector: (row) => row.name,
      sortable: true,
      omit: false,
      minWidth: "200px",
    },
    {
      id: "Passport No.",
      name: "Passport No.",
      selector: (row) => row.passport_number,
      sortable: true,
      omit: false,
      minWidth: "120px",
    },

    {
      id: "applicant_email",
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      omit: false,
      cell: (row) => <div style={{ whiteSpace: "normal" }}>{row.email}</div>,
      minWidth: "250px",
    },

    {
      id: "Contact No.",
      name: "Contact No.",
      selector: (row) => row.telephone,
      sortable: true,
      omit: false,
      minWidth: "120px",
    },
    {
      id: "visa_status",
      name: "Visa Status",
      omit: false,
      selector: (row) => {
        return (
          <div className="w-100">
            <div className="w-100">
              {selectVisaStatus?.tran_id !== row.tran_id ? (
                <p
                  className="grid_select_value"
                  onMouseOver={() => setselectVisaStatus(row)}
                >
                  {row.visa_status ? row.visa_status : "N/A"}
                </p>
              ) : (
                <select
                  name="sale Status"
                  className="grid_select_input"
                  value={selectVisaStatus?.visa_status}
                  onChange={updateVisaStatus}
                  onMouseOut={() =>
                    setselectVisaStatus({ ...selectVisaStatus, tran_id: 0 })
                  }
                >
                  <option value={""}>N/A</option>
                  <option value={"Granted"}>Granted</option>
                  <option value={"Rejected"}>Rejected</option>
                  <option value={"Reupload"}>Reupload</option>
                  <option value={"Under Process"}>Under Process</option>
                  <option value={"Not Processes"}>Not Processes</option>
                </select>
              )}
            </div>
          </div>
        );
      },
      sortable: true,
      width: "143px",
    },

    {
      id: "Sales Manager Status",
      name: "Sales Manager Status",
      selector: (row) => row.sales_manager_status,
      sortable: true,
      omit: false,
      minWidth: "170px",
    },
    {
      id: "Sales Status",
      name: "Sales Status",
      selector: (row) => row.sales_status,
      sortable: true,
      omit: false,
      minWidth: "155px",
      selector: (row) => {
        return (
          <div className="w-100">
            {selectSalesStatus?.tran_id !== row.tran_id ? (
              <p
                className="grid_select_value"
                onMouseOver={() => setselectSalesStatus(row)}
              >
                {row.sales_status ? row.sales_status : "N/A"}
              </p>
            ) : (
              <select
                name="sale Status"
                className="grid_select_input"
                value={selectSalesStatus?.sales_status}
                onChange={updateSalesStatus}
                onMouseOut={() =>
                  setselectSalesStatus({ ...selectSalesStatus, tran_id: 0 })
                }
              >
                <option value={""}>N/A</option>
                <option value={"Fresh Application"}>Fresh Application</option>
                <option value={"Will Pay Soon"}>Will Pay Soon</option>
                <option value={"No Call Response"}>No Call Response</option>
                <option value={"Voice Message"}>Voice Message</option>
                <option value={"Close"}>Close</option>
                <option value={"Others"}>Others</option>
              </select>
            )}
          </div>
        );
      },
    },
    {
      id: "DEO Manager Status",
      name: "DEO Manager Status",
      selector: (row) =>
        row.deo_manager_status ? row.deo_manager_status : <div>N/A</div>,
      sortable: true,
      omit: false,
      minWidth: "160px",
    },
    {
      id: "application_status",
      name: "Application Status",
      omit: false,
      selector: (row) => {
        return (
          <div className="w-100">
            {selectStatusApplication?.tran_id !== row.tran_id ? (
              <p
                className="grid_select_value"
                onMouseOver={() => setselectStatusApplication(row)}
              >
                {row.deo_status ? row.deo_status : "N/A"}
              </p>
            ) : (
              <select
                name="sale Status"
                className="grid_select_input"
                value={selectStatusApplication?.deo_status}
                onChange={updateDeoManagerStatus}
                onMouseOut={() =>
                  setselectStatusApplication({
                    ...selectStatusApplication,
                    tran_id: 0,
                  })
                }
              >
                <option value={""}>N/A</option>
                <option value={"Fresh Application"}>Fresh Application</option>
                <option value={"Complete Application"}>
                  Complete Application
                </option>
                <option value={"Pending"}>Pending</option>
                <option value={"Wants to Cancle"}>Wants to Cancle</option>
                <option value={"Refund"}>Refund</option>
                <option value={"Hold"}>Hold</option>
                <option value={"Late Arrival"}>Late Arrival</option>
              </select>
            )}
          </div>
        );
      },
      sortable: true,
      minWidth: "153px",
    },

    {
      id: "sales_user_name",
      name: "Sales",
      sortable: true,
      omit: false,
      minWidth: "100px",
      selector: (row) => {
        return (
          <div className="table__dttime text-start">
            {row.sales_user_name}
            {row.sales_assign_datetime && (
              <span className="bg-primary">
                {" "}
                {moment(row.sales_assign_datetime).format("DD/MM/YYYY")}
              </span>
            )}
          </div>
        );
      },
    },

    {
      id: "sales_user_name",
      name: "Sales Executive",
      sortable: true,
      omit: false,
      minWidth: "130px",
      selector: (row) => {
        return (
          <div className="table__dttime text-start">
            {row.sales_user_name}
            {row.sales_manager_status === "Assigned" ? (
              <span className="bg-primary">
                {moment(row.sales_assign_datetime).format("DD/MM/YYYY")}
              </span>
            ) : null}
          </div>
        );
      },
    },
    {
      id: "Payment",
      name: "Payment Status",
      selector: (row) => row.payment_status,
      sortable: true,
      omit: false,
      minWidth: "100px",
    },

    {
      id: "Mail status",
      name: "Mail Status",
      // selector: (row) => row.mail_send,
      selector: (row) => {
        return (
          <div className="tbl__mailsent">
            send<sup>{row.partial_mail_count}</sup>
          </div>
        );
      },
      sortable: true,
      omit: false,
      minWidth: "97px",
    },

    {
      id: "Last Active",
      name: "Last Active",
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(row.last_active).format("DD/MM/YYYY")}
            <span> {moment(row.last_active).format("hh:mm:ss A")}</span>
          </div>
        );
      },
      sortable: true,
      omit: false,
    },
    {
      id: "site",
      name: "Site",
      selector: (row) => row.website_url,
      sortable: true,
      omit: false,
      minWidth: "120px",
    },

    {
      id: "move_to_paid",
      name: "Move to Paid",
      omit: false,
      selector: (row) => {
        return (
          <div className="customBtn_p">
            <Button
              size="sm"
              variant="danger"
              onClick={() => handleShowMoveToPaid(row)}
            >
              Move to Paid
            </Button>
          </div>
        );
      },
      sortable: true,
      minWidth: "110px",
    },

    {
      id: "action",
      name: "Action",
      omit: false,
      selector: (row) => {
        return (
          <>
            <Dropdown className="action_dropdown">
              <Dropdown.Toggle variant="dark" size="sm">
                <FaCog />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => openRemarks(row.tran_id)}>
                  Remarks
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={() => {
                    handleMailSend(row);
                    // setshowMailSend(showMailSend);
                  }}
                >
                  M-Send
                </Dropdown.Item> */}
                <Dropdown.Item
                  onClick={(e) =>
                    handlePreviewAction(row.temporary_id, row.tran_id)
                  }
                >
                  View
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    handleDelete(row);
                  }}
                >
                  Delete
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    handleShowAddictionalFee(row.temporary_id, row.pay_amount);
                  }}
                >
                  Additional Fee
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
      sortable: true,
      minWidth: "70px",
    },

    // {
    //   id: "action",
    //   name: "Action",
    //   omit: false,
    //   selector: (row) => {
    //     return (
    //       <>
    //         <ButtonGroup>
    //           <Button
    //             onClick={(e) =>
    //               handleButtonClick(e, row.temporary_id, row.tran_id)
    //             }
    //           >
    //             <FaEye />
    //           </Button>
    //           <Button onClick={(e) => openRemarks(row.tran_id)}>
    //             <FaEdit />
    //           </Button>

    //           {/* <Button> <FaBeer /> </Button> */}
    //         </ButtonGroup>
    //       </>
    //     );
    //   },
    //   sortable: true,
    //   width: "auto",
    // },
  ];

  return (
    <div className="browse-list">
      <div className="application_form_wrapper">
        <div className="container-fluid">
          <div className="row">
            <Col md={2} className="mb-1">
              <Form.Control
                size="sm"
                value={subBodyQuery.application_id}
                onChange={handlechangeSub}
                onKeyUp={handlechangeSub}
                type="text"
                name="application_id"
                placeholder="Application ID"
              />
            </Col>
            <Col md={2} className="mb-1">
              <Form.Control
                size="sm"
                value={subBodyQuery.passport_no}
                onChange={handlechangeSub}
                onKeyUp={handlechangeSub}
                type="text"
                name="passport_number"
                placeholder="Passport No."
              />
            </Col>
            <Col md={2} className="mb-1">
              <Form.Control
                size="sm"
                value={subBodyQuery.email}
                onChange={handlechangeSub}
                onKeyUp={handlechangeSub}
                type="text"
                name="email"
                placeholder="Email"
              />
            </Col>
            <Col md={2} className="mb-1">
              <Form.Select size="sm" name="website_url" onChange={handleChange}>
                <option value="">All Sites Application</option>
                <option value="indiansvisa.org.in">indiansvisa.org.in</option>
                <option value="indiavisasonline.org.in">
                  indiavisasonline.org.in
                </option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                name="application_type"
                onChange={handleChange}
              >
                <option value="">All Application Type</option>
                <option value="Normal">Normal Application</option>
                <option value="Urgent">Urgent Application</option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Application"
                pickClass={"right"}
              />
            </Col>
            <Col md={2} className="mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Travel"
                pickClass={"left"}
              />
            </Col>
            <Col md={2} className="mb-1">
              <Form.Select size="sm" name="agent_id" onChange={() => {}}>
                <option value={""}>All Sales Executive</option>
                {salesExecutiveList.map((value) => (
                  <option key={value.user_id} value={value.user_id}>
                    {value.full_name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                name="visa_service"
                onChange={handleChange}
              >
                <option value="">All Visa Services</option>
                <option value="Business">Business</option>
                <option value="Tourist">Tourist</option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                onChange={handleChange}
                name="sales_manager_status"
              >
                <option value="">All Sales Manager Status</option>
                <option value="Not Assigned">Not Assigned</option>
                <option value="Assigned">Assigned</option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                name="sales_status"
                onChange={handleChange}
              >
                <option value="">Sales Status</option>
                <option value="Fresh Application">Fresh Application</option>
                <option value="Will Pay Soon">Will Pay Soon</option>
                <option value="No Call Response">No Call Response</option>
                <option value="Voice Message">Voice Message</option>
                <option value="Paid">Paid</option>
                <option value="Close">Close</option>
                <option value="Others">Others</option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-1">
              <Form.Select size="sm" name="deo_status" onChange={handleChange}>
                <option value={""}>All Application Status</option>
                <option value={"Fresh Application"}>Fresh Application</option>
                <option value={"Complete Application"}>
                  Complete Application
                </option>
                <option value={"Pending"}>Pending</option>
                <option value={"Wants to Cancle"}>Wants to Cancle</option>
                <option value={"Refund"}>Refund</option>
                <option value={"Hold"}>Hold</option>
                <option value={"Late Arrival"}>Late Arrival</option>
              </Form.Select>
            </Col>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <BrijCustomDataTable
          columns={columns}
          url="/tur/main_browse"
          apiBody={apibody}
          paramsBody={bodyQuery}
          excelData={handeleExcelData}
          assingUrl="/tur/Turkey_Application_assign_insert"
          type={"unpaid"}
        />

        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              Application Id : <span>{paidData.id}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <h3>
              {" "}
              Amount :{" "}
              <span>
                <input
                  type="number"
                  value={paidData.amount}
                  onChange={(e) =>
                    setPaidData({ ...paidData, amount: e.target.value })
                  }
                />
              </span>{" "}
            </h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showApplicationFee}
          onHide={() => setShowApplicationFee(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title className="fs-5">
              {" "}
              Application Fee : <span>{paidData.temporary_id}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center gap-3">
              <h5 className="flex-1">Amount:</h5>
              <Form.Control
                size="sm"
                type="number"
                value={paidData.application_amount}
                onChange={(e) =>
                  setPaidData({
                    ...paidData,
                    application_amount: e.target.value,
                  })
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="danger"
              onClick={() => setShowApplicationFee(false)}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              variant="success"
              onClick={handleSubmitApplicationFee}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Additional fee =========================== */}
        <Modal
          show={showAdditionalFee}
          onHide={() => setshowAdditionalFee(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              Additional Fee : <span>{paidData.id}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center gap-3">
              <h5 className="flex-1">Amount:</h5>
              <Form.Control
                size="sm"
                type="number"
                value={paidData.amount}
                onChange={(e) =>
                  setPaidData({ ...paidData, amount: e.target.value })
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="danger"
              onClick={() => setShowApplicationFee(false)}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              variant="success"
              onClick={handleSubmitApplicationFee}
            >
              Save & Send
            </Button>
            <Button
              size="sm"
              variant="success"
              onClick={handleSubmitApplicationFee}
            >
              Save & Preview
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Additional fee india=========================== */}

		<Modal
          show={showDeleteConfirm}
          onHide={() => setshowDeleteConfirm(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title className="fs-5">
              Are you sure you want to delete?
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col xs={12} className="mb-1">
                <strong>Application Id:</strong>
                <br /> {deleteConfirmData?.temporary_id}
              </Col>
              <Col xs={6} className="mb-1">
                <strong>Name:</strong>
                <br />
                <span className="text-ellipses">{deleteConfirmData?.name}</span>
              </Col>
              <Col xs={6} className="mb-1">
                <strong>Email:</strong>
                <br />
                <span className="text-ellipses">
                  {deleteConfirmData?.email}
                </span>
              </Col>
              <Col xs={6} className="mb-1">
                <strong>Payment</strong>
                <br />
                <span className="text-ellipses">
                  {deleteConfirmData?.payment}
                </span>
              </Col>
              <Col xs={6} className="mb-1">
                <strong>Payment Status</strong>
                <br />
                <span className="text-ellipses">
                  {deleteConfirmData?.payment_status}
                </span>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="success"
              onClick={() =>
                deletApplicationHandler(deleteConfirmData?.tran_id)
              }
            >
              Confirm
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={() => setshowDeleteConfirm(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <TurkeyPopup
        showtype={showType}
        handleshowType={handleShowType}
        data={application}
      />
      <MailSendPopup
        data={sendMailData}
        handleMailShowType={setshowMailSend}
        showMailSend={showMailSend}
      />
    </div>
  );
};

export default UnPaidApplicationBrowse;
