import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { commonAPICall } from "../services/services";
import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
export const showSucessToast = (msg) => {
  toast.success(msg, {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
export const showErrorToast = (msg) => {
  toast.error(msg, {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
export const authUser = (data) => {
  localStorage.setItem("auth", JSON.stringify(data));
};
export const getAuthUser = () => {
  return localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth"))
    : null;
};
export const preventNumber = (event) => {
  if (/^\d*$/.test(event.target.value)) {
    return false;
  }
};
export const validateNumber = (event) => {
  const keyCode = event.keyCode;
  const excludedKeys = [8, 37, 39, 46];
  if (
    !(
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      excludedKeys.includes(keyCode)
    )
  ) {
    event.preventDefault();
  }
};
// ============== Common Date Range Picker =================== ////
export const BrijDateRange = ({ onChange, dateLevel, pickClass }) => {
  const [showPicker, setShowPicker] = useState(false);
  const toggleDatePicker = () => setShowPicker(!showPicker);
  const [loading, setLoading] = useState(false);
  const [dateFilterRange, setDatefilterRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const set = () => {
    setLoading(false);
  };
  const dateFilter = async (date) => {
    const type = dateLevel.toLowerCase().split(" ");
    setDatefilterRange(date?.selection);
    onChange(date?.selection, type);
    setLoading(true);
  };
  return (
    <div className="custom-daterange-picker">
      <div
        className="custom-date-input d-flex align-items-center"
        onClick={toggleDatePicker}
      >
        <div className="custom-date-icon ">
          <img src="/assets/images/calendar.png" />
        </div>
        <span style={{ fontSize: ".875rem" }}>
          {loading
            ? moment(dateFilterRange?.startDate).format("DD/MM/YYYY") +
              " - " +
              moment(dateFilterRange?.endDate).format("DD/MM/YYYY")
            : dateLevel}
        </span>
      </div>
      {showPicker && (
        <OutsideClickHandler onOutsideClick={toggleDatePicker}>
          <div className={`custom-daterange-popup ${pickClass}`}>
            <DateRangePicker ranges={[dateFilterRange]} onChange={dateFilter} />
            <div className="text-end picker-toggler">
              <button
                className="btn btn-link "
                type="button"
                onClick={toggleDatePicker}
              >
                OK
              </button>
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};
export function currenyMasking(val) {
  if (val && val !== "") {
    var format = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
    });
    // var x = val.toString();
    // var lastThree = x.substring(x.length-3);
    // var otherNumbers = x.substring(0,x.length-3);
    // if(otherNumbers != '')
    //     lastThree = ',' + lastThree;
    // var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return format.format(parseFloat(val).toFixed(2));
  } else {
    return 0.0;
  }
}
// const RoutesWithSubRoutes = (routes) => {
//     re
// }
