import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { commonAPICall } from "../services/services";
import { ExcelExport } from "../components/excelExport";
import XLSX from "sheetjs-style";
function VietnamFee() {
  //  country data  start
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const handleUpdateFee = (e, event) => {
    const arrData = [...countryData];
    const index = countryData.indexOf(e);
    arrData[index][event.target.name] = event.target.value;
    setCountryData(arrData);
  };
  const countryDataList = async () => {
    try {
      const result = await commonAPICall("/vn/vn_list_country", "post", {});
      setCountryData(result.data);
      setFilter(result.data);
    } catch (err) {
      console.error(err);
    }
  };
  const handleSubmit = async () => {
    try {
      const result = await commonAPICall(
        "/vn/vn_masters_country_update_bulk",
        "post",
        { bulkList: countryData }
      );
      if (result.valid) {
        countryDataList();
        toast.success("Country fee  updated successfully");
      }
    } catch (err) {
      console.error(err);
    }
  };
  const countryColumns = [
    {
      id: "Country Name ",
      name: "Country Name ",
      selector: (row) => row.country_name,
      sortable: true,
      width: "200px",
    },
    {
      id: "Country ID  ",
      name: " Country ID  ",
      omit: false,
      selector: (row) => row.country_id,
      sortable: true,
      width: "120px",
    },
    {
      id: "Country Code ",
      name: " Country Code   ",
      omit: false,
      selector: (row) => row.country_code,
      sortable: true,
      width: "130px",
    },
    {
      id: "Visa fee Business  ",
      name: " Visa fee Business ",
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman"
            name="visa_b_fees"
            type="number"
            value={row.visa_b_fees}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: "Visa fee Tourist",
      name: "Visa fee Tourist",
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="visa_t_fees"
            value={row.visa_t_fees}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },

    {
      id: "Visa fee Tourist Urgent",
      name: "Visa fee Tourist Urgent",
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="visa_tu_fees"
            value={row.visa_tu_fees}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },

    {
      id: "Visa fee Business urgent",
      name: "Visa fee Business urgent",
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="visa_bu_fees"
            value={row.visa_bu_fees}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },

    // {
    //   id: 'Save',
    //   name: 'Save',
    //   omit: false,
    //   selector: (row) => {
    //     return (
    //       // <Button variant="info" onClick={() => handleShow(row)}>
    //       <Button variant="info" onClick={handleSubmit}>
    //         Save
    //       </Button>
    //     );
    //   },
    //   sortable: true,
    // },
  ];
  useEffect(() => {
    const result = countryData.filter((tabledatas) => {
      return tabledatas.country_name
        .toLowerCase()
        .match(search.toLocaleLowerCase());
    });

    setFilter(result);
  }, [search]);
  useEffect(() => {
    countryDataList();
  }, []);

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      console.log(parsedData, "paresdData");
      setCountryData(parsedData);
      setFilter(parsedData);
    };
  };
  const actionsMemo = React.useMemo(
    () => (
      <div className="d-flex align-items-center justify-content-end gap-2">
        <ExcelExport excelData={countryData} fileName={"vietnam"} />
        <input
          type="file"
          id="import"
          accept=".xlsx, .xls"
          onChange={handleFileUpload}
          helptext="excel"
          className="w-50"
        />
        <Button variant="secondary" size="sm" onClick={handleSubmit}>
          Save
        </Button>
      </div>
    ),
    [countryData]
  );
  return (
    <div className="settings_wrapper">
      <div>
        {" "}
        <h5 className="text-center "> Vietnam Country fee Update</h5>
      </div>
      <div>
        <DataTable
          columns={countryColumns}
          // data={countryData}
          pagination
          title="Country List with Price"
          data={filter}
          highlightOnHover
          fixedHeaderScrollHeight="400px"
          subHeader
          actions={actionsMemo}
          subHeaderComponent={
            <input
              type="text"
              placeholder="Search Country"
              className="w-25 form-control"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          }
        />
      </div>
    </div>
  );
}

export default VietnamFee;
