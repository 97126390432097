import axios from "axios";
import { getAuthUser, showErrorToast } from "../components/common";
const __DEV__ = window.location.hostname === "localhost";
export const commonAPICall = async (url, type, body) => {
  const headers = {
    Authorization: getAuthUser() ?"Bearer "+getAuthUser().token : "",
  };
  try {
    const apiResponse = await axios({
      method: type,
      headers: headers,
        url:(__DEV__
          ?process.env.REACT_APP_LOCAL_DOMAIN
          :process.env.REACT_APP_PROD_DOMAIN)
           + url,
      data: body,
    });

    if (apiResponse.status === 401) {
    }
    if (apiResponse.status === 200 || apiResponse.status === 500) {
      return apiResponse.data;
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
   
    showErrorToast(err);
  }
};
