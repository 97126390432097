import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import {
  BrijDateRange,
  showErrorToast,
  showSucessToast,
} from "../../components/common";
import { commonAPICall } from "../../services/services";

const user_id = JSON.parse(localStorage.getItem("auth"))?.user_id;

function IndiaPopup({ showtype, handleshowType, data }) {
  const [show, setShow] = useState(false);
  const [remarks, setRemarks] = useState([]);
  const [formdata, setFormdata] = useState({
    tran_id: 0,
    application_id: data,
    remarks: "",
    user_id: user_id,
  });

  const handleSubmitremarks = async () => {
    try {
      const obj = { ...formdata };
      obj.application_id = data;
      const result = await commonAPICall(
        "/ind/Application_Remarks_Insert_india",
        "post",
        obj
      );
      if (result.status == 200) {
        previewremarks();
        showSucessToast("Remarks Add successfully");
        setFormdata({...formdata, remarks: ""})
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const handleClose = () => {
    setShow(false);
    handleshowType(false);
  };

  const previewremarks = async () => {
    try {
      const result = await commonAPICall(
        "/ind/Application_remarks_preview_india",
        "post",
        { application_id: data }
      );
      if (result.valid) {
        setRemarks(result.data);
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const handlechanger = (event) => {
    setFormdata({ ...formdata, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    setShow(showtype);
    previewremarks();
  }, [showtype]);

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Remark * </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="remarks_list_box">
            {remarks.length > 0
              ? remarks.map((val, ind) => {
                  return (
                    <div className="remarks_items">
                      <div className="cont">
                        <h3> {val.remarks_by}</h3>
                        <p>{val.remarks} </p>
                      </div>
                      <div className="datetime">
                        <span>
                          {moment(
                            val.datetime
                              ? val.datetime.replace("Z", "")
                              : null
                          ).format("DD/MM/YYYY")}{" "}
                        </span>
                        <span>
                          {moment(
                            val.datetime
                              ? val.datetime.replace("Z", "")
                              : null
                          ).format("hh:mm:ss A")}
                        </span>
                      </div>
                    </div>
                  );
                })
              : " "}
          </div>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <h5>Add Remark * </h5>
              <Form.Control
                as="textarea"
                name="remarks"
                onChange={handlechanger}
                value={formdata.remarks}
                style={{ height: "100px" }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button size="sm" variant="success" onClick={handleSubmitremarks}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default IndiaPopup;
