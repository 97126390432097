import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { commonAPICall } from "../services/services";
import { FaTrash } from "react-icons/fa";

function SettingAngkor() {
  const [formData, setFormData] = useState({
    status_name: "",
    tran_id: 0,
    user_id: 2,
  });

  //Input Onchange START =========================================================
  const handleInputChangeSales = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleInputChangeAppication = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  //Input Onchange END =========================================================

  ///SALES SUBMIT START==============================================================================
  const [salesData, setSalesData] = useState([]);

  const applicationSalesStatusTableDataHandler = async () => {
    try {
      const result = await commonAPICall(
        "/ank/AG_browse_application_sales_status",
        "get",
        ""
      );
      setSalesData(result.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmitApplicationSalesStatus = async () => {
    try {
      const result = await commonAPICall(
        "/ank/AG_insert_application_sales_status",
        "post",
        formData
      );
      applicationSalesStatusTableDataHandler();
    } catch (err) {
      console.error(err);
    }
    toast.success(formData.status_name + " added successfully");
  };

  ///SALES SUBMIT END==============================================================================

  ///APPLICATION SUBMIT START==============================================================================
  const [applicationData, setApplicationData] = useState([]);

  const applicationTableDataHandler = async () => {
    try {
      const result = await commonAPICall(
        "/ank/AG_browse_application_status",
        "get",
        ""
      );
      setApplicationData(result.data);
    } catch (err) {
      console.error(err);
    }
  };
  const handleSubmitApplicationStatus = async () => {
    try {
      const result = await commonAPICall(
        "/ank/AG_insert_application_status",
        "post",
        formData
      );
      applicationTableDataHandler();
    } catch (err) {
      console.error(err);
    }
    toast.success(formData.status_name + " added successfully");
  };
  ///APPLICATION SUBMIT END==============================================================================

  useEffect(() => {
    applicationTableDataHandler();
    applicationSalesStatusTableDataHandler();
  }, []);

  //Sales and Application Columns START==========================================================
  const salesColumns = [
    {
      id: "status name",
      name: "Status Name",
      selector: (row) => row.status_name,
      sortable: true,
    },
    {
      id: "Delete status",
      name: " Delete status ",
      omit: false,
      selector: (row) => {
        return (
          <div>
            <FaTrash
              color="#b23029"
              className="cursor-pointer"
              onClick={() => deleteSales(row.tran_id)}
            />
          </div>
        );
      },
      sortable: true,
    },
  ];
  const applicationColumns = [
    {
      id: "status name",
      name: "Status Name",
      selector: (row) => row.status_name,
      sortable: true,
    },
    {
      id: "Delete status",
      name: " Delete status ",
      omit: false,
      selector: (row) => {
        return (
          <div>
            <FaTrash
              color="#b23029"
              className="cursor-pointer"
              onClick={() => deleteApplication(row.tran_id)}
            />
          </div>
        );
      },
      sortable: true,
    },
  ];
  //Sales and Application Columns END==========================================================
  const deleteSales = async (tran_id) => {
    try {
      const result = await commonAPICall(
        `/ank/AG_delete_application_sales_status/${tran_id}`,
        "delete",
        ""
      );
      if (result.status === 200) {
        applicationSalesStatusTableDataHandler();
        toast.error("Removed");
      }
    } catch (err) {
      console.error(err);
    }
  };
  const deleteApplication = async (tran_id) => {
    try {
      const result = await commonAPICall(
        `/ank/AG_delete_application_status/${tran_id}`,
        "delete",
        ""
      );
      if (result.status === 200) {
        applicationTableDataHandler();
        toast.error("Removed");
      }
    } catch (err) {
      console.error(err);
    }
  };
  //Delete Sales and Application END==========================================================
  return (
    <div className="settings_wrapper">
      <div>
        {" "}
        <h5 className="text-center "> Angkor</h5>
      </div>
      <Container fluid>
        <Row>
          <Col md={8} className="m-auto">
            <Tabs defaultActiveKey="Sale Status" id="uncontrolled-tab-example">
              <Tab eventKey="Sale Status" title="Sale Status">
                <div>
                  <Form.Label>Sale Status</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Sale Status"
                    className="mb-3"
                    name="status_name"
                    value={formData.name}
                    onChange={handleInputChangeSales}
                  />
                  <Button
                    variant="primary"
                    type="Update"
                    className="mb-3 bg-primary border-0 rounded-2 text-white"
                    onClick={handleSubmitApplicationSalesStatus}
                  >
                    Update
                  </Button>
                </div>
                <DataTable columns={salesColumns} data={salesData} />
              </Tab>
              <Tab eventKey="Application Status" title="Application Status">
                <div>
                  <Form.Label>Application Status</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Application Status"
                    className="mb-3"
                    name="status_name"
                    value={formData.name}
                    onChange={handleInputChangeAppication}
                  />
                  <Button
                    variant="primary"
                    type="Update"
                    className="mb-3 bg-primary  border-0 rounded-2 text-white"
                    onClick={handleSubmitApplicationStatus}
                  >
                    Update
                  </Button>
                </div>
                <DataTable
                  columns={applicationColumns}
                  data={applicationData}
                />
              </Tab>
              <Tab eventKey="Add New" title="Add New" disabled>
                <h1>hello home</h1>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SettingAngkor;
