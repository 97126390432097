import React, { useState } from "react";
import MasterUser from "./MasterUser";
import Addusers from "./Addusers";

export const IndexUserRight = () => {
	const [selectIndex, setselectIndex] = useState(0);
	const [edit, setedit] = useState({ valid: false });
	const handleData = (data) => {
		const val = { ...data };
		val.valid = true;
		setedit(val);
		setselectIndex(1);
	};
	const handleIndex = (e) => {
		setselectIndex(e);
		setedit({ valid: false });
	};
	return (
		<div className="rights_wrapper">
			{selectIndex === 0 ? (
				<MasterUser handleSelect={handleIndex} editData={handleData} />
			) : (
				<Addusers editData={edit} handleIndex={setselectIndex} />
			)}
		</div>
	);
};
