import moment from "moment";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { IoClipboard } from "react-icons/io5";
import { Form, Button, Col, Dropdown } from "react-bootstrap";
import { json, useLocation } from "react-router-dom";
import BrijCustomDataTable from "../../components/brijDataTable";
import {
  BrijDateRange,
  showErrorToast,
  showSucessToast,
} from "../../components/common";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaCog, FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { commonAPICall } from "../../services/services";
import { mainUrl } from "../../config";
import TajRemark from "./TajPopup";
const user_id = JSON.parse(localStorage.getItem("auth"))?.user_id;
const PaidApplicationBrowse = () => {
  const pageTitle = useLocation().pathname.split("/")[2];
  const [paymentData, setpaymentData] = useState([]);
  const [applicationStatus, setApplicationStatus] = useState([]);
  const [applicationSaleStatus, setApplicationSaleStatus] = useState([]);
  const [show, setShow] = useState(false);
  const [application, setapplication] = useState();
  const [paidData, setPaidData] = useState({
    amount: null,
    id: null,
  });
  const [file, setFile] = useState({
    image_pdf_path: "",
  });
  const fileHandle = async (e, tran_id) => {
    setFile({
      ...file,
      [e.target.name]: URL.createObjectURL(e.target.files[0]),
    });
    let value = e.target.files[0];
    let formData = new FormData();
    formData.append("file_path", e.target.files[0]);
    if (e.target.files[0]) {
      await axios({
        method: "post",
        url: "https://api.etouristservices.in/file/image",
        data: {
          file_path: e.target.files[0],
          tran_id: tran_id,
          user_id: user_id,
        },
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((result) => {
          if (result.status === 200) {
            showSucessToast("File uploaded successfully");
            setbodyQuery({ ...bodyQuery, update: bodyQuery.update + 1 });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const handleSubmit = async () => {
    try {
      const result = await commonAPICall("/taj/extra_payment_update", "post", {
        temporary_id: 0,
        amount: paidData.amount,
      });
      setShow(false);
    } catch (err) {
      showErrorToast(err);
    }
  };
  const PageData = async () => {
    try {
      const result = await commonAPICall("/taj/main_browse_paid", "post", {});
    } catch (err) {
      console, log(err);
    }
  };
  PageData();
  const handleShow = (id, amount) => {
    setPaidData({ ...paidData, id: id, amount: amount });
    setShow(true);
  };
  const [showType, setshowType] = useState(false);
  const [bodyQuery, setbodyQuery] = useState({
    application_id: "",
    order_id: "",
    nationality: "",
    email: "",
    visitor_id: "",
    sales_manager_status: "",
    sales_status: "",
    deo_manager_status: "",
    deo_status: "",
    payment_amount: 0,
    filter_column: "",
    search: "",
    pageSize: 15,
    pageNo: 1,
    update: "",
  });
  const [apibody, setapibody] = useState({
    from_date: null,
    to_date: null,
    payment_from_date: null,
    payment_to_date: null,
    agent_id: null,
  });
  const openRemarks = (e, tran_id) => {
    setapplication(tran_id);
    setshowType(!showType);
  };
  const handleButtonClick = (e, temporary_id, tran_id) => {
    const url = `${mainUrl}/preview/tajmahal/${temporary_id}/${tran_id}`;
    const windowOptions = "width=600,height=400,scrollbars=yes";
    window.open(url, "_blank", windowOptions);
  };

  const paymentApiCall = async () => {
    try {
      const result = await commonAPICall("/taj/list_payment_amount", "post", {
        from_date: apibody.from_date,
        to_date: apibody.to_date,
      });
      setpaymentData(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const updateApplicationStatus = async () => {
    try {
      const result = await commonAPICall(
        "/taj/browse_application_status",
        "post",
        { user_id: "" }
      );

      setApplicationStatus(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const updateApplicationSaleStatus = async () => {
    try {
      const result = await commonAPICall(
        "/taj/browse_application_sales_status",
        "post",
        { user_id: "" }
      );

      setApplicationSaleStatus(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };
  useEffect(() => {
    updateApplicationStatus();
    updateApplicationSaleStatus();
  }, []);

  const updateSalesStatus = async (id, element) => {
    try {
      const body = { tran_id: id, status: element.target.value };
      const result = await commonAPICall(
        "/taj/update_sales_status",
        "post",
        body
      );
      if (result.status === 200) {
        showSucessToast("Sales Status Update");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  useEffect(() => {
    paymentApiCall();
  }, [apibody.from_date, apibody.to_date]);

  const handleChange = (e) => {
    setbodyQuery({ ...bodyQuery, [e.target.name]: e.target.value });
  };
  const handleSearch = (e) => {};
  const dateFilter = (date, type) => {
    const startDate = moment(date.startDate).format("MM/DD/YYYY");
    const endDate = moment(date.endDate).format("MM/DD/YYYY");
    if (type[2] === "application") {
      setapibody({ ...apibody, from_date: startDate, to_date: endDate });
    }
    if (type[2] === "payment") {
      setapibody({
        ...apibody,
        payment_from_date: startDate,
        payment_to_date: endDate,
      });
    }
  };
  const handleShowType = (type) => {
    setshowType(type);
  };

  const handleSend = (email, ticket_path) => {
    axios
      .post("https://api.etouristservices.in/file/sendMail", {
        email: email,
        ticket_path: ticket_path,
      })
      .then((result) => {
        if (result.status === 200) {
          showSucessToast("ticket send successfully");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = [
    {
      id: "payment_record",
      name: "Payment Record",
      selector: (row) => row.payment_record,
      sortable: true,
      omit: false,
      minWidth: "150px",
    },
    {
      id: "application_date",
      name: "Application Date",
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(row.payment_date).format("DD/MM/YYYY")}
            <span> {moment(row.payment_date).format("hh:mm:ss A")}</span>
          </div>
        );
      },
      omit: false,
      sortable: true,
      minWidth: "180px",
    },
    {
      id: "application_id",
      name: "Application Id",
      selector: (row) => row.temporary_id,
      sortable: true,
      omit: false,
      minWidth: "150px",
    },
    {
      id: "mobile_no",
      name: "Mobile No.",
      selector: (row) => row.mobile_no,
      sortable: true,
      omit: false,
      minWidth: "105px",
    },
    {
      id: "Applicant Email",
      name: "Applicant email",
      selector: (row) => row.email,
      sortable: true,
      omit: false,
      minWidth: "300px",
      cell: (row) => <div style={{ whiteSpace: "normal" }}>{row.email}</div>,
    },
    {
      id: "Sales Manager Status",
      name: "Sales Manager Status",
      selector: (row) => row.sales_manager_status,
      sortable: true,
      omit: false,
      minWidth: "170px",
    },
    {
      id: "sales_status",
      name: "Sales Status",
      minWidth: "175px",
      omit: false,
      sortable: true,
      selector: (row) => {
        return (
          <select
            className="grid_select_input"
            name="sale Status"
            onChange={handleChange}
            size="md"
          >
            <option value={""}>sale Status </option>
            {applicationSaleStatus.length > 0
              ? applicationSaleStatus.map((value) => {
                  return <option>{value.status_name}</option>;
                })
              : ""}
          </select>
        );
      },
    },
    {
      id: "DEO Manager Status",
      name: "DEO Manager Status",
      selector: (row) => row.deo_manager_status,
      sortable: true,
      omit: false,
      minWidth: "160px",
    },
    {
      id: "Application Status",
      name: "Application Status",
      omit: false,
      sortable: true,
      minWidth: "175px",
      selector: (row) => {
        return (
          <select
            className="grid_select_input"
            name="Application Status"
            onChange={handleChange}
            size="md"
          >
            <option value={""}>Application Status </option>
            {applicationStatus.length > 0
              ? applicationStatus.map((value) => {
                  return <option>{value.status_name}</option>;
                })
              : ""}
          </select>
        );
      },
    },
    {
      id: "sales_user_name",
      name: "Sales",
      // selector: (row) => row.sales_user_name,
      sortable: true,
      omit: false,
      minWidth: "80px",
      selector: (row) => {
        return (
          <div className="table__dttime text-start">
            {row.sales_user_name}
            {row.sales_assign_datetime && (
              <span className="bg-primary">
                {" "}
                {moment(row.sales_assign_datetime).format("DD/MM/YYYY")}
              </span>
            )}
          </div>
        );
      },
    },
    {
      id: "DEO",
      name: "DEO",
      sortable: true,
      omit: false,
      minWidth: "80px",
      selector: (row) => {
        return (
          <div className="table__dttime text-start">
            {row.doe_user_name}
            {row.doe_assign_datetime && (
              <span className="bg-warning">
                {" "}
                {moment(row.doe_assign_datetime).format("DD/MM/YYYY")}
              </span>
            )}
          </div>
        );
      },
    },
    {
      id: "Payment",
      name: "Payment",
      selector: (row) => (
        <div style={{ whiteSpace: "normal" }}>{row.payment_status}</div>
      ),
      sortable: true,
      omit: false,
      minWidth: "90px",
    },
    {
      id: "move_to_paid",
      name: "Move to Unpaid",
      omit: false,
      selector: (row) => {
        return (
          <div className="customBtn_p">
            <Button
              variant="danger"
              onClick={() => handleShow(row.temporary_id, row.pay_amount)}
            >
              {" "}
              move to unpaid{" "}
            </Button>
          </div>
        );
      },
      sortable: true,
      width: "auto",
    },
    {
      id: "action",
      name: "Action",
      omit: false,
      selector: (row) => {
        return (
          <>
            <Dropdown className="action_dropdown">
              <Dropdown.Toggle variant="success" size="sm">
                <FaCog />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={(e) => openRemarks(e, row.tran_id)}>
                  Remarks
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={(e) =>
                    handleButtonClick(e, row.temporary_id, row.tran_id)
                  }
                >
                  View
                </Dropdown.Item>
                <Dropdown.Item onClick={() => {}}>Delete</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
      sortable: true,
      width: "70px",
    },

    // {
    //   id: "Ticket Id",
    //   name: "Ticket Id",

    //   selector: (row) => {
    //     return (
    //       <div>
    //         <span> {row.temporary_id} </span>
    //       </div>
    //     );
    //   },

    //   sortable: true,
    //   omit: false,
    //   minWidth: "200px",
    // },

    // {
    //   id: " Nationality  ",
    //   name: " Nationality  ",
    //   selector: (row) => row.nationality,
    //   sortable: true,
    //   omit: false,
    //   minWidth: "150px",
    // },
    // {
    //   id: "Applicant Email   ",
    //   name: " Applicant email   ",

    //   selector: (row) => {
    //     return (
    //       <div>
    //         <p>
    //           {" "}
    //           <span> {row.email} </span>
    //         </p>
    //       </div>
    //     );
    //   },
    //   sortable: true,
    //   omit: false,
    //   minWidth: "250px",
    // },

    // {
    //   id: "ID Number",
    //   name: "ID Number",
    //   selector: (row) => row.id_no,
    //   sortable: true,
    //   omit: false,
    //   minWidth: "200px",
    // },

    // {
    //   id: "ID Type",
    //   name: "ID Type",
    //   selector: (row) => row.id_type,
    //   sortable: true,
    //   omit: false,
    //   minWidth: "180px",
    // },

    // {
    //   id: "datetime",
    //   name: "Payment Date",
    //   selector: (row) => {
    //     return moment(row.datetime).format("DD/MM/YYYY");
    //   },
    //   omit: false,
    //   sortable: true,
    //   minWidth: "180px",
    // },
    // {
    //   id: "pay_amount",
    //   name: "Pay Amount",
    //   selector: (row) => row.pay_amount + " " + row.currency,
    //   sortable: true,
    //   omit: false,
    //   minWidth: "180px",
    // },
    // {
    //   id: "payment",
    //   name: "Payment",
    //   selector: (row) => row.payment,
    //   sortable: true,
    //   omit: false,
    //   minWidth: "180px",
    // },
    // {
    //   id: "payment Status",
    //   name: "Payment Status",
    //   selector: (row) => row.payment_status,
    //   sortable: true,
    //   omit: false,
    //   minWidth: "200px",
    // },
    // {
    //   id: "Visit Date",
    //   name: "Visit Date",
    //   selector: (row) => {
    //     return moment(row.visit_date).format("DD/MM/YYYY");
    //   },
    //   sortable: true,
    //   omit: false,
    //   minWidth: "150px",
    // },
    // {
    //   id: "Visit Time",
    //   name: "Visit Time",
    //   selector: (row) => row.visit_time,
    //   sortable: true,
    //   omit: false,
    //   minWidth: "150px",
    // },
    // {
    //   id: "No Of Child",
    //   name: "No of Child",
    //   selector: (row) => row.no_of_child,
    //   sortable: true,
    //   omit: false,
    //   minWidth: "150px",
    // },
    // {
    //   id: "No Of Adult",
    //   name: "No Of Adult",
    //   selector: (row) => row.no_of_adult,
    //   sortable: true,
    //   omit: false,
    //   minWidth: "180px",
    // },
    // {
    //   id: "sales_status",
    //   name: "Sales Status",
    //   selector: (row) => row.sales_status,
    //   sortable: true,
    //   omit: false,
    //   minWidth: "200px",
    // },

    // {
    //   id: " DEO status",
    //   name: "DEO Status",
    //   selector: (row) => row.deo_status,
    //   sortable: true,
    //   omit: false,
    //   minWidth: "200px",
    // },

    // {
    //   id: "sales_status",
    //   name: "Sales Status",
    //   minWidth: "200px",
    //   omit: false,

    //   sortable: true,
    // },

    // {
    //   id: "Application Status",
    //   name: "Application Status",
    //   omit: false,

    //   sortable: true,
    //   width: "200px",
    // },

    // {
    //   id: "move_to_paid",
    //   name: "Move to Unpaid",
    //   omit: false,
    //   selector: (row) => {
    //     return (
    //       <div>
    //         <Button
    //           variant="danger"
    //           onClick={() => handleShow(row.temporary_id, row.pay_amount)}
    //         >
    //           {" "}
    //           move to unpaid{" "}
    //         </Button>
    //       </div>
    //     );
    //   },
    //   sortable: true,
    //   width: "auto",
    // },

    // {
    //   id: " upload Ticket ",
    //   name: " upload Ticket ",
    //   omit: false,
    //   selector: (row) => {
    //     return (
    //       <div>
    //         <input type="file" onChange={(e) => fileHandle(e, row.tran_id)} />
    //       </div>
    //     );
    //     // toast.success("added successfully");
    //   },
    //   sortable: true,
    //   minWidth: "200px",
    // },
    // {
    //   id: "tickets status",
    //   name: "tickets status",
    //   selector: (row) =>
    //     row.ticket_path ? "ticket Uploaded" : "Not available",
    //   sortable: true,
    //   omit: false,
    //   minWidth: "200px",
    // },

    // {
    //   id: " send Ticket ",
    //   name: " send Ticket ",
    //   omit: false,
    //   selector: (row) => {
    //     return (
    //       <div>
    //         <Button
    //           variant="warning"
    //           onClick={() =>
    //             window.open(
    //               `https://api.etouristservices.in/uploads/${row.ticket_path}`,
    //               "",
    //               "width=600,height=300"
    //             )
    //           }
    //         >
    //           Preview
    //         </Button>

    //         <Button
    //           variant="info"
    //           onClick={() => handleSend(row.email, row.ticket_path)}
    //         >
    //           send
    //         </Button>
    //       </div>
    //     );
    //   },
    //   sortable: true,
    //   width: "auto",
    // },
  ];


  return (
    <div className="browse-list">
      <div className="application_form_wrapper">
        <div className="container-fluid">
          <div className="row">
            <Col md={2} className="mb-2">
              <Form.Control
                value={bodyQuery.temporary_id}
                onChange={handleChange}
                type="text"
                name="temporary_id"
                placeholder="Application Id"
                size="md"
              />
            </Col>
            <Col md={2} className="mb-2">
              <Form.Control
                value={bodyQuery.passport_no}
                onChange={handleChange}
                type="text"
                name="passport_no"
                placeholder="Passport No."
              />
            </Col>
            <Col md={4} className="mb-2">
              <Form.Control
                value={bodyQuery.email}
                onChange={handleChange}
                type="text"
                name="email"
                placeholder="Email"
                size="md"
              />
            </Col>
            <Col md={2} className="mb-2">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Application"
                pickClass={"right"}
              />
            </Col>
            <Col md={2} className="mb-2">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Payment"
                pickClass={"right"}
              />
            </Col>
            <Col md={2} className="mb-2">
              <Form.Select
                onChange={handleChange}
                name="sales_manager_status"
                size="md"
              >
                <option value={""}>All Sales Manager Status</option>
                <option>Not Assigned</option>
                <option>Assigned</option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-2">
              <Form.Select name="all_sales_status" onChange={handleChange}>
                <option>All Sales Status</option>
                <option>Fresh Application</option>
                <option>Will Pay Soon</option>
                <option>No Call Response</option>
                <option>Voice Message</option>
                <option>Paid</option>
                <option>Close</option>
                <option>Others</option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-2">
              <Form.Select
                name="all_deo_manager_status"
                onChange={handleChange}
              >
                <option>All DEO Manager Status</option>
                <option>Not Assigned</option>
                <option>Assigned</option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-2">
              <Form.Select name="application_status" onChange={handleChange}>
                <option>Application Status</option>
                <option>Fresh Application</option>
                <option>Complete Application</option>
                <option>Pending</option>
                <option>Wants to Cancle</option>
                <option>Refund</option>
                <option>Hold</option>
                <option>Late Arrival</option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-2">
              <Form.Select name="all_payment_amount" onChange={handleChange}>
                <option>All Payment Amount</option>
                {/* {allPaymentAmountList.map((value) => (
									<option value={value.full_name}>{value.full_name}</option>
								))} */}
              </Form.Select>
            </Col>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        {applicationSaleStatus.length > 0 && applicationStatus.length > 0 && (
          <BrijCustomDataTable
            columns={columns}
            url="/taj/main_browse_paid"
            apiBody={apibody}
            paramsBody={bodyQuery}
          />
        )}
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title className="fs-5">
              Application Id : <span>{paidData.id}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center gap-3">
              <h5 className="flex-1">Amount:</h5>
              <Form.Control
                type="number"
                value={paidData.amount}
                onChange={(e) =>
                  setPaidData({ ...paidData, amount: e.target.value })
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="danger" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button size="sm" variant="success" onClick={handleSubmit}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <TajRemark
        showtype={showType}
        handleshowType={handleShowType}
        data={application}
      />
    </div>
  );
};

export default PaidApplicationBrowse;
