import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { commonAPICall } from "../services/services";
import Modal from "react-bootstrap/Modal";
function SettingCombodia() {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (data) => {
    setShow(true);

    setupdateFee(data);
  };
  const [updateFee, setupdateFee] = useState({
    country_id: "",
    visa_b_fees: "",
    visa_t_fees: "",
    visa_bu_fees: "",
    visa_tu_fees: "",
  });
  const [formData, setFormData] = useState({
    status_name: "",
    tran_id: 0,
    user_id: 2,
  });
  //  country data  start
  const [updatefee, setupdatefee] = useState({});
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const handleUpdateFee = (e, event) => {
    const arrData = [...countryData];
    const index = countryData.indexOf(e);
    arrData[index][event.target.name] = event.target.value;
    setCountryData(arrData);
    setupdateFee(arrData[index]);
    // setupdateFee({...updateFee,[e.target.name]:e.target.value});
  };
  //Input Onchange START =========================================================
  const handleInputChangeSales = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleInputChangeAppication = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  //Input Onchange END =========================================================
  ///SALES SUBMIT START==============================================================================
  const applicationSalesStatusTableDataHandler = async () => {
    try {
      const result = await commonAPICall(
        "/cm/CM_browse_application_sales_status",
        "get",
        ""
      );
      setSalesData(result.data);
    } catch (err) {
      console.error(err);
    }
  };
  const countryDataList = async () => {
    try {
      const result = await commonAPICall("/cm/CM_list_country", "post", {});
      setCountryData(result.data);
      setFilter(result.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    countryDataList();
  }, []);

  // update country fee

  const handleSubmit = async () => {
    try {
      const result = await commonAPICall(
        "/cm/CM_update_country",
        "post",
        updateFee
      );
      if (result.valid) {
        countryDataList();
      }
      if (result.status === 200) {
        toast.success("Country fee  updated successfully");
      }
    } catch (err) {
      console.error(err);
    }
  };

  // update country fee

  //  country data end

  const handleSubmitApplicationSalesStatus = async () => {
    try {
      const result = await commonAPICall(
        "/cm/CM_insert_application_sales_status",
        "post",
        formData
      );
      applicationSalesStatusTableDataHandler();
    } catch (err) {
      console.error(err);
    }
    toast.success(formData.status_name + " added successfully");
  };

  ///SALES SUBMIT END==============================================================================

  ///APPLICATION SUBMIT START==============================================================================
  const [applicationData, setApplicationData] = useState([]);
  const applicationTableDataHandler = async () => {
    try {
      const result = await commonAPICall(
        "/cm/CM_browse_application_status",
        "get",
        ""
      );
      setApplicationData(result.data);
    } catch (err) {
      console.error(err);
    }
  };
  const handleSubmitApplicationStatus = async () => {
    try {
      const result = await commonAPICall(
        "/cm/CM_insert_application_status",
        "post",
        formData
      );
      applicationTableDataHandler();
    } catch (err) {
      console.error(err);
    }
    toast.success(formData.status_name + " added successfully");
  };
  ///APPLICATION SUBMIT END==============================================================================

  useEffect(() => {
    applicationTableDataHandler();
    applicationSalesStatusTableDataHandler();
  }, []);

  //Sales and Application Columns START==========================================================
  const salesColumns = [
    {
      id: "status name",
      name: "status name",
      selector: (row) => row.status_name,
      sortable: true,
    },
    {
      id: "Delete status",
      name: " Delete status ",
      omit: false,
      selector: (row) => {
        return (
          <div>
            <Button variant="danger" onClick={() => deleteSales(row.tran_id)}>
              Delete
            </Button>
          </div>
        );
      },
      sortable: true,
    },
  ];

  const applicationColumns = [
    {
      id: "status name",
      name: "status name",
      selector: (row) => row.status_name,
      sortable: true,
    },
    {
      id: "Delete status",
      name: " Delete status ",
      omit: false,
      selector: (row) => {
        return (
          <div>
            <Button
              variant="danger"
              onClick={() => deleteApplication(row.tran_id)}
            >
              Delete
            </Button>
          </div>
        );
      },
      sortable: true,
    },
  ];
  //  ------------------ country data---- table

  const countryColumns = [
    {
      id: "Country Name ",
      name: "country name ",
      selector: (row) => row.country_name,
      sortable: true,
      width: "200px",
    },
    {
      id: "Country ID  ",
      name: " Country ID  ",
      omit: false,
      selector: (row) => row.country_id,
      sortable: true,
      width: "120px",
    },
    {
      id: "Country Code ",
      name: " Country Code   ",
      omit: false,
      selector: (row) => row.country_code,
      sortable: true,
      width: "130px",
    },
    {
      id: "Visa fee Business  ",
      name: " Visa fee Business ",
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman"
            name="visa_b_fees"
            type="number"
            value={row.visa_b_fees}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: "Visa fee Tourist",
      name: "Visa fee Tourist",
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="visa_t_fees"
            value={row.visa_t_fees}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },

    {
      id: "Visa fee Tourist Urgent",
      name: "Visa fee Tourist Urgent",
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="visa_tu_fees"
            value={row.visa_tu_fees}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },

    {
      id: "Visa fee Business urgent",
      name: "Visa fee Business urgent",
      omit: false,
      selector: (row) => {
        return (
          <input
            className="hanuman2"
            type="number"
            name="visa_bu_fees"
            value={row.visa_bu_fees}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },

    {
      id: "Save",
      name: "Save",
      omit: false,
      selector: (row) => {
        return (
          // <Button variant="info" onClick={() => handleShow(row)}>
          <Button variant="info" onClick={handleSubmit}>
            Save
          </Button>
        );
      },
      sortable: true,
    },
  ];

  //Sales and Application Columns END==========================================================

  //Delete Sales and Application START==========================================================
  const deleteSales = async (tran_id) => {
    try {
      const result = await commonAPICall(
        `/cm/CM_delete_application_sales_status/${tran_id}`,
        "delete",
        ""
      );
      if (result.status === 200) {
        applicationSalesStatusTableDataHandler();
        toast.error("Removed");
      }
    } catch (err) {
      console.error(err);
    }
  };
  const deleteApplication = async (tran_id) => {
    try {
      const result = await commonAPICall(
        `/cm/CM_delete_application_status/${tran_id}`,
        "delete",
        ""
      );
      if (result.status === 200) {
        applicationTableDataHandler();
        toast.error("Removed");
      }
    } catch (err) {
      console.error(err);
    }
  };
  //Delete Sales and Application END==========================================================
  useEffect(() => {
    const result = countryData.filter((tabledatas) => {
      return tabledatas.country_name
        .toLowerCase()
        .match(search.toLocaleLowerCase());
    });

    setFilter(result);
  }, [search]);

  return (
    <div className="settings_wrapper">
      <div>
        {" "}
        <h5 className="text-center "> Combodia </h5>
      </div>
      <Container fluid>
        <Row>
          <Col md={8} className="m-auto">
            <Tabs defaultActiveKey="Sale Status" id="uncontrolled-tab-example">
              <Tab eventKey="Sale Status" title="Sale Status">
                <div>
                  <Form.Label>Sale Status</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Sale Status"
                    className="mb-3"
                    name="status_name"
                    value={formData.name}
                    onChange={handleInputChangeSales}
                    required
                  />
                  <Button
                    variant="primary"
                    type="Update"
                    className="mb-3 bg-primary border-0 rounded-2 text-white"
                    onClick={handleSubmitApplicationSalesStatus}
                  >
                    Update
                  </Button>
                </div>
                <DataTable columns={salesColumns} data={salesData} />
              </Tab>
              <Tab eventKey="Application Status" title="Application Status">
                <div>
                  <Form.Label>Application Status</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Application Status"
                    className="mb-3"
                    name="status_name"
                    value={formData.name}
                    onChange={handleInputChangeAppication}
                  />
                  <Button
                    variant="primary"
                    type="Update"
                    className="mb-3 bg-primary  border-0 rounded-2 text-white"
                    onClick={handleSubmitApplicationStatus}
                  >
                    Update
                  </Button>
                </div>
                <DataTable
                  columns={applicationColumns}
                  data={applicationData}
                />  
              </Tab>
              <Tab eventKey="Add New" title="Update Country price list">
                <div>
                  <DataTable
                    columns={countryColumns}
                    // data={countryData}
                    pagination
                    title="Country List with Price"
                    data={filter}
                    highlightOnHover
                    fixedHeaderScrollHeight="400px"
                    subHeader
                    subHeaderComponent={
                      <input
                        type="text"
                        placeholder="Search Country"
                        className="w-25 form-control"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    }
                  />
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SettingCombodia;
