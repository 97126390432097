import React, { useEffect, useState } from "react";
import { Container, Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import { commonAPICall } from "../services/services";
import { getAuthUser, showErrorToast } from "./common";
import { useDispatch } from "react-redux";
import { userRight } from "../redux/dataStore";
const Header = () => {
	const dispatch = useDispatch();
	const [rightData, setrightData] = useState([]);
	const [filter, setfilter] = useState({});
	const [mainFilter, setmainFilter] = useState([]);
	const[dropdown, setDropdown] = useState(false);
	const location = window.location.pathname
		.split("/")
		.filter((val) => val !== "");
	const userRightFun = async () => {
		try {
			await commonAPICall("/auth/user_right_get", "get", {}).then((result) => {
				if (result.valid) {
					setrightData(result.main);
					setfilter(result.filter);
				}
			});
		} catch (err) {
			showErrorToast(err);
		}
	};
// console.log(location)
	useEffect(() => {
		userRightFun();
	}, []);
	const logOut = () => {
		localStorage.removeItem("auth");
		window.location.reload();
	};
	const handleSelect = (e) => {
		if (filter[e]) {
			setmainFilter(filter[e]);
			dispatch(userRight({ value: filter[e] }));
		}
	};
	useEffect(() => {
	  if(location.length===2){
	    handleSelect(location[1].replace("-", " ").toUpperCase());
	    // dispatch(userRight({ value:filter[location[1].toUpperCase()] }));
	  }
	}, [rightData])
	return (
		<div className="brij-navbar ">
			<Navbar>
				<Container fluid className="p-0">
					<Navbar.Brand href="/">
						<img src="/assets/images/etouristlogo.png" className="logo-main" />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto">
							{mainFilter[0] ? <Link to={"dashboard"}>Dashboard</Link> : ""}
							{/* <Dropdown>
								<Dropdown.Toggle variant="none" className="text-white" id="application">
									Application
								</Dropdown.Toggle>

								<Dropdown.Menu>
									{rightData.length > 0
										? rightData.map((val, ind) => {
												return (
													<Dropdown.Item
														key={ind}
														onClick={(e) => handleSelect(val.country)}
														to={val.country.replace(" ", "-").toLowerCase()}
													>
														{val.country} APPLICATION
													</Dropdown.Item>
												);
										  })
										: ""}								
								</Dropdown.Menu>
							</Dropdown> */}
							<NavDropdown title="Application" show={dropdown} onMouseEnter={() => setDropdown(!dropdown)} onMouseLeave={() => setDropdown(false)}>
								{rightData.length > 0
									? rightData.map((val, ind) => {
											return (
												<NavDropdown.Item key={ind}>
													<Link
														to={val.country.replace(" ", "-").toLowerCase()}
														onClick={(e) => handleSelect(val.country)}
													>
														{val.country} APPLICATION
													</Link>
												</NavDropdown.Item>
											);
									  })
									: ""}
							</NavDropdown>
							{mainFilter[1]?.valid ? (
								<NavDropdown title="Status Update">
									<NavDropdown.Item>
										<Link to={"SettingTaj"}>TajMahal Status</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to={"SettingCombodia"}>Combodia Status</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to={"SettingAngkor"}>Angkor Status</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to={"SettingIndia"}>India Status</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to={"SettingSrilanka"}>Sri lanka Status</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to={"SettingTurkey"}>Turkey Status</Link>
									</NavDropdown.Item>
								</NavDropdown>
							) : (
								""
							)}

							{mainFilter[2]?.valid ? (
								<NavDropdown title="Fee Update">
									{/* <NavDropdown.Item>
										<Link to={"TajMahalFee"}>TajMahal Fee</Link>
									</NavDropdown.Item> */}

									<NavDropdown.Item>
										<Link to={"CombodiaFee"}>Combodia Fee</Link>
									</NavDropdown.Item>

									<NavDropdown.Item>
										<Link to={"AngkorFee"}>Angkor Fee</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to={"IndiaFee"}>India Fee</Link>
									</NavDropdown.Item>
									{/* <NavDropdown.Item>
										<Link to={"SrilankaFee"}>Srilanka Fee</Link>
									</NavDropdown.Item> */}
									{/* <NavDropdown.Item>
										<Link to={"TurkeyFee"}>Turkey Fee</Link>
									</NavDropdown.Item> */}
									<NavDropdown.Item>
										<Link to={"vietnam_fee"}>Vietnam Fee</Link>
									</NavDropdown.Item>
								</NavDropdown>
							) : (
								""
							)}

							{mainFilter[3]?.valid ? (
								<NavDropdown title="Mail Department">
									<NavDropdown.Item>
										<Link to={"Tajmail"}>TajMahal Mail</Link>
									</NavDropdown.Item>

									<NavDropdown.Item>
										<Link to={"MailCombodia"}>Combodia Mail</Link>
									</NavDropdown.Item>

									<NavDropdown.Item>
										<Link to={"Angkormail"}>Angkor Mail</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to={"Indiamail"}>India Fee</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to={"Srilankamail"}>Srilanka Mail</Link>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<Link to={"Turkeymail"}>Turkey Mail</Link>
									</NavDropdown.Item>
								</NavDropdown>
							) : (
								""
							)}

							{mainFilter[4]?.valid ? (
								<NavDropdown title=" Setting">
									<NavDropdown.Item>
										<Link to={"MasterUser"}>Master user Rights</Link>
									</NavDropdown.Item>
								</NavDropdown>
							) : (
								""
							)}
						</Nav>
					</Navbar.Collapse>
					<Nav className="ms-auto profile">
						<NavDropdown
							className="w-100"
							title={
								<div>
									<img src="/assets/images/user.png" className="profile_pin" />
									{getAuthUser()?.user_name}
								</div>
							}
							id="basic-nav-dropdown"
						>
							<NavDropdown.Item onClick={logOut}>Log out</NavDropdown.Item>
						</NavDropdown>
					</Nav>
				</Container>
			</Navbar>
		</div>
	);
};

export default Header;
