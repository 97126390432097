import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";

const Dashboard = () => {
  return (
    <div className="dashboard_wrapper">
      <Container>
        <h2 className="text-center">Welcome to dashboard</h2>
        <div className="dashboard_infobox">
          <Row>
            <Col md={10} className="m-auto">
              <Row>
                <Col>
                  <div className="box border">
                    <i className="fa-solid fa-tablet-screen-button"></i>
                    <h5>412</h5>
                    <p>Applications visit in next 15</p>
                    <Link to="" className="btn text-white">View Details</Link>
                  </div>
                </Col>
                <Col>
                  <div className="box border">
                    <i className="fa-solid fa-clock-rotate-left"></i>
                    <h5>116</h5>
                    <p>Pending Applications</p>
                    <Link to="" className="btn text-white">View Details</Link>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
      <Outlet />
    </div>
  );
};
export default Dashboard;
