import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { commonAPICall } from "../services/services";
function AngkorFee() {
  const [updateFee, setupdateFee] = useState({
    tran_id: null,
    price: "",
  });
  //  country data  start
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const handleUpdateFee = (e, event) => {
    const arrData = [...countryData];
    const index = countryData.indexOf(e);
    arrData[index][event.target.name] = event.target.value;
    setCountryData(arrData);
    setupdateFee(arrData[index]);
  };
  const countryDataList = async () => {
    try {
      const result = await commonAPICall("/ank/list_temple", "get", {});
      setCountryData(result.data);
      setFilter(result.data);
    } catch (err) {
      console.error(err);
    }
  };
  const handleSubmit = async () => {
    try {
      const result = await commonAPICall(
        "/ank/update_price",
        "post",
        updateFee
      );
      if (result.valid) {
        countryDataList();
      }
      if (result.status === 200) {
        toast.success("Country fee  updated successfully");
      }
    } catch (err) {
      console.error(err);
    }
  };
  const countryColumns = [
    {
      id: "type",
      name: "Temple Name",
      selector: (row) => row.type,
      sortable: true,
      width: "200",
    },
    {
      id: "currency",
      name: "Currency",
      omit: false,
      selector: (row) => row.currency,
      sortable: true,
      width: "50",
    },
    {
      id: "note1",
      name: "Day",
      omit: false,
      selector: (row) => row.note1,
      sortable: true,
      width: "100",
    },
    {
      id: "price",
      name: "Price",
      omit: false,
      selector: (row) => {
        return (
          <input
            name="price"
            type="number"
            value={row.price}
            onChange={(e) => handleUpdateFee(row, e)}
          />
        );
      },
      sortable: true,
    },
    {
      id: "Save",
      name: "Save",
      omit: false,
      selector: (row) => {
        return (
          // <Button variant="info" onClick={() => handleShow(row)}>
          <Button variant="info" onClick={handleSubmit}>
            Save
          </Button>
        );
      },
      sortable: true,
    },
  ];
  useEffect(() => {
    const result = countryData.filter((tabledatas) => {
      return tabledatas.type.toLowerCase().match(search.toLocaleLowerCase());
    });

    setFilter(result);
  }, [search]);
  useEffect(() => {
    countryDataList();
  }, []);

  return (
    <div className="settings_wrapper">
      <div>
        {" "}
        <h5 className="text-center "> Angkor fee Update</h5>
      </div>
      <div>
        <DataTable
          columns={countryColumns}
          // data={countryData}
          pagination
          title="Temple List with Price "
          data={filter}
          highlightOnHover
          fixedHeaderScrollHeight="400px"
          subHeader
          subHeaderComponent={
            <input
              type="text"
              placeholder="Search Country"
              className="w-25 form-control"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          }
        />
      </div>
    </div>
  );
}

export default AngkorFee;
