import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    value: [],
}
export const apiData = createSlice({
    name: "userRightData",
    initialState,
    reducers: {
        userRight: (state, action) => action.payload
    },
})
export const { userRight } = apiData.actions
export default apiData.reducer