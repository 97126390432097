import React, { useState, useEffect } from "react";
import moment from "moment";
import { commonAPICall } from "../../services/services";
import { Col, Container, Row, Table } from "react-bootstrap";
import FileSaver, { saveAs } from "file-saver";
export default function preview() {
  const [applicationViewData, setApplicationViewData] = useState([]);
  const [visitors, setVisitors] = useState([]);
  useEffect(() => {
    const previewData = async () => {
      const id = window.location.pathname.split("/");
      try {
        const result = await commonAPICall(
          "/ank/AG_Application_full_preview",
          "post",
          {
            temporary_id: id[3],
          }
        );
        setApplicationViewData(result.data);
      } catch (err) {
        console.error(err);
      }
    };
    previewData();
  }, []);
  useEffect(() => {
    const allVisitorsData = async () => {
      try {
        const id = window.location.pathname.split("/");
        const result = await commonAPICall(
          "/ank/ag_admin_ticket_preview_visitors",
          "post",
          {
            tran_id: id[4],
          }
        );
        setVisitors(result.data);
      } catch (err) {
        console.error(err);
      }
    };
    allVisitorsData();
  }, []);

  const downloadURI = (e, fileUrl, download) => {
    e.preventDefault(); // Prevent browser's default download stuff...
    FileSaver.saveAs(fileUrl, download);

    // saveAs(data , "image.png");
  };
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="preview_table_wrapper">
            <Row>
              <Col>
                <div className="title_box">
                  <h3>Your eTV Application Details</h3>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table bordered>
                  {applicationViewData.map((data) => (
                    <>
                      <tr>
                        <td className="bg-warning">
                          <div className="viewdata border-0">
                            Application id no. : -
                          </div>
                        </td>
                        <td className="bg-warning">
                          <div className="viewdata border-0">
                            {data.temporary_id}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0"> First Name : - </div>
                        </td>
                        <td>
                          <div className="viewdata border-0"> {data.first_name}</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">Last Name : - </div>
                        </td>
                        <td>
                          <div className="viewdata border-0"> {data.last_name}</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">Mobile No : - </div>
                        </td>
                        <td>
                          {" "}
                          <div className="viewdata border-0">
                            {data.country_code + data.mobile}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">Email : - </div>
                        </td>
                        <td>
                          <div className="viewdata border-0"> {data.email}</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">Ticket : - </div>
                        </td>
                        <td>
                          <div className="viewdata border-0"> {data.ticket}</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0"> Amount : - </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {data.amount + data.currency}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0"> Date of Entry : - </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {moment(data.date_of_entry).format("DD/MM/YYYY")}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0"> Date of expiry: - </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {moment(data.date_of_expiry).format("DD/MM/YYYY")}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">Total visitors : - </div>
                        </td>
                        <td>
                          <div className="viewdata border-0"> {data.total_visitors}</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0"> IP Address : - </div>
                        </td>
                        <td>
                          <div className="viewdata border-0"> {data.ip_address}</div>
                        </td>
                      </tr>
                    </>
                  ))}
                </Table>
              </Col>
            </Row>
          </div>

          <div className="title_box">
            <h4> Vigitors</h4>
          </div>
          <div>
            <Table striped bordered className="bg-white">
              <thead>
                <tr>
                  <th>Gender</th>
                  <th>Nationality</th>
                  <th>Group Age</th>
                  <th>Image</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {visitors.map((item) => (
                  <tr key={item.id}>
                    <td>{item.gender}</td>
                    <td>{item.nationality}</td>
                    <td>{item.group_age}</td>
                    <td>{item.image_path}</td>
                    <td>
                      <img
                        width={100}
                        height={150}
                        src={`https://api.angkorenterprise.org/images/${item.image_path}`}
                      ></img>
                      <a
                        className="dbtnp"
                        href={`https://api.angkorenterprise.org/images/${item.image_path}`}
                        onClick={(e) =>
                          downloadURI(
                            e,
                            `https://api.angkorenterprise.org/images/${item.image_path}`,
                            item.image_path
                          )
                        }
                        download={item.image_path}
                      >
                        Download
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
