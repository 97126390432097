import moment from "moment";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button, Row, Col, Table, Dropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import BrijCustomDataTable from "../../components/brijDataTable";
import {
  BrijDateRange,
  showErrorToast,
  showSucessToast,
} from "../../components/common";
import Modal from "react-bootstrap/Modal";
import { previewLiveUrl } from "../../config";
import { FaCog } from "react-icons/fa";
import { commonAPICall } from "../../services/services";
import IndiaPopup from "./IndiaPopup";
import MailSendPopup from "./MailSendPopup";
import { toast } from "react-toastify";
const PaidApplicationBrowse = () => {
  const pageTitle = useLocation().pathname.split("/")[2];
  // const [applicationStatus, setApplicationStatus] = useState([]);

  const [application, setapplication] = useState();
  const [etadata, setEtadata] = useState([]);
  const [show, setShow] = useState(false);
  const [showUpdateId, setShowUpdateId] = useState(false);
  const [paidData, setPaidData] = useState({
    amount: null,
    id: null,
  });
  const [tempid, setTempid] = useState({
    temporary_id: " ",
    tran_id: "",
  });
  const [formdata, setFormdata] = useState({
    temporary_id: tempid.temporary_id,
    govt_permanent_id: "",
    govt_temporary_id: null,
    fees: "",
  });
  const [visaPaymentDetails, setVisaPaymentDetails] = useState({
    temporary_id: "",
    pay_amount: null,
    payment_date: "",
    payment: "",
  });
  // const [salesStatus, setsalesStatus] = useState([]);
  const [selectVisaStatus, setselectVisaStatus] = useState({
    tran_id: 0,
  });
  const [selectStatusApplication, setselectStatusApplication] = useState({
    tran_id: 0,
  });
  const [deoExecutiveList, setDeoExecutiveList] = useState([]);
  const [salesExecutiveList, setSalesExecutiveList] = useState([]);
  const [allPaymentAmountList, setAllPaymentAmountList] = useState([]);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [selectSalesStatus, setselectSalesStatus] = useState({
    tran_id: 0,
  });
  const [showType, setshowType] = useState(false);
  const [showAdditionalFee, setshowAdditionalFee] = useState(false);
  const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);
  const [deleteConfirmData, setdeleteConfirmData] = useState({});
  const [subBodyQuery, setsubBodyQuery] = useState({
    application_id: "",
    passport_no: "",
    email: "",
  });
  const [bodyQuery, setbodyQuery] = useState({
    application_id: "",
    passport_no: "",
    email: "",
    from_date: "",
    to_date: "",
    payment_from_date: "",
    payment_to_date: "",
    travel_from_date: "",
    travel_to_date: "",
    application_type: "",
    agent_id: "",
    website_url: "",
    visa_service: "",
    visa_tourist_days: "",
    sales_manager_status: "",
    sales_status: "",
    deo_manager_status: "",
    deo_status: "",
    visa_status: "",
    form_process: "",
    payment_amount: 0, //
    filter_column: "",
    search: "",
    pageSize: 15,
    pageNo: 1,
    reload: 0,
  });
  const [apibody, setapibody] = useState({
    from_date: null,
    to_date: null,
    payment_from_date: null,
    payment_to_date: null,
    travel_from_date: null,
    travel_to_date: null,
    agent_id: null,
  });

  const etaDetails = async (id) => {
    try {
      const result = await commonAPICall(
        "/ind/application_govt_detail_list_india",
        "post",
        {
          temporary_id: id,
        }
      );
      setEtadata(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const [notification, setNotification] = useState([]);

  const listNotification = async () => {
    try {
      const result = await commonAPICall("/ind/list_notifications", "post", "");
      if (result.valid) {
        setNotification(result.data);
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const handleUpdateGovernmentId = async () => {
    try {
      const result = await commonAPICall(
        "/ind/Update_govt_id_india",
        "post",
        formdata
      );
      if (result.status == 200) {
        etaDetails(formdata.temporary_id);
        setbodyQuery({ ...bodyQuery, reload: Math.random() * 2 });
        showSucessToast("successfully");
        setFormdata({
          ...formdata,
          govt_permanent_id: "",
          fees: "",
          govt_permanent_id: "",
        });
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const handlechanger = (event) => {
    setFormdata({ ...formdata, [event.target.name]: event.target.value });
  };
  const handleChange = (e) => {
    setbodyQuery({ ...bodyQuery, [e.target.name]: e.target.value });
  };
  const handleChangeBody = (e) => {
    setapibody({ ...apibody, [e.target.name]: e.target.value });
  };
  const handlechangeSub = (e) => {
    const obj = { application_id: "", passport_no: "", email: "" };
    obj[e.target.name] = e.target.value;
    setsubBodyQuery(obj);
    if (e.key === "Enter") {
      setbodyQuery({
        ...bodyQuery,
        application_id: obj.application_id,
        passport_no: obj.passport_no,
        email: obj.email,
      });
      setsubBodyQuery({ application_id: "", passport_no: "", email: "" });
    }
  };

  const handleSubmit = async () => {
    try {
      const result = await commonAPICall("/ind/extra_payment_update", "post", {
        temporary_id: 0,
        amount: paidData.amount,
      });

      // if(result.)
      setShow(false);
    } catch (err) {
      showErrorToast(err);
    }
  };
  const handleShow = (id, amount) => {
    setPaidData({ ...paidData, id: id, amount: amount });
    setShow(true);
  };

  const governmentUpdate = (temporary_id, tran_id) => {
    setTempid({ ...tempid, temporary_id: temporary_id, tran_id: tran_id });
    setFormdata({ ...formdata, temporary_id: temporary_id });
    setShowUpdateId(true);
    etaDetails(temporary_id);
  };

  const openRemarks = (tran_id) => {
    setapplication(tran_id);
    setshowType(!showType);
  };

  // const handleShowAddictionalFee = (id, amount) => {
  //   setshowAdditionalFee(true);
  // };

  let previewWindow = null;

  const handlePreviewAction = (temporary_id, tran_id) => {
    const url = `${previewLiveUrl}/preview/India/${temporary_id}/${tran_id}`;
    const windowOptions = "width=600,height=600,scrollbars=yes";
    if (!previewWindow || previewWindow.closed) {
      previewWindow = window.open(url, "_blank", windowOptions);
    } else {
      // If the window is minimized, bring it to the front
      if (previewWindow.outerWidth === 0) {
        previewWindow.focus();
      }
      // Refresh the content
      previewWindow.location.href = url;
    }
  };
  const updateApplicationStatus = async (element) => {
    try {
      const body = {
        tran_id: selectStatusApplication.tran_id,
        status: element.target.value,
      };
      setselectStatusApplication({
        ...selectStatusApplication,
        deo_status: element.target.value,
      });
      const result = await commonAPICall(
        "/ind/update_application_status",
        "post",
        body
      );
      if (result.status === 200) {
        showSucessToast("Application Status Update");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const updateAllDeoExecutiveList = async () => {
    try {
      const result = await commonAPICall("/ind/list_deo_users", "get", "");

      setDeoExecutiveList(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };
  const updateAllSalesExecutiveList = async () => {
    try {
      const result = await commonAPICall("/ind/list_sales_users", "get", "");

      setSalesExecutiveList(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };
  const paymentApiCall = async () => {
    try {
      const result = await commonAPICall("/ind/list_payment_amount", "post", {
        from_date: apibody.from_date,
        to_date: apibody.to_date,
      });
      setAllPaymentAmountList(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };
  // const selectBrowseApplicationStatus = async () => {
  //   try {
  //     const result = await commonAPICall(
  //       "/ind/browse_application_status",
  //       "get",
  //       ""
  //     );
  //     setApplicationStatus(result.data);
  //   } catch (err) {
  //     showErrorToast(err);
  //   }
  // };
  // const selectBrowseSalesStatus = async () => {
  //   try {
  //     const result = await commonAPICall(
  //       "/ind/browse_application_sales_status",
  //       "get",
  //       ""
  //     );
  //     setsalesStatus(result.data);
  //   } catch (err) {
  //     showErrorToast(err);
  //   }
  // };

  const updateVisaStatus = async (e) => {
    try {
      const result = await commonAPICall("/ind/update_visa_status", "post", {
        tran_id: selectVisaStatus.tran_id,
        status: e.target.value,
      });
      if (result.status === 200) {
        setbodyQuery({ ...bodyQuery, reload: Math.random() * 3 });
        showSucessToast("Visa Status Updated");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };
  const updateDeoManagerStatus = async (e) => {
    try {
      const result = await commonAPICall("/ind/update_deo_status", "post", {
        tran_id: selectStatusApplication?.tran_id,
        status: e.target.value,
      });
      if (result.status === 200) {
        setbodyQuery({ ...bodyQuery, reload: Math.random() * 3 });
        showSucessToast("Application Status Updated");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  useEffect(() => {
    // selectBrowseApplicationStatus();
    updateAllSalesExecutiveList();
    updateAllDeoExecutiveList();
    updateApplicationStatus();
    paymentApiCall();
    listNotification();
    // selectBrowseSalesStatus();
  }, []);

  const updateSalesStatus = async (e) => {
    try {
      const result = await commonAPICall("/ind/update_sales_status", "post", {
        tran_id: selectSalesStatus.tran_id,
        status: e.target.value,
      });
      if (result.status === 200) {
        setbodyQuery({ ...bodyQuery, reload: Math.random() * 3 });
        showSucessToast("Sales Status Updated");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  useEffect(() => {
    paymentApiCall();
  }, [apibody.from_date, apibody.to_date]);

  const dateFilter = (date, type) => {
    console.log("TYPE", type);
    const startDate = moment(date.startDate).format("MM/DD/YYYY");
    const endDate = moment(date.endDate).format("MM/DD/YYYY");
    if (type[2] === "application") {
      setapibody({ ...apibody, from_date: startDate, to_date: endDate });
    }
    if (type[2] === "payment") {
      setapibody({
        ...apibody,
        payment_from_date: startDate,
        payment_to_date: endDate,
      });
    }
    if (type[2] === "travel") {
      setapibody({
        ...apibody,
        travel_from_date: startDate,
        travel_to_date: endDate,
      });
    }
  };
  const handleShowType = (type) => {
    setshowType(type);
  };

  const handleSend = (email, ticket_path) => {
    axios
      .post(`${process.env.REACT_APP_LOCAL_DOMAIN}/file/sendMail`, {
        email: email,
        ticket_path: ticket_path,
      })
      .then((result) => {
        if (result.status === 200) {
          showSucessToast("ticket send successfully");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [showMailSend, setshowMailSend] = useState(false);
  const [sendMailData, setsendMailData] = useState({});

  const handleSubmitApplicationFee = async () => {
    try {
      const result = await commonAPICall(
        "/ind/move_to_unpaid_payment_insert",
        "post",
        paidData
      );
      if (result.valid) {
        setbodyQuery({ ...bodyQuery, reload: Math.random() * 3 });
        setshowAdditionalFee(false);
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const columns = [
    {
      id: "payment_datetime",
      name: "Payment Record",
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(
              row.payment_date ? row.payment_date.replace("Z", "") : null
            ).format("DD/MM/YYYY")}
            <span className="bg-warning">
              {" "}
              {moment(
                row.payment_date ? row.payment_date.replace("Z", "") : null
              ).format("hh:mm A")}
            </span>
          </div>
        );
      },
      sortable: true,
      omit: false,
      minWidth: "110px",
    },
    {
      id: "application_date",
      name: "Application Date",
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(row.datetime ? row.datetime.replace("Z", "") : null).format(
              "DD/MM/YYYY"
            )}
            <span>
              {" "}
              {moment(
                row.datetime ? row.datetime.replace("Z", "") : null
              ).format("hh:mm A")}
            </span>
          </div>
        );
      },

      sortable: true,
      omit: false,
      minWidth: "110px",
    },
    {
      id: "exp_date_of_arrival",
      name: "Travel Date",
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(row.exp_date_of_arrival).format("DD/MM/YYYY")}
          </div>
        );
      },
      sortable: true,
      omit: false,
      minWidth: "100px",
    },
    {
      id: "application_id",
      name: "Application ID",
      selector: (row) => row.temporary_id,
      sortable: true,
      omit: false,
      minWidth: "140px",
    },
    {
      id: "application_type",
      name: "Application Type",
      selector: (row) => row.application_type,
      sortable: true,
      omit: false,
      minWidth: "110px",
    },
    {
      id: "passport_no",
      name: "Passport No.",
      selector: (row) => row.passport_no,
      sortable: true,
      omit: false,
      minWidth: "110px",
    },
    {
      id: "applicant_name",
      name: "Application Name",
      selector: (row) => row.Name,
      sortable: true,
      omit: false,
      minWidth: "150px",
      cell: (row) => <div style={{ whiteSpace: "normal" }}>{row.Name}</div>,
    },

    {
      id: "visa_service",
      name: "Visa Service",
      selector: (row) => row.visa_service,
      sortable: true,
      omit: false,
      minWidth: "120px",
      cell: (row) => (
        <div style={{ whiteSpace: "normal" }}>{row.visa_service}</div>
      ),
    },
    {
      id: "Applicant Email",
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      omit: false,
      minWidth: "300px",
      cell: (row) => <div style={{ whiteSpace: "normal" }}>{row.email}</div>,
    },
    {
      id: "contact_no",
      name: "Contact no.",
      selector: (row) => row.contact_no,
      sortable: true,
      omit: false,
      minWidth: "105px",
    },
    {
      id: "visa_tourist_days",
      name: "Visa Days",
      selector: (row) => (
        <div style={{ whiteSpace: "normal" }}>{row.visa_tourist_days}</div>
      ),
      sortable: true,
      omit: false,
      minWidth: "162px",
    },
    {
      id: "visa_status",
      name: "Visa Status",
      omit: false,
      selector: (row) => {
        return (
          <div className="w-100">
            <div className="w-100">
              {selectVisaStatus?.tran_id !== row.tran_id ? (
                <p
                  className="grid_select_value"
                  onMouseOver={() => setselectVisaStatus(row)}
                >
                  {row.visa_status ? row.visa_status : "N/A"}
                </p>
              ) : (
                <select
                  name="sale Status"
                  className="grid_select_input"
                  value={selectVisaStatus?.visa_status}
                  onChange={updateVisaStatus}
                  onMouseOut={() =>
                    setselectVisaStatus({ ...selectVisaStatus, tran_id: 0 })
                  }
                >
                  <option value="">N/A</option>
                  <option value="Granted">Granted</option>
                  <option value="Rejected">Rejected</option>
                  <option value="Reupload">Reupload</option>
                  <option value="Under process">Under process</option>
                  <option value="Not processed">Not processed</option>
                </select>
              )}
            </div>
          </div>
        );
      },
      sortable: true,
      minWidth: "146px",
    },
    {
      id: "Sales Manager Status",
      name: "Sales Manager Status",
      selector: (row) => row.sales_manager_status,
      sortable: true,
      omit: false,
      minWidth: "170px",
    },

    // {
    // 	id: "Sales Status",
    // 	name: "Sales Status",
    // 	selector: (row) => row.sales_status,
    // 	sortable: true,
    // 	omit: false,
    // 	minWidth: "180px",
    // },
    {
      id: "DEO Manager Status",
      name: "DEO Manager Status",
      selector: (row) => row.deo_manager_status,
      sortable: true,
      omit: false,
      minWidth: "160px",
    },
    {
      id: "sales_status",
      name: "Sales Status",
      minWidth: "146px",
      omit: false,
      selector: (row) => {
        return (
          <div className="w-100">
            {selectSalesStatus?.tran_id !== row.tran_id ? (
              <p
                className="grid_select_value"
                onMouseOver={() => setselectSalesStatus(row)}
              >
                {row.sales_status ? row.sales_status : "N/A"}
              </p>
            ) : (
              <select
                name="sale Status"
                className="grid_select_input"
                value={selectSalesStatus?.sales_status}
                onChange={updateSalesStatus}
                onMouseOut={() =>
                  setselectSalesStatus({ ...selectSalesStatus, tran_id: 0 })
                }
              >
                <option value={""}>N/A</option>
                <option value={"Fresh Application"}>Fresh Application</option>
                <option value={"Will Pay Soon"}>Will Pay Soon</option>
                <option value={"No Call Response"}>No Call Response</option>
                <option value={"Voice Message"}>Voice Message</option>
                <option value={"Close"}>Close</option>
                <option value={"Others"}>Others</option>
              </select>
            )}
          </div>
        );
      },
      sortable: true,
    },
    {
      id: "Application Status",
      name: "Application Status",
      omit: false,
      selector: (row) => {
        return (
          <div className="w-100">
            {selectStatusApplication?.tran_id !== row.tran_id ? (
              <p
                className="grid_select_value"
                onMouseOver={() => setselectStatusApplication(row)}
              >
                {row.deo_status ? row.deo_status : "N/A"}
              </p>
            ) : (
              <select
                name="deo_status"
                className="grid_select_input"
                value={selectStatusApplication?.deo_status}
                onChange={updateDeoManagerStatus}
                onMouseOut={() =>
                  setselectStatusApplication({
                    ...selectStatusApplication,
                    tran_id: 0,
                  })
                }
              >
                <option value={""}>N/A</option>
                <option value={"Fresh Application"}>Fresh Application</option>
                <option value={"Complete Application"}>
                  Complete Application
                </option>
                <option value={"Pending"}>Pending</option>
                <option value={"Wants to Cancle"}>Wants to Cancle</option>
                <option value={"Refund"}>Refund</option>
                <option value={"Hold"}>Hold</option>
                <option value={"Late Arrival"}>Late Arrival</option>
              </select>
            )}
          </div>
        );
      },
      sortable: true,
      minWidth: "165px",
    },

    {
      id: "sales_user_name",
      name: "Sales",
      sortable: true,
      omit: false,
      minWidth: "100px",
      selector: (row) => {
        return (
          <div className="table__dttime text-start">
            {row.sales_user_name}
            {row.sales_assign_datetime && (
              <span className="bg-primary">
                {" "}
                {moment(row.sales_assign_datetime).format("DD/MM/YYYY")}
              </span>
            )}
          </div>
        );
      },
    },

    {
      id: "DEO",
      name: "DEO",
      sortable: true,
      omit: false,
      minWidth: "100px",
      selector: (row) => {
        return (
          <div
            className="table__dttime text-start"
            style={{ whiteSpace: "normal" }}
          >
            {row.doe_user_name}
            {row.doe_assign_datetime && (
              <span className="bg-warning">
                {" "}
                {moment(row.doe_assign_datetime).format("DD/MM/YYYY")}
              </span>
            )}
          </div>
        );
      },
    },
    {
      id: "Govt ID",
      name: "Govt ID",
      selector: (row) => row.govt_permanent_id,
      sortable: true,
      omit: false,
      minWidth: "110px",
    },
    {
      id: "Payment",
      name: "Payment Status",
      selector: (row) => row.payment_status,
      sortable: true,
      omit: false,
      minWidth: "130px",
    },
    {
      id: "mail_status",
      name: "Mail status",
      // selector: (row) => row.mail_send,
      selector: (row) => {
        return (
          <div className="tbl__mailsent">
            send<sup>{row.partial_mail_count}</sup>
          </div>
        );
      },
      sortable: true,
      omit: false,
      minWidth: "97px",
    },
    {
      id: "last_active",
      name: "Last Active",
      // selector: (row) => row.last_active,
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(row.last_active.replace("Z", "")).format("DD/MM/YYYY")}
            <span>
              {" "}
              {moment(row.last_active.replace("Z", "")).format("hh:mm A")}
            </span>
          </div>
        );
      },
      sortable: true,
      omit: false,
      minWidth: "98px",
    },
    {
      id: "Site",
      name: "Site",
      selector: (row) => row.website_url,
      sortable: true,
      omit: false,
      minWidth: "164px",
    },

    {
      id: "move_to_paid",
      name: "Move to Unpaid",
      omit: false,
      selector: (row) => {
        return (
          <div className="customBtn_p">
            <Button
              variant="danger"
              onClick={() => handleShow(row.temporary_id, row.pay_amount)}
            >
              {" "}
              move to unpaid{" "}
            </Button>
          </div>
        );
      },
      sortable: true,
      minWidth: "130px",
    },
    {
      id: "action",
      name: "Action",
      omit: false,
      selector: (row) => {
        return (
          <>
            <Dropdown className="action_dropdown">
              <Dropdown.Toggle variant="dark" size="sm">
                <FaCog />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => openRemarks(row.tran_id)}>
                  Remarks
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    handleMailSend(row);
                    // setshowMailSend(showMailSend);
                  }}
                >
                  M-Send
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) =>
                    handlePreviewAction(row.temporary_id, row.tran_id)
                  }
                >
                  View
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) =>
                    governmentUpdate(row.temporary_id, row.tran_id)
                  }
                >
                  Update ID
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) =>
                    paymentDetails(
                      row.temporary_id,
                      row.pay_amount,
                      row.payment_date
                    )
                  }
                >
                  Payment Detail
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleDelete(row)}>
                  Delete
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={() => {
                    handleShowAddictionalFee(row.temporary_id, row.pay_amount);
                  }}
                >
                  Additional Fee
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
      sortable: true,
      minWidth: "70px",
    },
  ];

  const handleMailSend = (row) => {
    setsendMailData(row);
    setshowMailSend(true);
  };

  const handleDelete = (row) => {
    setshowDeleteConfirm(true);
    setdeleteConfirmData(row);
  };

  const paymentDetails = (id, amount, date) => {
    setVisaPaymentDetails({
      temporary_id: id,
      pay_amount: amount,
      payment_date: date,
    });
    setShowPaymentDetails(true);
  };

  const deletApplicationHandler = async (id) => {
    try {
      const result = await commonAPICall(
        `/ind/application_delete/${id}`,
        "delete",
        ""
      );
      if (result.status === 200) {
        setbodyQuery({ ...bodyQuery, apibody: bodyQuery.apibody + 1 });
        setshowDeleteConfirm(false);
        toast.success("Removed successfully");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="browse-list">
      <div className="application_form_wrapper">
        <span className="notificationButton">
          Notifications {notification.length}
        </span>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 mb-1">
              <Form.Control
                value={subBodyQuery.application_id}
                onChange={handlechangeSub}
                onKeyUp={handlechangeSub}
                type="text"
                name="application_id"
                placeholder="Application Id"
                size="sm"
              />
            </div>
            <div className="col-md-2 mb-1">
              <Form.Control
                value={subBodyQuery.passport_no}
                onChange={handlechangeSub}
                onKeyUp={handlechangeSub}
                type="text"
                name="passport_no"
                placeholder="Passport No."
                size="sm"
              />
            </div>
            <div className="col-md-2 mb-1">
              <Form.Control
                value={subBodyQuery.email}
                onChange={handlechangeSub}
                onKeyUp={handlechangeSub}
                type="text"
                name="email"
                placeholder="Email"
                size="sm"
              />
            </div>
            <div className="col-md-2 mb-1">
              <Form.Select
                name="agent_id"
                onChange={handleChangeBody}
                size="sm"
              >
                <option value={""}>All Deo Executive</option>
                {deoExecutiveList.map((value) => (
                  <option key={value.user_id} value={value.user_id}>
                    {value.full_name}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-2 mb-1">
              <Form.Select
                name="agent_id"
                onChange={handleChangeBody}
                size="sm"
              >
                <option value={""}>All Sales Executive</option>
                {salesExecutiveList.map((value) => (
                  <option value={value.user_id} key={value.user_id}>
                    {value.full_name}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-2 mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Application"
                pickClass={"right"}
              />
            </div>
            <div className="col-md-2 mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Payment"
                pickClass={"left"}
              />
            </div>
            <div className="col-md-2 mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Travel"
                pickClass={"left"}
              />
            </div>
            <div className="col-md-2 mb-1">
              <Form.Select
                onChange={handleChange}
                name="application_type"
                size="sm"
              >
                <option value={""}>All Application Type</option>
                <option value={"Normal"}>Normal Application</option>
                <option value={"urgent"}>Urgent Application</option>
              </Form.Select>
            </div>
            <Col md={2} className="mb-1">
              <Form.Select size="sm" name="website_url" onChange={handleChange}>
                <option value={""}>All Sites Application</option>
                <option value="indiavisasonline.org.in">
                  indiavisasonline.org.in
                </option>
                <option value="indiansvisa.org.in">indiansvisa.org.in</option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-1">
              <Form.Select size="sm" name="visa_status" onChange={handleChange}>
                <option value={""}>All Visa Status</option>
                <option value="Granted">Granted</option>
                <option value="Rejected"> Rejected</option>
                <option value="Reupload">Reupload</option>
                <option value="Under process">Under process</option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                name="visa_service"
                onChange={handleChange}
              >
                <option value={""}>All Visa Services</option>
                <option value="Business">Business</option>
                <option value="Medial">Medial</option>
                <option value="Tourist">Tourist</option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                onChange={handleChange}
                name="sales_manager_status"
              >
                <option value={""}>All Sales Manager Status</option>
                <option value="Not Assgined">Not Assigned</option>
                <option value="Assgined">Assigned</option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                name="sales_status"
                onChange={handleChange}
              >
                <option value="">All Sales Status</option>
                <option value="Fresh Application">Fresh Application</option>
                <option value="Will Pay Soon">Will Pay Soon</option>
                <option value="No Call Response">No Call Response</option>
                <option value="Voice Message">Voice Message</option>
                <option value="Paid">Paid</option>
                <option value="Close">Close</option>
                <option value="Others">Others</option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                name="deo_manager_status"
                onChange={handleChange}
              >
                <option value={""}>All DEO Manager Status</option>
                <option value="Not Assgine">Not Assigned</option>
                <option value="Assgined">Assigned</option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-1">
              <Form.Select size="sm" name="deo_status" onChange={handleChange}>
                <option value={""}>Application Status</option>
                <option value={"Fresh Application"}>Fresh Application</option>
                <option value={"Complete Application"}>
                  Complete Application
                </option>
                <option value={"Pending"}>Pending</option>
                <option value={"Wants to Cancle"}>Wants to Cancle</option>
                <option value={"Refund"}>Refund</option>
                <option value={"Hold"}>Hold</option>
                <option value={"Late Arrival"}>Late Arrival</option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                name="all_payment_amount"
                onChange={handleChange}
              >
                <option value="0">All Payment Amount</option>
                {allPaymentAmountList.map((value) => (
                  <option value={value.full_name}>{value.full_name}</option>
                ))}
              </Form.Select>
            </Col>
            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                name="visa_tourist_days"
                onChange={handleChange}
              >
                <option value={""}>All Visa Days</option>
                <option value="eTourist Visa(for 30 Days)">
                  eTourist Visa(for 30 Days)
                </option>
                <option value="eTourist Visa(for 1 Year)">
                  eTourist Visa(for 1 Year)
                </option>
                <option value="eTourist Visa(for 5 Years)">
                  eTourist Visa(for 5 Years)
                </option>
              </Form.Select>
            </Col>
            {/* 
						<div className="col-md-2">
							<Form.Control
								value={bodyQuery.passport_no}
								onChange={handleChange}
								type="text"
								name="passport_no"
								placeholder="Passport No."
							/>
						</div>
						<div className="col">
							<Form.Control
								value={bodyQuery.nationality}
								onChange={handleChange}
								type="text"
								name="nationality"
								placeholder="Nationality"
								size="sm"
							/>
						</div>

						<div className="col-md-2 mb-1">
							<BrijDateRange
								onChange={dateFilter}
								dateLevel="Date Of Travel"
								pickClass={"left"}
							/>
						</div> */}

            {/* <div className="col-md-2 mb-1">
              <Form.Select
                name="sales_status"
                onChange={handleChange}
                size="sm"
              >
                <option value={""}>All Sales Status</option>
                {saleStatus.map((value) => (
                  <option value={value.status_name}>{value.status_name}</option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-2 mb-1">
              <Form.Select
                name="sales_manager_status"
                onChange={handleChange}
                size="sm"
              >
                <option value={""}>All Sales Manager Status</option>
                <option>Not Assigned</option>
                <option>Assigned</option>
              </Form.Select>
            </div> */}
            {/* <div className="col-md-2 mb-1">
							<Form.Select name="deo_status" onChange={handleChange}>
								<option value={""}> All Deo Status </option>
								{applicationStatus.map((value) => (
									<option value={value.status_name}>{value.status_name}</option>
								))}
							</Form.Select>
						</div>
						<div className="col-md-2 mb-1">
							<Form.Select
								name="deo_manager_status"
								onChange={handleChange}
								size="sm"
							>
								<option value={""}>All Deo Manager Status</option>
								<option>Not Assigned</option>
								<option>Assigned</option>
							</Form.Select>
						</div> */}
            {/* <div className="col-md-2 mb-1">
              <Form.Select
                name="payment_amount"
                onChange={handleChange}
                size="sm"
              >
                <option value={""}>All Payment Amount</option>
                {paymentData?.map((list) => (
                  <option>{list.pay_amount}</option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-2 mb-1">
              <Form.Select name="all_visa_days" onChange={handleChange}>
                <option value={""}>All Visa Days</option>
                <option>eTourist Visa(for 30 Days)</option>
                <option>eTourist Visa(for 1 Year)</option>
                <option>eTourist Visa(for 5 Years)</option>
              </Form.Select>
            </div> */}

            {/* <div className="col">
              <Form.Control
                size="sm"
                type="text"
                placeholder="Search"
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    handleSearch(e);
                  }
                }}
              />
            </div> */}
          </div>
        </div>
      </div>
      <div className="table-responsive formRow3">
        <BrijCustomDataTable
          columns={columns}
          url="/ind/main_browse_paid"
          apiBody={apibody}
          paramsBody={bodyQuery}
          assingUrl="/ind/Application_assign_insert"
          type={"paid"}
        />

        <Modal
          show={showDeleteConfirm}
          onHide={() => setshowDeleteConfirm(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title className="fs-5">
              Are you sure you want to delete?
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col xs={12} className="mb-1">
                <strong>Application Id:</strong>
                <br /> {deleteConfirmData?.temporary_id}
              </Col>
              <Col xs={6} className="mb-1">
                <strong>Name:</strong>
                <br />
                <span className="text-ellipses">{deleteConfirmData?.name}</span>
              </Col>
              <Col xs={6} className="mb-1">
                <strong>Email:</strong>
                <br />
                <span className="text-ellipses">
                  {deleteConfirmData?.email}
                </span>
              </Col>
              <Col xs={6} className="mb-1">
                <strong>Payment</strong>
                <br />
                <span className="text-ellipses">
                  {deleteConfirmData?.payment}
                </span>
              </Col>
              <Col xs={6} className="mb-1">
                <strong>Payment Status</strong>
                <br />
                <span className="text-ellipses">
                  {deleteConfirmData?.payment_status}
                </span>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="success"
              onClick={() =>
                deletApplicationHandler(deleteConfirmData?.tran_id)
              }
            >
              Confirm
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={() => setshowDeleteConfirm(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ======================================================= */}
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="fs-4">
                Application Id : <span>{paidData.id}</span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>
              Amount :
              <span>
                <input
                  type="number"
                  value={paidData.amount}
                  onChange={(e) =>
                    setPaidData({ ...paidData, amount: e.target.value })
                  }
                />
              </span>
            </h3>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" size="sm" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button variant="success" size="sm" onClick={handleSubmit}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        {/* govt id update */}
        {/* ================================================================== */}
        <Modal
          show={showUpdateId}
          onHide={() => setShowUpdateId(false)}
          dialogClassName="modal_body"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title className="fs-5">
              Update Government ID And Fees <br />
              Application ID : {tempid.temporary_id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <Table bordered style={{ verticalAlign: "middle" }}>
                  <tbody>
                    <tr>
                      <td>Government Temp Id</td>
                      <td>
                        {" "}
                        <Form.Control
                          type="text"
                          name="govt_temporary_id"
                          onChange={handlechanger}
                          value={formdata.govt_temporary_id}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Government Permanent Id</td>
                      <td>
                        <Form.Control
                          type="text"
                          name="govt_permanent_id"
                          onChange={handlechanger}
                          value={formdata.govt_permanent_id}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Government Fees</td>
                      <td>
                        <Form.Control
                          type="text"
                          name="fees"
                          onChange={handlechanger}
                          value={formdata.fees}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col md={12} className="mt-4 govttablegrid">
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>Government Id</th>
                      <th>Government Fees</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {etadata.length > 0 ? (
                      etadata.map((val, ind) => {
                        return (
                          <tr>
                            <td>{val.govt_permanent_id}</td>
                            <td>{val.fees}</td>
                            <td>
                              {" "}
                              <span>
                                {moment(val.payment_date).format("DD/MM/YYYY")}{" "}
                                ,{" "}
                                <sapn>
                                  {" "}
                                  {moment(val.payment_date).format("hh:mm A")}
                                </sapn>
                              </span>{" "}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={3} className="text-center mt-3">
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              size="sm"
              onClick={() => setShowUpdateId(false)}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              size="sm"
              onClick={handleUpdateGovernmentId}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <IndiaPopup
        showtype={showType}
        handleshowType={handleShowType}
        data={application}
      />
      <MailSendPopup
        data={sendMailData}
        handleMailShowType={setshowMailSend}
        showMailSend={showMailSend}
      />
      {/* =================================================================== */}
      <Modal
        show={showPaymentDetails}
        onHide={() => setShowPaymentDetails(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">
            Application ID: {visaPaymentDetails.temporary_id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="shadow-sm mb-3 rounded-3 border p-3 align-items-start">
            <Row>
              <Col md={12} className="fs-5 fw-bold mb-4">
                Visa Payment Details
              </Col>
              <Col>{visaPaymentDetails.pay_amount} USD</Col>
              <Col>
                {" "}
                {moment(visaPaymentDetails.payment_date).format(
                  "DD/MM/YYYY"
                )}{" "}
                {moment(visaPaymentDetails.payment_date).format("hh:mm A")}
              </Col>

              <Col>Application Fees</Col>
            </Row>
          </div>
          <div className="text-end">
            <Button
              variant="danger"
              size="sm"
              onClick={() => setShowPaymentDetails(false)}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* ============================================================ */}
      {/* Additional fee =========================== */}
      <Modal
        show={showAdditionalFee}
        onHide={() => setshowAdditionalFee(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Additional Fee : <span>{paidData.id}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center gap-3">
            <h5 className="flex-1">Amount:</h5>
            <Form.Control
              type="number"
              value={paidData.amount}
              onChange={(e) =>
                setPaidData({ ...paidData, amount: e.target.value })
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="danger"
            onClick={() => setshowAdditionalFee(false)}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            variant="success"
            onClick={handleSubmitApplicationFee}
          >
            Save & Send
          </Button>
          <Button
            size="sm"
            variant="success"
            onClick={handleSubmitApplicationFee}
          >
            Save & Preview
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Additional fee india=========================== */}
    </div>
  );
};

export default PaidApplicationBrowse;
