import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  Card,
  FloatingLabel,
  Form,
  Button,
  Col,
  Dropdown,
} from "react-bootstrap";
import { DateRange, DateRangePicker } from "react-date-range";
import { useLocation } from "react-router-dom";
import BrijCustomDataTable from "../../components/brijDataTable";
import { IoClipboard } from "react-icons/io5";
import { ExcelExport } from "../../components/excelExport";
import { BrijDateRange, showErrorToast } from "../../components/common";
import { selectionRange } from "../../components/constant";
import { commonAPICall } from "../../services/services";
import { mainUrl, previewLiveUrl } from "../../config";
import TajRemark from "./TajPopup";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaCog, FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";

const UnPaidApplicationBrowse = () => {
  const pageTitle = useLocation().pathname.split("/")[2];
  const [paymentData, setpaymentData] = useState([]);
  const [excelData, setexcelData] = useState([]);
  const [application, setapplication] = useState();
  const [dateFilterRange, setDateFilterRange] = useState({
    selection: selectionRange,
  });
  const [paidData, setPaidData] = useState({
    amount: null,
    id: null,
  });
  const handleSubmit = async () => {
    try {
      const result = await commonAPICall("/taj/extra_payment_update", "post", {
        temporary_id: 0,
        amount: paidData.amount,
      });
      setShow(false);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const [show, setShow] = useState(false);
  const [showType, setshowType] = useState(false);
  const [bodyQuery, setbodyQuery] = useState({
    application_id: "",
    from_date: "",
    to_date: "",
    payment_from_date: "",
    payment_to_date: "",
    order_id: "",
    nationality: "",
    email: "",
    visitor_id: "",
    agent_id: "",
    sales_manager_status: "",
    sales_status: "",
    deo_manager_status: "",
    deo_status: "",
    payment_amount: 0,
    filter_column: "", //
    search: "",
    pageSize: 15,
    pageNo: 1,
  });

  const [apibody, setapibody] = useState({
    from_date: null,
    to_date: null,
    payment_from_date: null,
    payment_to_date: null,
    agent_id: null,
  });
  const handleShow = (id, amount) => {
    setPaidData({ ...paidData, id: id, amount: amount });
    setShow(true);
  };

  const handeleExcelData = (data) => {
    setexcelData(data);
  };
  const handleAction = (e, id) => {
    if (e.target.value === "View") {
      window.open(
        `${previewLiveUrl}/preview/tajmahal/${id}`,
        "",
        "width=600,height=300"
      );
    }
    if (e.target.value === "Remarks") {
      setshowType(!showType);
    }
  };

  const openRemarks = (e, tran_id) => {
    setapplication(tran_id);
    setshowType(!showType);
  };
  const handleButtonClick = (e, temporary_id, tran_id) => {
    const url = `${previewLiveUrl}/preview/tajmahal/${temporary_id}/${tran_id}`;
    const windowOptions = "width=600,height=400,scrollbars=yes";
    window.open(url, "_blank", windowOptions);
  };
  const handleShowType = (type) => {
    setshowType(type);
  };
  const paymentApiCall = async () => {
    try {
      const result = await commonAPICall("/taj/list_payment_amount", "post", {
        from_date: apibody.from_date,
        to_date: apibody.to_date,
      });
      setpaymentData(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  useEffect(() => {
    paymentApiCall();
  }, [apibody.from_date, apibody.to_date]);

  const handleChange = (e) => {
    setbodyQuery({ ...bodyQuery, [e.target.name]: e.target.value });
  };
  const handleSearch = (e) => {
    // let currentQuery = { ...queryParam };
    // currentQuery.search = e.target.value;
    // setQueryParam({ ...queryParam, search: e.target.value });
    // getBrowseData(currentQuery);
  };
  const dateFilter = (date) => {
    setDateFilterRange(date);
  };

  function copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => toast.success("Copied to clipboard:", text))
      .catch((err) => console.error("Failed to copy to clipboard:", err));
  }

  const columns = [
    {
      id: "application_date",
      name: "Application Date",
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(row.datetime).format("DD/MM/YYYY")}
            <span> {moment(row.datetime).format("hh:mm:ss A")}</span>
          </div>
        );
      },

      sortable: true,
      omit: false,
      minWidth: "140px",
    },
    {
      id: "travel_date",
      name: "Travel Date",
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(row.datetime).format("DD/MM/YYYY")}
            <span> {moment(row.datetime).format("hh:mm:ss A")}</span>
          </div>
        );
      },
      sortable: true,
      omit: false,
      minWidth: "100px",
    },
    {
      id: "Application Id",
      name: "Application Id",
      selector: (row) => row.temporary_id,
      sortable: true,
      omit: false,
      minWidth: "145px",
    },
    {
      id: "mobile_no",
      name: "Mobile No.",
      selector: (row) => row.mobile_no,
      sortable: true,
      omit: false,
      minWidth: "112px",
    },
    {
      id: "applicant_email",
      name: "Applicant email",
      selector: (row) => row.email,
      sortable: true,
      omit: false,
      cell: (row) => <div style={{ whiteSpace: "normal" }}>{row.email}</div>,
      minWidth: "250px",
    },
    {
      id: "Sales Manager Status",
      name: "Sales Manager Status",
      selector: (row) => row.sales_manager_status,
      sortable: true,
      omit: false,
      minWidth: "170px",
    },
    {
      id: "Sales Status",
      name: "Sales Status",
      selector: (row) => row.sales_status,
      sortable: true,
      omit: false,
      minWidth: "180px",
      // selector: (row) => {
      //   return (
      //     <div className="w-100">
      //       {selectSalesStatus?.tran_id !== row.tran_id ? (
      //         <p
      //           className="grid_select_value"
      //           onMouseOver={() => setselectSalesStatus(row)}
      //         >
      //           {row.deo_status ? row.deo_status : "Update Status"}
      //         </p>
      //       ) : (
      //         <select
      //           name="sale Status"
      //           className="grid_select_input"
      //           value={selectSalesStatus?.deo_status}
      //           onChange={updateSalesStatus}
      //         >
      //           <option value={""}>N/A</option>
      //           {salesStatus.map((value) => (
      //             <option value={value.status_name}>{value.status_name}</option>
      //           ))}
      //         </select>
      //       )}
      //     </div>
      //   );
      // },
    },
    {
      id: "DEO Manager Status",
      name: "DEO Manager Status",
      selector: (row) => row.deo_manager_status,
      sortable: true,
      omit: false,
      minWidth: "160px",
    },
    {
      id: "application_status",
      name: "Application Status",
      omit: false,
      selector: (row) => row.application_status,
      // selector: (row) => {
      //   return (
      //     <div className="w-100">
      //       {selectStatusApplication?.tran_id !== row.tran_id ? (
      //         <p
      //           className="grid_select_value"
      //           onMouseOver={() => setselectStatusApplication(row)}
      //         >
      //           {row.deo_status ? row.deo_status : "Update Status"}
      //         </p>
      //       ) : (
      //         <select
      //           name="sale Status"
      //           className="grid_select_input"
      //           value={selectStatusApplication?.deo_status}
      //           onChange={updateApplicationStatus}
      //         >
      //           <option value={""}>Applicatin Status</option>
      //           {applicationStatus.map((value) => (
      //             <option key={value.status_name} value={value.status_name}>
      //               {value.status_name}
      //             </option>
      //           ))}
      //         </select>
      //       )}
      //     </div>
      //   );
      // },
      sortable: true,
      minWidth: "180px",
    },
    {
      id: "Sales Executive",
      name: "Sales Executive",
      selector: (row) => row.sales_user_name,
      sortable: true,
      omit: false,
      minWidth: "170px",
    },
    {
      id: "Payment Status",
      name: "Payment Status",
      selector: (row) => row.payment_status,
      sortable: true,
      omit: false,
      minWidth: "170px",
    },
    {
      id: "move_to_paid",
      name: "Move to Paid",
      omit: false,
      selector: (row) => {
        return (
          <div className="customBtn_p">
            <Button
              size="sm"
              variant="danger"
              onClick={() => handleShow(row.temporary_id, row.pay_amount)}
            >
              Move to Paid
            </Button>
          </div>
        );
      },
      sortable: true,
      width: "auto",
    },

    {
      id: "action",
      name: "Action",
      omit: false,
      selector: (row) => {
        return (
          <>
            <Dropdown className="action_dropdown">
              <Dropdown.Toggle variant="success" size="sm">
                <FaCog />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => openRemarks(row.tran_id)}>
                  Remarks
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={(e) =>
                    handleButtonClick(e, row.temporary_id, row.tran_id)
                  }
                >
                  View
                </Dropdown.Item>
                <Dropdown.Item onClick={() => {}}>Delete</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
      sortable: true,
      minWidth: "70px",
    },
  ];

  return (
    <div className="browse-list">
      <div className="application_form_wrapper">
        <div className="container-fluid">
          <div className="row">
            <Col md={4} className="mb-2">
              <Form.Control
                value={bodyQuery.email}
                onChange={handleChange}
                type="text"
                name="email"
                placeholder="Email"
                size="md"
              />
            </Col>
            <Col md={4} className="mb-2">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Application"
                pickClass={"left"}
              />
            </Col>
            <Col md={4} className="mb-2">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Travel"
                pickClass={"right"}
              />
            </Col>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <BrijCustomDataTable
          columns={columns}
          url="/taj/main_browse"
          apiBody={apibody}
          paramsBody={bodyQuery}
          excelData={handeleExcelData}
        />

        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title className="fs-5">
              {" "}
              Application Id : <span>{paidData.id}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center gap-3">
              <h5 className="flex-1">Amount:</h5>
              <Form.Control
                type="number"
                value={paidData.amount}
                onChange={(e) =>
                  setPaidData({ ...paidData, amount: e.target.value })
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="danger" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button size="sm" variant="success" onClick={handleSubmit}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <TajRemark
        showtype={showType}
        handleshowType={handleShowType}
        data={application}
      />
    </div>
  );
};

export default UnPaidApplicationBrowse;
