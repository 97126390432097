import React from "react";
import XLSX from "sheetjs-style";
import { saveAs } from "file-saver";
import { Button } from "react-bootstrap";

export const ExcelExport = ({ excelData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const excelExportButton = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const excelBuffer = XLSX.write(
      {
        Sheets: { data: ws },
        SheetNames: ["data"],
      },
      { bookType: "xlsx", type: "array" }
    );
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
  };
  return (
    <>
      <Button
        onClick={() => excelExportButton()}
        variant="success"
        size="sm"
        // className="btn btn-export btn-sm w-75"
      >
        Excel Export
      </Button>
    </>
  );
};
