import React, { useState, useEffect } from "react";
import moment from "moment";
import FileSaver from "file-saver";
import { commonAPICall } from "../../services/services";
import { Col, Container, Row, Table } from "react-bootstrap";
export default function PreviewCambodia() {
  const [applicationViewData, setApplicationViewData] = useState([]);
  const id = window.location.pathname.split("/");
  const previewData = async () => {
    try {
      const result = await commonAPICall(
        "/cm/CM_Application_full_preview",
        "post",
        {
          temporary_id: id[3],
        }
      );

      setApplicationViewData(result.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    previewData();
  }, []);
  const downloadURI = (e, fileUrl, download) => {
    e.preventDefault(); // Prevent browser's default download stuff...
    FileSaver.saveAs(fileUrl, download);
  };
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="preview_table_wrapper">
            <Row>
              <Col>
                <div className="title_box">
                  <h3>Your eTV Application Details</h3>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table bordered>
                  {applicationViewData.map((data) => (
                    <tbody>
                      <tr>
                        <td className="bg-warning">
                          <div className="viewdata border-0">
                            Application id no. : -
                          </div>
                        </td>
                        <td className="bg-warning">
                          {" "}
                          <div className="viewdata border-0">
                            {data.temporary_id}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            Given Name : -{" "}
                          </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            {data.given_name}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">Surname : - </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            {data.surname}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">
                            Telephone : -{" "}
                          </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            {data.telephone}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">Email : - </div>
                        </td>
                        <td>
                          <div className="viewdata border-0"> {data.email}</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0"> Gender : - </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            {data.gender}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">
                            Date of Birth : -{" "}
                          </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {moment(data.dob).format("DD/MM/YYYY")}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">
                            Country / Region of Birth: -
                          </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            {data.country_birth}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            Country of Nationality : -{" "}
                          </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            {data.country_nationality}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">
                            Residential Address : -{" "}
                          </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            {data.residential_address}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">
                            State / Province : -{" "}
                          </div>
                        </td>
                        <td>
                          <div className="viewdata border-0"> {data.state}</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">
                            Address During Visit (Address in Cambodia): -
                          </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            {data.address_visit}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">
                            Passport No : -{" "}
                          </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            {data.passport_no}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            Passport Date Issue : -{" "}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className="viewdata border-0">
                            {moment(data.passport_date_issue).format(
                              "DD/MM/YYYY"
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            Passport Date Expiry : -{" "}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className="viewdata border-0">
                            {moment(data.passport_date_expiry).format(
                              "DD/MM/YYYY"
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            Country Of Passport : -{" "}
                          </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            {data.country_of_passport}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">
                            Intended Date of Entry: -{" "}
                          </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {moment(data.intended_date_of_entry).format(
                              "DD/MM/YYYY"
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">
                            Port of Entry : -{" "}
                          </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            {data.port_of_entry}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">
                            Visa Type : -{" "}
                          </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            {data.visa_type}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">
                            Entry purpose : -{" "}
                          </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            {data.entry_purpose}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">
                            Other Purpose : -{" "}
                          </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            {data.other_purpose}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0"> purpose : - </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            {data.Purpose}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            IP Address : -{" "}
                          </div>
                        </td>
                        <td>
                          <div className="viewdata border-0">
                            {" "}
                            {data.ip_address}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <p className="mt-3">Document Details</p>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <Row>
                            <Col>
                              <div className="d-flex mt-1 justify-content-start">
                                <div className="Application_photo">
                                  <h6> Application Photo</h6>
                                  <img
                                    src={`https://api.cambodiaevisa.org/${data.application_photo}`}
                                    width={100}
                                  ></img>
                                  <a
                                    className="dbtnp"
                                    href={`https://api.cambodiaevisa.org/${data.application_photo}`}
                                    onClick={(e) =>
                                      downloadURI(
                                        e,
                                        `https://api.cambodiaevisa.org/${data.application_photo}`,
                                        data.application_photo
                                      )
                                    }
                                    // download={data.application_photo}`
                                  >
                                    Download
                                  </a>
                                </div>
                                <div className="Application_photo">
                                  <h6> Passport</h6>
                                  <img
                                    src={`https://api.cambodiaevisa.org/${data.passport_image}`}
                                    width={100}
                                  ></img>
                                  <a
                                    className="dbtnp"
                                    href={`https://api.cambodiaevisa.org/${data.passport_image}`}
                                    onClick={(e) =>
                                      downloadURI(
                                        e,
                                        `https://api.cambodiaevisa.org/${data.passport_image}`,
                                        data.passport_image
                                      )
                                    }
                                  >
                                    Download
                                  </a>
                                </div>
                                <div className="Application_photo">
                                  <h6> Hotel Reservation</h6>
                                  <img
                                    src={`https://api.cambodiaevisa.org/${data.hotel_reservation}`}
                                    width={100}
                                  ></img>
                                  <a
                                    className="dbtnp"
                                    href={`https://api.cambodiaevisa.org/${data.hotel_reservation}`}
                                    onClick={(e) =>
                                      downloadURI(
                                        e,
                                        `https://api.cambodiaevisa.org/${data.hotel_reservation}`,
                                        data.hotel_reservation
                                      )
                                    }
                                  >
                                    Download
                                  </a>
                                </div>
                                <div className="Application_photo">
                                  <h6> Other Document</h6>
                                  <img
                                    src={`https://api.cambodiaevisa.org/${data.other_document}`}
                                    width={100}
                                  ></img>
                                  <a
                                    className="dbtnp"
                                    href={`https://api.cambodiaevisa.org/${data.other_document}`}
                                    onClick={() =>
                                      downloadURI(
                                        e,
                                        `https://api.cambodiaevisa.org/${data.other_document}`,
                                        data.other_document
                                      )
                                    }
                                  >
                                    Download
                                  </a>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
