import React, { useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import {
  authUser,
  showErrorToast,
  validateNumber,
} from "../components/common";
import { commonAPICall } from "../services/services";
const AuthLogin = () => {
  const [loading, setLoading] = useState(false);
  const [authParams, setAuthParams] = useState({
    mobile: "",
    password: "",
    otp: "",
  });
  const [showOTP, setShowOTP] = useState(false);
  const handleChange = (event) => {
    setAuthParams({ ...authParams, [event.target.name]: event.target.value });
  };

  const handleGetOtp = async (e) => {

    if (authParams.mobile == "") {
      showErrorToast("Please Enter Mobile Number Or Email")
    } else if (authParams.password == "") {
      showErrorToast("Please Enter Password")
    } else {
      e.preventDefault();
      setLoading(true);
      try {
        const response = await commonAPICall("/auth/login", "post", {
          user_name: authParams.mobile,
          password: authParams.password
        });
        if (response.status === 200) {
          setShowOTP(true);
        } else {
          showErrorToast(response.message);
        }
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    }
  };
  const submitSign = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await commonAPICall("/auth/check", "post",authParams);
      if (response.status === 200) {
        authUser(response.token);
        window.location.reload();
      } else {
        showErrorToast(response.message);
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="login-box shadow">
        <img src="/assets/images/etourist.png" />

        <h3 className=" mt-2 fw-bold">Welcome to India Visa</h3>
        <p className="mb-4 fw-medium text-muted">Sign in to continue</p>
        <Form onSubmit={showOTP ? submitSign : handleGetOtp}>
          {!showOTP && (
            <FloatingLabel
              controlId="emailFloating"
              label="Enter mobile number and password"
              className="mb-3"
            >
              <Form.Control
                value={authParams.mobile}
                onChange={handleChange}
                type="text"
                className="fw-bold"
                autoFocus
                // onKeyDown={validateNumber}
                name="mobile"
              />
            </FloatingLabel>
          )}
          {!showOTP && (
            <FloatingLabel
              controlId="emailFloating"
              label="Password"
              className="mb-3"
            >
              <Form.Control
                value={authParams.password}
                onChange={handleChange}
                type="password"
                className="fw-bold"
                autoFocus
                // onKeyDown={validateNumber}
                name="password"
              />
            </FloatingLabel>
          )}
          {showOTP &&
            (
              <FloatingLabel controlId="otpFloating" label="OTP" className="mb-3">
                <Form.Control
                  value={authParams.otp}
                  onChange={handleChange}
                  type="text"
                  className="fw-bold"
                  maxLength={4}
                  autoFocus
                  onKeyDown={validateNumber}
                  name="otp"
                />
              </FloatingLabel>
            )}

          <button
            disabled={loading}
            type="submit"
            className="btn btn-primary w-100"
          >
            {loading ? "Please wait.." : showOTP ? "Submit" : "Send OTP"}
          </button>
        </Form>
      </div>
      <div className="login-page">
        <div className="bg-overlay"></div>
        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1440 120"
          >
            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
          </svg>
        </div>
      </div>
    </>
  );
};



export default AuthLogin;
