import { Container } from "react-bootstrap";

export default function Footer() {
  return (
    <footer>
      <Container className="text-center">
        <p>All Rights Reserved eVisa Admin.</p>
      </Container>
    </footer>
  );
}
