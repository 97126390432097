import React, { useState, useEffect } from "react";
import { commonAPICall } from "../../services/services";
import FileSaver from "file-saver";
import moment from "moment";
import { Table } from "react-bootstrap";
export default function preview() {
  const [data, setApplicationViewData] = useState({});
  useEffect(() => {
    const previewData = async () => {
      const id = window.location.pathname.split("/");
      try {
        const result = await commonAPICall(
          "/sri/SL_Application_Main_tourist_individual_View",
          "post",
          {
            temporary_id: id[3],
          }
        );

        setApplicationViewData(result.data);
      } catch (err) {
        console.error(err);
      }
    };
    previewData();
  }, []);

  const downloadURI = (e, fileUrl, download) => {
    e.preventDefault(); // Prevent browser's default download stuff...
    FileSaver.saveAs(fileUrl, download);
  };
  return (
    <>
      <div className="preview_table_wrapper">
        <div className="title_box">
          <h3>Your eTV Application Details</h3>
        </div>
        {/* {applicationViewData.map((data) => ( */}
        <div className="datatab_d">
          <div className="viewdata text-white" style={{backgroundColor: 'orangered'}}> Application ID No.</div>
          <div className="viewdata text-white" style={{backgroundColor: 'orangered'}}> {data.temporary_id}</div>

          <div className="viewdata"> Ip Address </div>
          <div className="viewdata"> {data.ip_address}</div>
          <div className="viewdata"> Visa </div>
          <div className="viewdata"> {data.visa_type}</div>

          <div className="viewdata">Surname/ family name </div>
          <div className="viewdata"> {data.surname_family_name}</div>

          <div className="viewdata"> Other / given Name </div>
          <div className="viewdata"> {data.other_given_name}</div>

          <div className="viewdata"> Title </div>
          <div className="viewdata"> {data.title}</div>

          <div className="viewdata"> Date of Birth </div>
          <div className="viewdata"> {moment(data.dob).format("MM-DD-YYYY")}</div>

          <div className="viewdata"> Gender </div>
          <div className="viewdata"> {data.gender}</div>

          <div className="viewdata">Nationality </div>
          <div className="viewdata"> {data.nationality}</div>

          <div className="viewdata">
            Are you fully Vaccinated for Covid-1{" "}
          </div>
          <div className="viewdata"> {data.isVaccinated}</div>

          <div className="viewdata">Country Of Birth </div>
          <div className="viewdata"> {data.country_of_birth}</div>

          <div className="viewdata"> Occupatio </div>
          <div className="viewdata"> {data.occupation}</div>

          <div className="viewdata">Passport No </div>
          <div className="viewdata"> {data.passport_no}</div>

          <div className="viewdata"> Passport Date Issue </div>
          <div className="viewdata"> {moment(data.passport_issue_date?.replace("Z", "")).format("MM-DD-YYYY")}</div>

          <div className="viewdata"> Passport Date Expiry </div>
          <div className="viewdata"> {moment(data.passport_expiry_date?.replace("Z", "")).format("MM-DD-YYYY")}</div>
          {/* 
            <div className="title_box">
              Child information in parent's passpor{" "}
            </div>
            <div className="title_box">.</div> */}

          {data.child_details?.length > 0 && (
            <>
              <div className="viewdata orange">Child Information</div>
          
              <div className="viewdata bg-warning p-2" style={{ gridColumn: 'span 2', marginBottom: '20px' }}>
                <Table striped bordered size="sm" className="bg-white">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Surname</th>
                      <th>Other Names</th>
                      <th>Date of Birth</th>
                      <th>Gender</th>
                      <th>Relationship</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.child_details.map((el, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{el.surname_family_name}</td>
                        <td>{el.other_given_name}</td>
                        <td>{moment(el.dob).format('MM-DD-YYYY')}</td>
                        <td>{el.gender}</td>
                        <td>{el.relationship}</td>
                      </tr>
                    ))}

                  </tbody>
                </Table>
              </div>
            </>
          )}

          <div className="viewdata">
            Where you have been during last 14 days before this travel{" "}
          </div>
          <div className="viewdata"> {data.have_been_in_14_days}</div>

          <div className="viewdata"> Intended Arrival Date </div>
          <div className="viewdata"> {moment(data.arrival_date?.replace("Z", "")).format("MM-DD-YYYY")}</div>



          <div className="viewdata"> Purpose of Visit </div>
          <div className="viewdata"> {data.purpose_of_visit}</div>

          {data.application_type === "Business Individual" && (<>
            <div className="viewdata"> Purpose of Description </div>
          <div className="viewdata"> {data.purpose_description}</div>
          </>)}

          <div className="viewdata"> Port of Departure </div>
          <div className="viewdata"> {data.port_of_departure}</div>

          <div className="viewdata"> Airline/Vessel </div>
          <div className="viewdata"> {data.airline_vessel}</div>

          <div className="viewdata"> Flight/Vessel Number </div>
          <div className="viewdata"> {data.Flight_vessel_no}</div>
          {/* ============================= contact Details===================== */}

          <div className="viewdata orange">Contact Details</div>
      

          {data.application_type === "Business Individual" && <>
            <div className="viewdata">  Company Name</div>
            <div className="viewdata"> {data.company_name}</div>
          </>}

          <div className="viewdata"> Address Line 1 </div>
          <div className="viewdata"> {data.address1}</div>

          <div className="viewdata"> Address Line 2 </div>
          <div className="viewdata"> {data.address2}</div>

          <div className="viewdata"> City </div>
          <div className="viewdata"> {data.city}</div>

          <div className="viewdata"> State </div>
          <div className="viewdata"> {data.state}</div>

          <div className="viewdata"> Zip/Postal Cod </div>
          <div className="viewdata"> {data.postal_code}</div>

          <div className="viewdata"> Country </div>
          <div className="viewdata"> {data.country}</div>

          <div className="viewdata"> Address in Sri Lank </div>
          <div className="viewdata"> {data.address_in_srilanka}</div>

          <div className="viewdata"> Telephone Number </div>
          <div className="viewdata"> {data.telephone_no}</div>

          <div className="viewdata"> Mobile Number </div>
          <div className="viewdata"> {data.mobile_no}</div>

          <div className="viewdata"> Fax Number </div>
          <div className="viewdata"> {data.fax_no}</div>

          <div className="viewdata"> Email Address </div>
          <div className="viewdata"> {data.email}</div>



          {data.application_type === "Business Individual" && (
            <>
              <div className="viewdata orange">Contact Details of the Sri Lankan Company</div>
          
              <div className="viewdata">Company name</div>
              <div className="viewdata">{data.sl_company_name}</div>
              <div className="viewdata">Address Line 1</div>
              <div className="viewdata">{data.sl_address1}</div>
              <div className="viewdata">Address Line 2</div>
              <div className="viewdata">{data.sl_address2}</div>
              <div className="viewdata">City</div>
              <div className="viewdata">{data.sl_city}</div>
              <div className="viewdata">State</div>
              <div className="viewdata">{data.sl_state}</div>
              <div className="viewdata">Zip/Postal Code</div>
              <div className="viewdata">{data.sl_postal_code}</div>
              <div className="viewdata">Telephone Number</div>
              <div className="viewdata">{data.sl_telephone_no}</div>
              <div className="viewdata">Mobile Number</div>
              <div className="viewdata">{data.sl_mobile_no}</div>
              <div className="viewdata">Fax Number</div>
              <div className="viewdata">{data.sl_fax_no}</div>
              <div className="viewdata">Email Address</div>
              <div className="viewdata">{data.sl_email}</div>
            </>
          )}


          <div className="viewdata">
            {" "}
            1. Do you have a valid residence visa to Sri Lanka?{" "}
          </div>
          <div className="viewdata"> {data.dec_valid_residence_visa}</div>

          <div className="viewdata">
            {" "}
            2 . Are you currently in Sri Lanka with a valid ETA or obtained an
            extension of visa?{" "}
          </div>
          <div className="viewdata"> {data.dec_valid_eta_extension}</div>

          <div className="viewdata">
            {" "}
            3 . Do you have a multiple entry visa to Sri Lanka?{" "}
          </div>
          <div className="viewdata"> {data.dec_multiple_entry_visa}</div>






          {/*  document  */}
          <div className="viewdata orange"> Dcouments </div>
      

          {data.passport_path && (
            <>
              <div className="viewdata"> User Passport </div>
              <div className="viewdata">
                <img
                  src={`https://indvisa.org/UploadSrilanka/${data.temporary_id}/${data.passport_path}`}
                  width={150} />

                <button
                  onClick={(e) =>
                    downloadURI(
                      e,
                      `https://indvisa.org/UploadSrilanka/${data.temporary_id}/${data.passport_path}`,
                      data.document12_name
                    )
                  }
                >
                  {" "}
                  download
                </button>
              </div>
            </>
          )}
        </div>
        {/* ))} */}
      </div>
    </>
  );
}
