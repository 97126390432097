import { BrowserRouter, useNavigate, useRoutes } from "react-router-dom";
import { routes } from "./routes";

import "./style.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
const RoutesSub = ({ data }) => {
	const router = useRoutes(routes());
	return router;
};

function App() {
	const userRightData = useSelector((state) => state.userRightData.value);
	return (
		<>
			<BrowserRouter>
				<RoutesSub data={userRightData} />
			</BrowserRouter>
			<ToastContainer />
		</>
	);
}

export default App;
