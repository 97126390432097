import React, { useState, useEffect } from "react";
import { commonAPICall } from "../../services/services";
import FileSaver from "file-saver";
import moment from "moment";
import { Table } from "react-bootstrap";
export default function PreviewVet() {
  const [data, setApplicationViewData] = useState({});
  const [passportDetail, setpassportDetail] = useState([]);
  const [ViolationDetail, setvoilationDetail] = useState([]);
  const [vietnamVisitDetails, setvietnamVisitDetails] = useState([]);
  const [relativeDetails, setrelativeDetails] = useState([]);
  const [Under14, setUnder14] = useState([]);
  useEffect(() => {
    const previewData = async () => {
      const id = window.location.pathname.split("/");
      console.log(id);
      try {
        const result = await commonAPICall(
          "/vn/VN_Application_full_preview",
          "post",
          {
            temporary_id: id[3],
          }
        );

        setApplicationViewData(result.data);
        setpassportDetail(result.otherPasswords);
        setvoilationDetail(result.violationDetails);
        setvietnamVisitDetails(result.vietnamVisitDetails);
        setrelativeDetails(result.relativeDetails);
        setUnder14(result.Under14);
      } catch (err) {
        console.error(err);
      }
    };
    previewData();
  }, []);

  const downloadURI = (e, fileUrl, download) => {
    e.preventDefault();
    FileSaver.saveAs(fileUrl, download);
  };

  return (
    <>
      <div className="preview_table_wrapper">
        <div className="title_box">
          <h3>Your eTV Application Details</h3>
        </div>

        {/* {applicationViewData.map((data) => ( */}
        <div className="datatab_d bg-light border">
          <div
            className="viewdata text-white"
            style={{ backgroundColor: "orangered" }}
          >
            {" "}
            Application id no.{" "}
          </div>
          <div
            className="viewdata text-white"
            style={{ backgroundColor: "orangered" }}
          >
            {" "}
            {data.temporary_id}
          </div>

          {/* <div className="viewdata orange"> Application ID No.</div>
          <div className="viewdata orange"> {data.temporary_id}</div> */}

          <div className="viewdata"> Ip Address </div>
          <div className="viewdata"> {data.ip_address}</div>

          <div className="viewdata"> Visa </div>
          <div className="viewdata"> {data.visa_type}</div>

          <div className="viewdata">Surname/ family name </div>
          <div className="viewdata"> {data.surname}</div>

          <div className="viewdata"> Other / given Name </div>
          <div className="viewdata"> {data.given_name}</div>

          <div className="viewdata"> Date of Birth </div>
          <div className="viewdata">
            {" "}
            {moment(data.dob).format("MM-DD-YYYY")}
          </div>
          <div className="viewdata"> Place of Birth </div>
          <div className="viewdata"> {data.place_of_birth}</div>

          <div className="viewdata"> Gender </div>
          <div className="viewdata"> {data.gender}</div>

          <div className="viewdata">Nationality </div>
          <div className="viewdata"> {data.nationality}</div>
          <div className="viewdata">Religion </div>
          <div className="viewdata"> {data.religion}</div>
          <div className="viewdata">ID Card Number </div>
          <div className="viewdata"> {data.id_card_number}</div>
          <div className="viewdata">Email </div>
          <div className="viewdata"> {data.email}</div>

          {data.used_other_passport == "Yes" && passportDetail.length > 0 ? (
            <>
              <div className="viewdata orange">
                {" "}
                Have you ever used any other passports to enter into VietNam? ?{" "}
              </div>
              <div className="viewdata" style={{ gridColumn: "1/3" }}>
                <Table bordered>
                  <thead>
                    <tr style={{ backgroundColor: "#ddd" }}>
                      <th>Passport No</th>
                      <th>Full Name</th>
                      <th>Date of Birth</th>
                      <th>Nationality</th>
                    </tr>
                  </thead>
                  <tbody>
                    {passportDetail.map(
                      (val, i) =>
                        val.full_name && (
                          <tr key={i}>
                            <td>{val.passport_no}</td>
                            <td>{val.full_name}</td>
                            <td>
                              {moment(val.dob).format("DD/MM/YYYY")}
                            </td>
                            <td>{val.nationality}</td>
                          </tr>
                        )
                    )}
                  </tbody>
                </Table>
              </div>
            </>
          ) : (
            ""
          )}

          {/* Do you have multiple nationalities? */}

          <div className="viewdata"> Do you have multiple nationalities? </div>
          <div className="viewdata">
            {data.multiple_nationality ? "Yes" : "No"}
          </div>

          {/* Violation of the Vietnamese laws/regulations (if any) */}

          {data.voilation_of_laws == "Yes" && ViolationDetail.length > 0 ? (
            <>
              <div className="viewdata orange">
                Violation of the Vietnamese laws/regulations (if any){" "}
              </div>
              <div className="viewdata" style={{ gridColumn: "1/3" }}>
                <Table bordered>
                  <thead>
                    <tr style={{ backgroundColor: "#ddd" }}>
                      <th>Act of violation</th>
                      <th>Time of violation</th>
                      <th>Form of sanction</th>
                      <th>Authority imposed sanction</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ViolationDetail.map((val, i) => (
                      <tr key={i}>
                        <td>{val.act_of_violation}</td>
                        <td>{val.authority}</td>
                        <td>
                          {moment(val.date_of_violation).format("DD/MM/YYYY")}
                        </td>
                        <td>{val.form_of_sanction}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          ) : (
            ""
          )}

          <div className="viewdata orange"> Requested Information </div>
          <div className="viewdata">e-Visa request information </div>
          <div className="viewdata"> {data.entry_type}</div>

          <div className="viewdata"> Grant Evisa valid from </div>
          <div className="viewdata">
            {" "}
            {moment(data.grant_evisa_valid_from).format("DD/MM/YYYY")}
          </div>

          <div className="viewdata">Grant Evisa valid To </div>
          <div className="viewdata">
            {moment(data.grant_evisa_valid_to).format("DD/MM/YYYY")}
          </div>

          <div className="viewdata orange"> Passport Information </div>

          <div className="viewdata"> Passport Type</div>
          <div className="viewdata"> {data.passport_type}</div>

          <div className="viewdata"> Passport No </div>
          <div className="viewdata">{data.passport_number}</div>
          <div className="viewdata"> Issuing Authority/Place of issue </div>
          <div className="viewdata">{data.passport_issuing_authority}</div>
          <div className="viewdata">Passport Issue date </div>
          <div className="viewdata">
            {moment(data.passport_issue_date).format("DD/MM/YYYY")}
          </div>
          <div className="viewdata">Passport Expiry Date</div>
          <div className="viewdata">
            {moment(data.passport_expiry_date).format("DD/MM/YYYY")}
          </div>

          {/* Do you hold any other valid passports? */}

          {data.hold_other_passport == "Yes" ? (
            <>
              <div className="viewdata orange">
                {" "}
                Do you hold any other valid passports?{" "}
              </div>

              <div className="viewdata"> Passport Type</div>
              <div className="viewdata"> {data.other_passport_type}</div>
              <div className="viewdata"> Passport No </div>
              <div className="viewdata">{data.other_passport_number}</div>
              <div className="viewdata"> Issuing Authority/Place of issue </div>
              <div className="viewdata">
                {data.other_passport_issuing_authority}
              </div>
              <div className="viewdata">Passport Issue date </div>
              <div className="viewdata">
                {moment(data.other_passport_issue_date).format("DD/MM/YYYY")}
              </div>
              <div className="viewdata">Passport Expiry Date</div>
              <div className="viewdata">
                {moment(data.other_passport_expiry_date).format("DD/MM/YYYY")}
              </div>
            </>
          ) : (
            ""
          )}

          {/* Contact infromation */}
          <div className="viewdata orange"> Contact infromation </div>
          <div className="viewdata"> Permanent residential address </div>
          <div className="viewdata">{data.permanent_address}</div>
          <div className="viewdata">Contact address</div>
          <div className="viewdata">{data.contact_address}</div>

          <div className="viewdata orange"> Emergency Contacts </div>
          <div className="viewdata"> Full Name </div>
          <div className="viewdata">{data.emergency_full_name}</div>
          <div className="viewdata">Current residential address </div>
          <div className="viewdata">{data.current_residence_address}</div>
          <div className="viewdata"> Telephone number </div>
          <div className="viewdata">{data.telephone_number}</div>
          <div className="viewdata">Relationship </div>
          <div className="viewdata">{data.relationship}</div>

          {/* Occupation */}

          <div className="viewdata orange"> Occupation </div>
          <div className="viewdata"> Occupation </div>
          <div className="viewdata">{data.occupation}</div>

          <div className="viewdata orange"> Occupation Information </div>
          <div className="viewdata"> Name of Company/Agency/School</div>
          <div className="viewdata">{data.company_name}</div>
          <div className="viewdata">Position/Course of study </div>
          <div className="viewdata">{data.position}</div>
          <div className="viewdata">Address </div>
          <div className="viewdata">{data.company_address}</div>
          <div className="viewdata"> Telephone number </div>
          <div className="viewdata">{data.company_telephone}</div>

          {/* Occupation */}

          <div className="viewdata orange"> Information about the trip </div>
          <div className="viewdata"> Purpose of visit</div>
          <div className="viewdata">{data.purpose_of_visit}</div>

          <div className="viewdata orange">
            {" "}
            Agency/Organization/Individual that the applicant plans to contact
            when enter into Vietnam{" "}
          </div>
          <div className="viewdata"> Name of hosting organisation</div>
          <div className="viewdata">{data.hosting_organisation}</div>
          <div className="viewdata">Address </div>
          <div className="viewdata">{data.organisation_address}</div>
          <div className="viewdata">Telephone number </div>
          <div className="viewdata">{data.organisation_telephone}</div>
          <div className="viewdata"> Purpose</div>
          <div className="viewdata">{data.hosting_purpose}</div>

          <div className="viewdata">
            Intended length of stay in Vietnam (number of days)
          </div>
          <div className="viewdata">{data.length_of_stay}</div>
          <div className="viewdata">Intended date of entry </div>
          <div className="viewdata">
            {moment(data.date_of_entry).format("DD/MM/YYYY")}
          </div>
          <div className="viewdata">Allowed to entry through checkpoint </div>
          <div className="viewdata">{data.entry_through}</div>
          <div className="viewdata">Exit through checkpoint </div>
          <div className="viewdata">{data.exit_through}</div>

          <div className="viewdata">
            Intended temporary residential address in Vietnam{" "}
          </div>
          <div className="viewdata">{data.temporary_address}</div>
          <div className="viewdata">City/Province </div>
          <div className="viewdata">{data.temporary_city}</div>
          <div className="viewdata">Phone number (in Vietnam) </div>
          <div className="viewdata">{data.temporary_phone}</div>

          {/* Have you been to Vietnam in the last 01 year? */}

          {data.visit_in_one_year == "Yes" && vietnamVisitDetails.length > 0 ? (
            <>
              <div className="viewdata orange">
                {" "}
                Have you been to Vietnam in the last 01 year?
              </div>
              <div className="viewdata" style={{ gridColumn: "1/3" }}>
                <Table bordered>
                  <thead>
                    <tr style={{ backgroundColor: "#ddd" }}>
                      <th>From Date</th>
                      <th>To Date</th>
                      <th>Purpose</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vietnamVisitDetails.map((val, i) => (
                      <tr key={i}>
                        <td>{moment(val.from_date).format("DD/MM/YYYY")}</td>
                        <td>{moment(val.to_date).format("DD/MM/YYYY")}</td>
                        <td>{val.purpose}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          ) : (
            ""
          )}

          {/* Do you have relatives who currently reside in Vietnam? */}

          {data.relatives_in_vietnam == "Yes" && relativeDetails.length > 0 ? (
            <>
              <div className="viewdata orange">
                {" "}
                Do you have relatives who currently reside in Vietnam?
              </div>
              <div className="viewdata" style={{ gridColumn: "1/3" }}>
                <Table bordered>
                  <thead>
                    <tr style={{ backgroundColor: "#ddd" }}>
                      <th>Full name</th>
                      <th>Date of Birth</th>
                      <th>Nationality</th>
                      <th>Relationship</th>
                      <th>Residential address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {relativeDetails.map((val, i) => (
                      <tr key={i}>
                        <td>{val.full_name}</td>
                        <td>
                          {moment(val.date_of_birth).format("DD/MM/YYYY")}
                        </td>
                        <td>{val.nationality}</td>
                        <td>{val.relationship}</td>
                        <td>{val.address}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          ) : (
            ""
          )}

          {/* Under 14 years old accompanying child(ren) included in your passport (IF ANY) */}

          <div className="viewdata orange">
            Under 14 years old accompanying child(ren) included in your passport
            (IF ANY)
          </div>

          {Under14?.length ? (
            <div className="viewdata" style={{ gridColumn: "1/3" }}>
              <Table bordered>
                <thead>
                  <tr style={{ backgroundColor: "#ddd" }}>
                    <th>Full name</th>
                    <th>Gender</th>
                    <th>Date of Birth</th>
                    <th>Portrait photography</th>
                  </tr>
                </thead>
                <tbody>
                  {Under14?.map((val, i) => (
                    <tr key={i}>
                      <td>{val.full_name}</td>
                      <td>{val.gender}</td>
                      <td>{moment(val.date_of_birth).format("DD/MM/YYYY")}</td>

                      <td>
                        <img
                          src={`https://vietnamapi.etouristservices.in/${val.image}`}
                          style={{ maxWidth: "100px" }}
                        />
                        <button
                          onClick={(e) =>
                            downloadURI(
                              e,
                              `https://vietnamapi.etouristservices.in/${val.image}`,
                              val.image
                            )
                          }
                        >
                          Download
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            ""
          )}

          {/* Trip's expense, insurance */}

          <div className="viewdata orange"> Trip's expense, insurance </div>

          <div className="viewdata"> Intended expenses (in USD)e </div>
          <div className="viewdata"> {data.intended_expenses}</div>

          {/* need to add style */}

          <div className="viewdata">
            Who will cover the trip’s expenses of the applicant
          </div>
          <div className="viewdata">{data.trip_expenses}</div>

          {data.trip_expenses === "Other agency/organization/individual" ? (
            <>
              <div className="viewdata">Type</div>
              <div className="viewdata">{data.trip_expenses_type}</div>
              <div className="viewdata">Name of hosting organisation</div>
              <div className="viewdata">{data.trip_hosting_organisation}</div>

              <div className="viewdata">Address</div>
              <div className="viewdata">{data.trip_hosting_address}</div>

              <div className="viewdata">Telephone number</div>
              <div className="viewdata">{data.trip_hosting_telephone}</div>
            </>
          ) : (
            <div className="viewdata">The applicant</div>
          )}

          {/* need to add style */}

          {data.health_insurance === "Yes" ? (
            <>
              <div className="viewdata orange">
                Does the applicant have health insurance arranged for their stay
                in VietNam?
              </div>
              <div className="viewdata">Detail</div>
              <div className="viewdata">{data.health_insurance_details}</div>
            </>
          ) : (
            <div>No</div>
          )}

          {/*  document  */}

          <div className="viewdata orange">Documents</div>

          <div className="viewdata"> User Image </div>
          <div className="viewdata">
            {" "}
            <img
              width={150}
              src={`https://vietnamapi.etouristservices.in/${data.profile_image}`}
            ></img>{" "}
            <button
              onClick={(e) =>
                downloadURI(
                  e,
                  `https://vietnamapi.etouristservices.in/${data.profile_image}`,
                  data.profile_image
                )
              }
            >
              Download
            </button>
          </div>
          <>
            {data.passport_image && (
              <>
                <div className="viewdata">
                  Copy of Passport page containing personal particulars{" "}
                </div>
                <div className="viewdata">
                  <img
                    width={150}
                    src={`https://vietnamapi.etouristservices.in/${data.passport_image}`}
                  ></img>{" "}
                  <button
                    onClick={(e) =>
                      downloadURI(
                        e,
                        `https://vietnamapi.etouristservices.in/${data.passport_image}`,
                        data.passport_image
                      )
                    }
                  >
                    Download
                  </button>
                </div>
              </>
            )}
          </>
        </div>
        {/* ))} */}
      </div>
    </>
  );
}
