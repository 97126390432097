import moment from "moment";
import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Table, Dropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import BrijCustomDataTable from "../../components/brijDataTable";
import { FaCog } from "react-icons/fa";
import {
  BrijDateRange,
  showErrorToast,
  showSucessToast,
} from "../../components/common";
import Modal from "react-bootstrap/Modal";

import { commonAPICall } from "../../services/services";

import { previewLiveUrl } from "../../config";
import VietnamPopup from "./VietnamPopup";

const user_id = JSON.parse(localStorage.getItem("auth"))?.user_id;
const PaidApplicationBrowse = () => {
  const pageTitle = useLocation().pathname.split("/")[2];
  // const [paymentData, setpaymentData] = useState([]);
  const [applicationStatus, setApplicationStatus] = useState([]);
  const [updateApplication, setupdateApplication] = useState(false);
  const [etadata, setEtadata] = useState([]);
  const [application, setapplication] = useState();

  const [tempid, setTempid] = useState({
    temporary_id: " ",
    tran_id: "",
  });
  const [formdata, setFormdata] = useState({
    temporary_id: null,
    govt_permanent_id: "",
    govt_temporary_id: null,
    eta_no: null,
    fees: "",
    user_id: user_id,
  });
  const [selectSalesStatus, setselectSalesStatus] = useState({
    tran_id: 0,
  });
  const [selectDeoApplication, setselectDeoApplication] = useState({
    tran_id: 0,
  });
  const [selectStatusApplication, setselectStatusApplication] = useState({
    tran_id: 0,
  });
  const [deoExecutiveUsers, setdeoExecutiveUsers] = useState([]);
  const [subBodyQuery, setsubBodyQuery] = useState({
    application_id: "",
    passport_number: "",
    email: "",
  });
  const [bodyQuery, setbodyQuery] = useState({
    application_id: "",
    passport_number: "",
    email: "",
    nationality: "",
    global_application_id: "",
    from_date: "",
    to_date: "",
    travel_from_date: "",
    travel_to_date: "",
    application_type: "",
    sales_manager_status:"",
    sales_status:"",
    deo_manager_status:"",
    deo_status:"",
    visa_service: "",
    visa_priority:"",
    agent_id: "",
    status: "",
    payment_from_date:"",
    payment_to_date:"",
    filter_column: "",
    search: "",
    pageSize: 15,
    pageNo: 1,
    reload: 0,
  });

  
  const [apibody, setapibody] = useState({
    from_date: null,
    to_date: null,
    travel_from_date: null,
    travel_to_date: null,
    payment_from_date:null,
    payment_to_date:null,
    agent_id: null,
  });

  const [selectVisaStatus, setselectVisaStatus] = useState({
    tran_id: 0,
  });
  const handlechangeSub = (e) => {
    const obj = { application_id: "", passport_number: "", email: "" };
    obj[e.target.name] = e.target.value;
    setsubBodyQuery(obj);
    if (e.key === "Enter") {
      setbodyQuery({
        ...bodyQuery,
        application_id: obj.application_id,
        passport_number: obj.passport_number,
        email: obj.email,
      });
      setsubBodyQuery({ application_id: "", passport_number: "", email: "" });
    }
  };

  const handleChangeBody = (e) => {
    setapibody({ ...apibody, [e.target.name]: e.target.value });
  };
  

  const etaDetails = async (id) => {
    try {
      const result = await commonAPICall(
        "/vn/application_govt_detail_list",
        "post",
        {
          temporary_id: id,
        }
      );
      setEtadata(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const handleUpdateGovernmentId = async () => {
    try {
      const result = await commonAPICall(
        "/vn/vn_update_govt_id_india",
        "post",
        formdata
      );
      if (result.status == 200) {
        showSucessToast("Government ID and Application Status Updated");
        window.location.reload();
      }
      setupdateApplication(false);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const handlechanger = (event) => {
    setFormdata({ ...formdata, [event.target.name]: event.target.value });
  };

  const [showType, setshowType] = useState(false);

  const governmentUpdate = (temporary_id, tran_id) => {
    setTempid({ ...tempid, temporary_id: temporary_id, tran_id: tran_id });
    setFormdata({ ...formdata, temporary_id: temporary_id });
    setupdateApplication(true);
    etaDetails(temporary_id);
  };
  const openRemarks = (e, tran_id) => {
    setapplication(tran_id);
    setshowType(!showType);
  };



  let previewWindow = null;

  const handlePreviewAction = (temporary_id, tran_id) => {
    const url = `${previewLiveUrl}/preview/vietnam/${temporary_id}/${tran_id}`;
    const windowOptions = "width=600,height=600,scrollbars=yes";
    // window.open(url, "_blank", windowOptions);
    if (!previewWindow || previewWindow.closed) {
      previewWindow = window.open(url, "_blank", windowOptions);
    } else {
      // If the window is minimized, bring it to the front
      if (previewWindow.outerWidth === 0) {
        previewWindow.focus();
      }
      // Refresh the content
      previewWindow.location.href = url;
    }
  };

  const updateAllDeoExecutiveList = async () => {
    try {
      const result = await commonAPICall("/vn/list_deo_users", "get", "");

      setdeoExecutiveUsers(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  // const selectBrowseSalesStatus = async () => {
  //   try {
  //     const result = await commonAPICall(
  //       "/vn/sl_browse_application_sales_status",
  //       "get",
  //       ""
  //     );
  //     setsalesStatus(result.data);
  //   } catch (err) {
  //     showErrorToast(err);
  //   }
  // };

  const updateDeoManagerStatus = async (e) => {
    try {
      const result = await commonAPICall("/vn/VN_update_deo_status", "post", {
        tran_id: selectDeoApplication?.tran_id,
        status: e.target.value,
      });
      if (result.status === 200) {
        setbodyQuery({ ...bodyQuery, reload: Math.random() * 3 });
        showSucessToast("Application Status Updated");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const updateVisaStatus = async (e) => {
    try {
      const result = await commonAPICall("/vn/update_visa_status", "post", {
        tran_id: selectVisaStatus.tran_id,
        status: e.target.value,
      });
      if (result.status === 200) {
        setbodyQuery({ ...bodyQuery, reload: Math.random() * 3 });
        showSucessToast("Visa Status Updated");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  // const paymentApiCall = async () => {
  //   try {
  //     const result = await commonAPICall("/vn/list_payment_amount", "post", {
  //       from_date: apibody.from_date,
  //       to_date: apibody.to_date,
  //     });
  //     setpaymentData(result.data);
  //   } catch (err) {
  //     showErrorToast(err);
  //   }
  // };

  const updateApplicationStatus = async () => {
    try {
      const result = await commonAPICall(
        "/vn/VN_browse_application_status",
        "get",
        { user_id: "" }
      );
      setApplicationStatus(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  useEffect(() => {
    // selectBrowseSalesStatus();
    updateAllDeoExecutiveList();
    updateApplicationStatus();
  }, []);
  const updateSalesStatus = async (e) => {
    try {
      const result = await commonAPICall(
        "/vn/VN_update_sales_status",
        "post",
        { tran_id: selectSalesStatus.tran_id, status: e.target.value }
      );
      if (result.status === 200) {
        setbodyQuery({ ...bodyQuery, reload: Math.random() * 3 });
        showSucessToast("Sales Status Update");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  // useEffect(() => {
  //   paymentApiCall();
  // }, [apibody.from_date, apibody.to_date]);

  const handleChange = (e) => {
    setbodyQuery({ ...bodyQuery, [e.target.name]: e.target.value });
  };

  const dateFilter = (date, type) => {
    const startDate = moment(date.startDate).format("MM/DD/YYYY");
    const endDate = moment(date.endDate).format("MM/DD/YYYY");
    if (type[2] === "application") {
      setapibody({ ...apibody, from_date: startDate, to_date: endDate });
    }
    if (type[2] === "payment") {
      setapibody({
        ...apibody,
        payment_from_date: startDate,
        payment_to_date: endDate,
      });
    }

    if (type[2] === "travel") {
      setapibody({
        ...apibody,
        travel_from_date: startDate,
        travel_to_date: endDate,
      });
    }
  };

  const handleshowType = (type) => {
    setshowType(type);
  };
  const columns = [
    {
      id: "Payment Datetime",
      name: "Payment Datetime",
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(
              row.payment_date ? row.payment_date.replace("Z", "") : null
            ).format("DD/MM/YYYY")}
            <span>
              {" "}
              {moment(
                row.payment_date ? row.payment_date.replace("Z", "") : null
              ).format("hh:mm:ss A")}
            </span>
          </div>
        );
      },
      sortable: true,
      omit: false,
      minWidth: "150px",
    },
    {
      id: "Application Date",
      name: "Application Date",
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(row.datetime ? row.datetime.replace("Z", "") : null).format(
              "DD/MM/YYYY"
            )}
            <span>
              {" "}
              {moment(
                row.datetime ? row.datetime.replace("Z", "") : null
              ).format("hh:mm:ss A")}
            </span>
          </div>
        );
      },

      sortable: true,
      omit: false,
      minWidth: "140px",
    },
    {
      id: "exp_date_of_arrival",
      name: "Travel Date",
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(
              row.exp_date_of_arrival
                ? row.exp_date_of_arrival.replace("Z", "")
                : null
            ).format("DD/MM/YYYY")}
            {/* <span> {moment(row.exp_date_of_arrival ? row.exp_date_of_arrival.replace("Z", "") : null).format("hh:mm:ss A")}</span> */}
          </div>
        );
      },
      sortable: true,
      omit: false,
      minWidth: "100px",
    },
    {
      id: "Application Id",
      name: "Application ID",
      selector: (row) => row.temporary_id,
      sortable: true,
      omit: false,
      minWidth: "145px",
    },


  {
    id: "passport_number",
    name: "Passport No.",
    selector: (row) => row.passport_number,
    sortable: true,
    omit: false,
    minWidth: "110px",
  },
  {
    id: "application_type",
    name: "Application Type",
    selector: (row) => row.application_type,
    sortable: true,
    omit: false,
    minWidth: "110px",
  },

  {
    id: "Name",
    name: "Applicant Name",
    selector: (row) => row.Name,
    cell: (row) => <div style={{ whiteSpace: "normal" }}>{row.Name}</div>,
    sortable: true,
    omit: false,
    minWidth: "150px",
  },

  {
    id: "visa_service",
    name: "Visa Service",
    selector: (row) => row.visa_service,
    sortable: true,
    omit: false,
    minWidth: "140px",
  },

    {
      id: "Applicant Email",
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => <div style={{ whiteSpace: "normal" }}>{row.email}</div>,
      sortable: true,
      omit: false,
      minWidth: "230px",
    },
    // {
    //   id: "Applicant Name",
    //   name: "Application Name",
    //   selector: (row) => row.Name,
    //   cell: (row) => <div style={{ whiteSpace: "normal" }}>{row.Name}</div>,
    //   sortable: true,
    //   omit: false,
    //   minWidth: "250px",
    // },
  
  
    //  {
    //     id: "visa_priority",
    //     name: "Visa Priority",
    //     selector: (row) => row.visa_priority,
    //     sortable: true,
    //     minWidth: "150px",
    //   },

    {
      id: "Sales Manager Status",
      name: "Sales Manager Status",
      selector: (row) => row.sales_manager_status,
      sortable: true,
      omit: false,
      minWidth: "170px",
    },
    {
      id: "sales_status",
      name: "Sales Status",
      minWidth: "193px",
      omit: false,
      selector: (row) => {
        return (
          <div className="w-100">
            {selectSalesStatus?.tran_id !== row.tran_id ? (
              <p
                className="grid_select_value"
                onMouseOver={() => setselectSalesStatus(row)}
              >
                {row.sales_status ? row.sales_status : "N/A"}
              </p>
            ) : (
              <select
                name="sale Status"
                className="grid_select_input"
                value={selectSalesStatus?.sales_status}
                onChange={updateSalesStatus}
                onMouseOut={() =>
                  setselectSalesStatus({ ...selectSalesStatus, tran_id: 0 })
                }
              >
                <option value={""}>N/A</option>
                <option value={"Paid"}>Paid</option>
                <option value={"Fresh Application"}>Fresh Application</option>
                <option value={"Will Pay Soon"}>Will Pay Soon</option>
                <option value={"No Call Response"}>No Call Response</option>
                <option value={"Voice Message"}>Voice Message</option>
                <option value={"Close"}>Close</option>
                <option value={"Others"}>Others</option>
              </select>
            )}
          </div>
        );
      },
      sortable: true,
    },
    {
      id: "DEO Manager Status",
      name: "DEO Manager Status",
      selector: (row) => row.deo_manager_status,
      sortable: true,
      omit: false,
      minWidth: "170px",
    },
    {
      id: "govt_permanent_id",
      name: "Government ID",
      selector: (row) =>
        row.govt_permanent_id ? row.govt_permanent_id : "N/A",
      sortable: true,
      omit: false,
      minWidth: "180px",
    },
    {
      id: "deo_status",
      name: "Application Status",
      minWidth: "193px",
      omit: false,
      selector: (row) => {
        return (
          <div className="w-100">
            {selectDeoApplication?.tran_id !== row.tran_id ? (
              <p
                className="grid_select_value"
                onMouseOver={() => setselectDeoApplication(row)}
              >
                {row.deo_status ? row.deo_status : "N/A"}
              </p>
            ) : (
              <select
                name="DEO Status"
                className="grid_select_input"
                value={selectSalesStatus?.deo_status}
                onChange={updateDeoManagerStatus}
                onMouseOut={() =>
                  setselectDeoApplication({ ...selectSalesStatus, tran_id: 0 })
                }
              >
                <option value={""}>N/A</option>
                <option value={"Paid"}>Paid</option>
                <option value={"Fresh Application"}>Fresh Application</option>
                <option value={"Will Pay Soon"}>Will Pay Soon</option>
                <option value={"No Call Response"}>No Call Response</option>
                <option value={"Voice Message"}>Voice Message</option>
                <option value={"Close"}>Close</option>
                <option value={"Others"}>Others</option>
              </select>
            )}
          </div>
        );
      },
      sortable: true,
    },
    {
      id: "Government Id",
      name: "Government Id",
      selector: (row) =>
        row.govt_permanent_id ? row.govt_permanent_id : "N/A",
      sortable: true,
      omit: false,
      minWidth: "180px",
    },
    {
      id: "Application Status",
      name: "Application Status",
      omit: false,
      selector: (row) => {
        return (
          <div className="w-100">
            {selectStatusApplication?.tran_id !== row.tran_id ? (
              <p
                className="grid_select_value"
                onMouseOver={() => setselectStatusApplication(row)}
              >
                {row.deo_status ? row.deo_status : "N/A"}
              </p>
            ) : (
              <select
                name="sale Status"
                className="grid_select_input"
                value={selectStatusApplication?.apl}
                onChange={updateSalesStatus}
                onMouseOut={() =>
                  setselectStatusApplication({
                    ...selectStatusApplication,
                    tran_id: 0,
                  })
                }
              >
                <option value={""}>N/A</option>
                <option value={"Fresh Application"}>Fresh Application</option>
                <option value={"Complete Application"}>
                  Complete Application
                </option>
                <option value={"Pending"}>Pending</option>
                <option value={"Wants to Cancle"}>Wants to Cancle</option>
                <option value={"Refund"}>Refund</option>
                <option value={"Hold"}>Hold</option>
                <option value={"Already have a ETA No"}>
                  Already have a ETA No
                </option>
                <option value={"Late Arrival"}>Late Arrival</option>
              </select>
            )}
          </div>
        );
      },
      sortable: true,
      minWidth: "193px",
    },
   
    {
      id: "visa_status",
      name: "Visa Status",
      omit: false,
      selector: (row) => {
        return (
          <div className="w-100">
            <div className="w-100">
              {selectVisaStatus?.tran_id !== row.tran_id ? (
                <p
                  className="grid_select_value"
                  onMouseOver={() => setselectVisaStatus(row)}
                >
                  {row.visa_status ? row.visa_status : "N/A"}
                </p>
              ) : (
                <select
                  name="sale Status"
                  className="grid_select_input"
                  value={selectVisaStatus?.visa_status}
                  onChange={updateVisaStatus}
                  onMouseOut={() =>
                    setselectVisaStatus({ ...selectVisaStatus, tran_id: 0 })
                  }
                >
                  <option value={""}>N/A</option>
                  <option value={"Fresh Application"}>Fresh Application</option>
                  <option value={"Will Pay Soon"}>Will Pay Soon</option>
                  <option value={"No Call Response"}>No Call Response</option>
                  <option value={"Voice Message"}>Voice Message</option>
                  <option value={"Close"}>Close</option>
                  <option value={"Others"}>Others</option>
                </select>
              )}
            </div>
          </div>
        );
      },
      sortable: true,
      width: "143px",
    },
    {
      id: "Sales Executive",
      name: "Sales Executive",
      selector: (row) => row.sales_executive,
      sortable: true,
      omit: false,
      minWidth: "200px",
    },
    {
      id: "DEO Executive",
      name: "DEO Executive",
      selector: (row) => (
        <div className="table__dttime">
          {row.doe_user_name}
          {row.doe_assign_datetime ? (
            <span>
              {moment(row.doe_assign_datetime.replace("Z", "")).format(
                "DD/MM/YYYY"
              )}{" "}
              <br />
              {moment(row.doe_assign_datetime.replace("Z", "")).format(
                "hh:mm:ss A"
              )}
            </span>
          ) : (
            "N/A"
          )}
        </div>
      ),
      sortable: true,
      omit: false,
      minWidth: "200px",
    },

    {
      id: "Payment",
      name: "Payment",
      selector: (row) => (
        <div style={{ whiteSpace: "normal" }}>{row.payment_status}</div>
      ),
      sortable: true,
      omit: false,
      minWidth: "90px",
    },
    {
      id: "Mail status",
      name: "Mail Status",
      // selector: (row) => row.mail_send,
      selector: (row) => {
        return (
          <div className="tbl__mailsent">
            send<sup>{row.partial_mail_count}</sup>
          </div>
        );
      },
      sortable: true,
      omit: false,
      minWidth: "97px",
    },
    {
      id: "Last Active",
      name: "Last Active",
      // selector: (row) => row.last_active,
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(
              row.last_active ? row.last_active.replace("Z", "") : null
            ).format("DD/MM/YYYY")}
            <span>
              {" "}
              {moment(
                row.last_active ? row.last_active.replace("Z", "") : null
              ).format("hh:mm:ss A")}
            </span>
          </div>
        );
      },
      sortable: true,
      omit: false,
      minWidth: "100px",
    },
    {
      id: "Site",
      name: "Site",
      selector: (row) => row.website_url,
      sortable: true,
      omit: false,
      minWidth: "160px",
    },
    // {
    //   id: "move_to_unpaid",
    //   name: "Move",
    //   omit: false,
    //   selector: (row) => {
    //     return (
    //       <div className="customBtn_p">
    //         <button
    //           onClick={() => handleShow(row.temporary_id, row.pay_amount)}
    //         >
    //           {" "}
    //           move to unpaid{" "}
    //         </button>
    //       </div>
    //     );
    //   },
    //   sortable: true,
    //   width: "auto",
    // },
  
    {
      id: "action",
      name: "Action",
      omit: false,
      selector: (row) => {
        return (
          <>
            <Dropdown className="action_dropdown">
              <Dropdown.Toggle variant="dark" size="sm">
                <FaCog />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={(e) => openRemarks(e, row.tran_id)}>
                  Remarks
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={(e) =>
                    handlePreviewAction(row.temporary_id, row.tran_id)
                  }
                >
                  View
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    governmentUpdate(row.temporary_id, row.tran_id)
                  }
                >
                  Update Application
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
      sortable: true,
      minWidth: "70px",
    },
  ];

  return (
    <div className="browse-list">
      <div className="application_form_wrapper">
        <div className="container-fluid">
          <div className="row">
            <Col md={2} className="mb-1">
            <Form.Control
                size="sm"
                value={subBodyQuery.application_id}
                onChange={handlechangeSub}
                onKeyUp={handlechangeSub}
                type="text"
                name="application_id"
                placeholder="Application ID"
              />
            </Col>
            <Col md={2} className="mb-1">
              <Form.Control
                size="sm"
                value={subBodyQuery.passport_number}
                onChange={handlechangeSub}
                onKeyUp={handlechangeSub}
                type="text"
                name="passport_number"
                placeholder="Passport No."
              />
            </Col>
            <Col md={2} className="mb-1">
              <Form.Control
                size="sm"
                value={subBodyQuery.email}
                onChange={handlechangeSub}
                onKeyUp={handlechangeSub}
                type="text"
                name="email"
                placeholder="Email"
              />
            </Col>

            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                name="agent_id"
                onChange={handleChangeBody}
              >
                <option value={""}>All Executives</option>
                {deoExecutiveUsers.map((value) => (
                  <option value={value.user_id} key={value.user_id}>
                    {value.full_name}
                  </option>
                ))}
              </Form.Select>
            </Col>

            <Col md={2} className="mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Application"
                pickClass={"right"}
              />
            </Col>

            <Col md={2} className="mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Travel"
                pickClass={"right"}
              />
            </Col>

            <Col md={2} className="mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Payment"
                pickClass={"right"}
              />
            </Col>

            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                name="application_type"
                onChange={handleChange}
              >
                <option value={""}>All Visa Type</option>
                <option value="Tourist">Tourist Individual</option>
                <option value="Business">Business Individual</option>
              </Form.Select>
            </Col>

            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                onChange={handleChange}
                name="visa_service"
              >
                <option value={""}>All Application Type</option>
                <option value="Normal">Normal Application</option>
                <option value="Urgent">Urgent Application</option>
              </Form.Select>
            </Col>

            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                onChange={handleChange}
                name="sales_manager_status"
              >
                <option value={""}>All Sales Manager Status</option>
                <option value="Not Assigned">Not Assigned</option>
                <option value="Assigned">Assigned</option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                name="sales_status"
                onChange={handleChange}
              >
               <option value="">All Sales Status</option>
                <option value="Fresh Application">Fresh Application</option>
                <option value="Will Pay Soon">Will Pay Soon</option>
                <option value="No Call Response">No Call Response</option>
                <option value="Voice Message">Voice Message</option>
                <option value="Paid">Paid</option>
                <option value="Close">Close</option>
                <option value="Others">Others</option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                onChange={handleChange}
                name="deo_manager_status"
              >
                <option value={""}>All DEO Manager Status</option>
                <option value="Not Assigned">Not Assigned</option>
                <option value="Assigned">Assigned</option>
              </Form.Select>
            </Col>
            <Col md={2} className="mb-1">
              <Form.Select
                size="sm"
                name="deo_status"
                onChange={handleChange}
              >
               <option value={""}>Application Status</option>
                <option value={"Fresh Application"}>Fresh Application</option>
                <option value={"Complete Application"}>
                  Complete Application
                </option>
                <option value={"Pending"}>Pending</option>
                <option value={"Wants to Cancle"}>Wants to Cancle</option>
                <option value={"Refund"}>Refund</option>
                <option value={"Hold"}>Hold</option>
                <option value={"Late Arrival"}>Late Arrival</option>
              </Form.Select>
            </Col>
          </div>
        </div>
      </div>
      <div className="table-responsive formRow3">
        <BrijCustomDataTable
          columns={columns}
          url="/vn/vn_main_browse_paid"
          apiBody={apibody}
          paramsBody={bodyQuery}
          assingUrl="/vn/VN_Application_assign_insert"
          type={"paid"}
        />
      </div>
      <VietnamPopup
        showtype={showType}
        handleshowType={handleshowType}
        data={application}
      />
      <Modal
        show={updateApplication}
        onHide={() => setupdateApplication(false)}
        dialogClassName="modal_body"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">
            Update ETA NO And Government Fees <br />
            Application ID : {tempid.temporary_id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Table bordered style={{ verticalAlign: "middle" }}>
                <tbody>
                  <tr>
                    <td>ETA Number</td>
                    <td>
                      {" "}
                      <Form.Control
                        size="sm"
                        type="text"
                        name="govt_permanent_id"
                        onChange={handlechanger}
                        value={formdata.govt_permanent_id}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Government Fees</td>
                    <td>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="fees"
                        onChange={handlechanger}
                        value={formdata.fees}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col md={12} className="mt-4">
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>ETA Number</th>
                    <th>Government Fees</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {etadata.length > 0 ? (
                    etadata.map((val, ind) => {
                      return (
                        <tr>
                          <td>{val.govt_permanent_id}</td>
                          <td>{val.fees}</td>
                          <td>
                            {" "}
                            <span>
                              {moment(val.payment_date).format("DD/MM/YYYY")} ,{" "}
                              <sapn>
                                {" "}
                                {moment(val.payment_date).format("hh:mm:ss A")}
                              </sapn>
                            </span>{" "}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={3} className="text-center mt-3">
                        No Data
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            size="sm"
            onClick={() => setupdateApplication(false)}
          >
            Cancel
          </Button>
          <Button
            variant="success"
            size="sm"
            onClick={handleUpdateGovernmentId}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
   
  
  );
};

export default PaidApplicationBrowse;
