import React from "react";
import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaEdit } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { FaEye, FaTrash } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { commonAPICall } from "../services/services";
import { Link, NavLink, useLocation } from "react-router-dom";
import { type } from "@testing-library/user-event/dist/type";
function MasterUser({ handleSelect, editData }) {
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const userDataList = async (type) => {
    try {
      const result = await commonAPICall("/auth/list_all_users", "post", {
        type: type,
      });
      if (result.valid) {
        setCountryData(result.data);
        setFilter(result.data);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const disableUser = async (value, user) => {
    try {
      const result = await commonAPICall("/auth/disable_user", "post", {
        value: value,
        user_id: user.user_id,
      });
      if (result.valid) {
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    userDataList(false);
  }, []);
  const handleSearch = (e) => {
    const result = countryData.filter((tabledatas) => {
      return tabledatas.first_name
        .toLowerCase()
        .match(e.target.value.toLocaleLowerCase());
    });
    setFilter(result);
  };
  const columns = [
    {
      id: "User ID",
      name: "User ID",
      selector: (row) => row.user_id,
      sortable: true,
      omit: false,
      minWidth: "100px",
    },
    {
      id: "User Name",
      name: "User Name",
      selector: (row) => row.first_name,
      sortable: true,
      omit: false,
    },

    {
      id: "Mobile No",
      name: "Mobile No",
      selector: (row) => row.mobile,
      sortable: true,
      omit: false,
    },

    {
      id: "Email",
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      omit: false,
      minWidth: "300px",
    },

    {
      id: "Department",
      name: "Department",
      selector: (row) => row.department,
      sortable: true,
      omit: false,
      minWidth: "200px",
    },
    {
      id: "Disable",
      name: "Disable",
      selector: (row) => (
        <input
          type="checkbox"
          id="filter"
          name="active"
          checked={row.disable}
          onChange={(e) => disableUser(e.target.checked, row)}
        />
      ),
      sortable: true,
      omit: false,
    },
    {
      id: "Action",
      name: "Action",
      omit: false,
      selector: (row) => {
        return (
          <>
            <ButtonGroup className="d-flex align-items-center">
              <FaEdit
                onClick={(e) => editData({ id: row.user_id, type: true })}
                size={20}
                className="text-primary"
                style={{ cursor: "pointer", marginRight: "10px" }}
              />

              <AiFillEye
                onClick={(e) => editData({ id: row.user_id, type: false })}
                size={20}
                className="text-secondary"
                style={{ cursor: "pointer", marginRight: "10px" }}
              />
            </ButtonGroup>
          </>
        );
      },
      sortable: true,
      minWidth: "200px",
    },
  ];
  return (
    <>
      <DataTable
        columns={columns}
        // data={countryData}
        pagination
        title="Master Users With Rights "
        data={filter}
        highlightOnHover
        fixedHeaderScrollHeight="400px"
        subHeader
        subHeaderComponent={
          <div className="d-flex align-items-center gap-2 w-50">
            <div
              style={{ flex: 1 }}
              className="d-flex justify-content-between align-items-center gap-2"
            >
              <div>
                <input
                  type="checkbox"
                  id="filter"
                  name="active"
                  onChange={(e) => userDataList(e.target.checked)}
                />
                <label htmlFor="filter" className="ms-3">Disable Users</label>
              </div>
              <Button variant="danger" onClick={() => handleSelect(1)}>
                Add
              </Button>
            </div>
            <input
              type="text"
              placeholder="Search User "
              className="form-control"
              onChange={handleSearch}
              style={{ flex: 2 }}
            />
          </div>
        }
      />
    </>
  );
}

export default MasterUser;
