
export default function FirstDisplay() {
  return (
    <>
      <div className="main_banner">
        <header>
          <img
            src="/assets/images/etourist.png"
            alt="etourist"
            className="logo"
          />
        </header>
        <div className="bg">
          <img
            src="/assets/images/big-banner.jpg"
            className="w-100 h-100"
            alt="etourist"
          />
        </div>
      </div>
    </>
  );
}
