import React from "react";
import { Card } from "react-bootstrap";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/header";

const Layout = () => {
  //   const location = window.location.pathname
  //   .split("/")
  //   .filter((val) => val !== "");

  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (location.length > 0) {
  //     navigate("/dashboard");
  //   }
  // }, []);
  return (
    <div className="erp-layout">
      <Header />
      <div className="container-fluid ">
        <Card>
          <Card.Body>
            <Outlet />
          </Card.Body>
        </Card>
      </div>      
      <Footer />
    </div>
  );
};

export default Layout;
