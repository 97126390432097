import moment from "moment";
import React, { useState, useEffect, useMemo } from "react";
import { Form, Button, Row, Col, Table, Dropdown } from "react-bootstrap";
import BrijCustomDataTable from "../../components/brijDataTable";
import ComboRemark from "./ComboPop";
import {
  BrijDateRange,
  showErrorToast,
  showSucessToast,
} from "../../components/common";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaEye, FaSyncAlt, FaEdit, FaCog } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { commonAPICall } from "../../services/services";
import { previewLiveUrl } from "../../config";
const PaidApplicationBrowse = () => {
  const [selectStatus, setselectStatus] = useState({ tran_id: 0 });
  const [selectStatusApplication, setselectStatusApplication] = useState({
    tran_id: 0,
  });
  const [paymentData, setpaymentData] = useState([]);
  const [application, setapplication] = useState();
  const [applicationStatus, setApplicationStatus] = useState([]);
  const [saleStatus, setSaleStatus] = useState([]);
  const [showType, setshowType] = useState(false);
  const [tempid, setTempid] = useState({
    temporary_id: " ",
    tran_id: "",
  });
  const [formdata, setFormdata] = useState({
    temporary_id: tempid.temporary_id,
    govt_permanent_id: "",
    govt_temporary_id: null,
    eta_no: null,
    fees: "",
  });
  const [bodyQuery, setbodyQuery] = useState({
    application_id: "",
    order_id: "",
    nationality: "",
    email: "",
    visitor_id: "",
    passport_no: "",
    sales_manager_status: "",
    sales_status: "",
    deo_manager_status: "",
    deo_status: "",
    payment_amount: 0,
    filter_column: "",
    search: "",
    visa_priority: "",
    pageSize: 15,
    pageNo: 1,
    agent_id: "",
  });

  const [apibody, setapibody] = useState({
    payment_to_date: null,
    payment_from_date: null,
    from_date: null,
    to_date: null,
    travel_to_date: null,
    travel_from_date: null,
  });
  const [show, setShow] = useState(false);
  const [showr, setShowr] = useState(false);
  const [etadata, setEtadata] = useState([]);
  const [paidData, setPaidData] = useState({
    amount: null,
    id: null,
  });
  const handleShow = (id, amount) => {
    setPaidData({ ...paidData, id: id, amount: amount });
    setShow(true);
  };
  const handleSubmit = async () => {
    try {
      const result = await commonAPICall("/cm/extra_payment_update", "post", {
        temporary_id: paidData.id,
        amount: paidData.amount,
      });
      if (result.valid) {
        setShow(false);
        showSucessToast("Success");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  const etaDetails = async (id) => {
    try {
      const result = await commonAPICall(
        "/cm/CM_application_govt_details_list",
        "post",
        {
          temporary_id: id,
        }
      );
      setEtadata(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };
  const handleSubmitr = async () => {
    try {
      const result = await commonAPICall(
        "/cm/CM_insert_govt_eta_no_update",
        "post",
        formdata
      );
      if (result.status == 200) {
        showSucessToast("Remarks Add successfully");
      }
      setShowr(false);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const handlechanger = (event) => {
    setFormdata({ ...formdata, [event.target.name]: event.target.value });
  };
  const governmentUpdate = (temporary_id, tran_id) => {
    setTempid({ ...tempid, temporary_id: temporary_id, tran_id: tran_id });
    setFormdata({ ...formdata, temporary_id: temporary_id });
    setShowr(true);
    etaDetails(temporary_id);
  };
  const openRemarks = (tran_id) => {
    setapplication(tran_id);
    setshowType(!showType);
  };

  const handleButtonClick = (temporary_id, tran_id) => {
    setTempid({ ...tempid, temporary_id: temporary_id, tran_id: tran_id });
    setFormdata({ ...formdata, temporary_id: temporary_id });
    const url = `${previewLiveUrl}/preview/combodia/${temporary_id}/${tran_id}`;
    const windowOptions = "width=600,height=400,scrollbars=yes";
    window.open(url, "_blank", windowOptions);
  };

  const paymentApiCall = async () => {
    try {
      const result = await commonAPICall("/cm/list_payment_amount", "post", {
        from_date: apibody.from_date,
        to_date: apibody.to_date,
      });
      setpaymentData(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };
  const updateStatus = async () => {
    try {
      const result = await commonAPICall(
        "/cm/cm_browse_application_status",
        "get",
        ""
      );
      setApplicationStatus(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };
  const updateApplicationSaleStatus = async () => {
    try {
      const result = await commonAPICall(
        "/cm/CM_browse_application_sales_status",
        "get",
        ""
      );

      setSaleStatus(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };
  useEffect(() => {
    updateStatus();
    updateApplicationSaleStatus();
  }, []);
  const updateSalesStatus = async (element) => {
    try {
      const body = {
        tran_id: selectStatus.tran_id,
        status: element.target.value,
      };
      setselectStatus({ ...selectStatus, sales_status: element.target.value });
      const result = await commonAPICall(
        "/cm/update_sales_status",
        "post",
        body
      );
      if (result.status === 200) {
        showSucessToast("Sales Status Update");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };
  const updateApplicationStatus = async (element) => {
    try {
      const body = {
        tran_id: selectStatusApplication.tran_id,
        status: element.target.value,
      };
      setselectStatusApplication({
        ...selectStatusApplication,
        deo_status: element.target.value,
      });
      const result = await commonAPICall("/cm/update_deo_status", "post", body);
      if (result.status === 200) {
        showSucessToast("Application Status Update");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  // useEffect(() => {
  //   paymentApiCall();
  // }, [apibody]);

  const [subBodyQuery, setsubBodyQuery] = useState({
    application_id: "",
    passport_no: "",
    email: "",
    nationality: "",
  });

  const handlechangeSub = (e) => {
    const obj = {
      application_id: "",
      passport_no: "",
      email: "",
      nationality: "",
    };
    obj[e.target.name] = e.target.value;
    setsubBodyQuery(obj);
    if (e.key === "Enter") {
      setbodyQuery({
        ...bodyQuery,
        application_id: obj.application_id,
        passport_no: obj.passport_no,
        email: obj.email,
        nationality: obj.nationality,
      });
      setsubBodyQuery({
        application_id: "",
        passport_no: "",
        email: "",
        nationality: "",
      });
    }
  };

  const handleChange = (e) => {
    setbodyQuery({ ...bodyQuery, [e.target.name]: e.target.value });
  };
  const handleSearch = (e) => {
    // let currentQuery = { ...queryParam };
    // currentQuery.search = e.target.value;
    // setQueryParam({ ...queryParam, search: e.target.value });
    // getBrowseData(currentQuery);
  };
  const dateFilter = (date, type) => {
    const startDate = moment(date.startDate).format("MM/DD/YYYY");
    const endDate = moment(date.endDate).format("MM/DD/YYYY");
    if (type[2] === "application") {
      setapibody({ ...apibody, from_date: startDate, to_date: endDate });
    }
    if (type[2] === "payment") {
      setapibody({
        ...apibody,
        payment_from_date: startDate,
        payment_to_date: endDate,
      });
    }
    if (type[2] === "travel") {
      setapibody({
        ...apibody,
        travel_from_date: startDate,
        travel_to_date: endDate,
      });
    }
  };
  const handleShowType = (type) => {
    setshowType(type);
  };
  const columns = useMemo(
    () => [
      {
        id: "payment_date",
        name: "Payment Date",
        selector: (row) => {
          return (
            <div className="table__dttime">
              {moment(
                row.payment_date ? row.payment_date.replace("Z", "") : null
              ).format("DD/MM/YYYY")}
              <span>
                {" "}
                {moment(
                  row.payment_date ? row.payment_date.replace("Z", "") : null
                ).format("hh:mm:ss A")}
              </span>
            </div>
          );
        },
        sortable: true,
        omit: false,
        minWidth: "110px",
      },
      {
        id: "datetime",
        name: "Application Date",
        selector: (row) => moment(row.datetime).format("MM/DD/YY"),
        sortable: true,
        omit: false,
        minWidth: "140px",
      },
      {
        id: "exp_date_of_arrival",
        omit: false,
        name: "Travel Date",
        selector: (row) => {
          return moment(
            row.exp_date_of_arrival
              ? row.exp_date_of_arrival.replace("Z", "")
              : null
          ).format("DD/MM/YYYY");
        },

        sortable: true,
        minWidth: "120px",
      },
      {
        id: "temporary_id",
        name: "Application ID",
        selector: (row) => row.temporary_id,
        sortable: true,
        minWidth: "145px",
        omit: false,
      },
      {
        id: "passport_no",
        name: "Passport No",
        selector: (row) => {
          return (
            <div>
              <span> {row.passport_no} </span>
            </div>
          );
        },
        sortable: true,
        minWidth: "110px",
      },
      {
        id: "Application Type",
        name: "Application Type",
        selector: (row) => row.application_type,
        sortable: true,
        minWidth: "150px",
        omit: false,
      },
      {
        id: "Applicant Name",
        name: "Applicant Name",
        omit: false,
        selector: (row) => (row.Name ? row.Name : "N/A"),
        cell: (row) => <div style={{ whiteSpace: "normal" }}>{row.Name}</div>,
        sortable: true,
        minWidth: "150px",
      },
      {
        id: "visa_service",
        name: "Visa Services",
        selector: (row) => row.visa_service,
        sortable: true,
        minWidth: "120px",
        omit: false,
      },
      {
        id: "email",
        name: "Applicant email",
        omit: false,
        cell: (row) => <div style={{ whiteSpace: "normal" }}>{row.email}</div>,
        sortable: true,
        minWidth: "170px",
      },
      {
        id: "visa_status",
        name: "Visa Status",
        omit: false,
        selector: (row) => row.visa_status,
        sortable: true,
        minWidth: "150px",
      },
      {
        id: "deo_manager_status",
        name: "DEO Manager Status",
        omit: false,
        selector: (row) => row.deo_manager_status,
        sortable: true,
        minWidth: "180px",
      },
      {
        id: "deo_status",
        name: "DEO Status",
        minWidth: "175px",
        omit: false,
        selector: (row) => {
          return (
            <div className="w-100">
              {selectStatus?.tran_id !== row.tran_id ? (
                <p
                  className="grid_select_value"
                  onMouseOver={() => setselectStatus(row)}
                >
                  {row.sales_status}
                </p>
              ) : (
                <select
                  name="sale Status"
                  className="grid_select_input"
                  value={selectStatus?.sales_status}
                  onChange={updateSalesStatus}
                >
                  {saleStatus.map((value) => (
                    <option value={value.status_name}>
                      {value.status_name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          );
        },
        sortable: true,
      },
      {
        id: "Application Status",
        name: "Application Status",
        omit: false,
        selector: (row) => {
          return (
            <div className="w-100">
              {selectStatusApplication?.tran_id !== row.tran_id ? (
                <p
                  className="grid_select_value"
                  onMouseOver={() => setselectStatusApplication(row)}
                >
                  {row.deo_status ? row.deo_status : "Update Status"}
                </p>
              ) : (
                <select
                  name="sale Status"
                  className="grid_select_input"
                  value={selectStatusApplication?.deo_status}
                  onChange={updateApplicationStatus}
                >
                  <option value={""}>Applicatin Status</option>
                  {applicationStatus.map((value) => (
                    <option value={value.status_name}>
                      {value.status_name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          );
        },
        sortable: true,
        width: "190px",
      },
      {
        id: "nationality",
        name: "Nationality",
        selector: (row) => (row.nationality ? row.nationality : "N/A"),
        sortable: true,
        minWidth: "110px",
        omit: false,
      },
      {
        id: "ref_no",
        name: "Reference No.",
        selector: (row) => (
          <div style={{ whiteSpace: "normal" }}>{row.ref_no}</div>
        ),
        sortable: true,
        minWidth: "150px",
        omit: false,
      },
      {
        id: "ref_no",
        name: "Reference Email",
        selector: (row) => (
          <div style={{ whiteSpace: "normal" }}>{row.email}</div>
        ),
        sortable: true,
        minWidth: "160px",
        omit: false,
      },
      {
        id: "payment",
        name: "Payment",
        selector: (row) => row.pay_amount,
        sortable: true,
        minWidth: "120px",
        omit: false,
      },
      {
        id: "mail_status",
        name: "Mail Status",
        selector: (row) => row.mail_send,
        sortable: true,
        minWidth: "97px",
        omit: false,
      },
      {
        id: "move_to_paid",
        name: "Move to Unpaid",
        omit: false,
        selector: (row) => {
          return (
            <div className="customBtn_p">
              <Button
                size="sm"
                variant="danger"
                onClick={() => handleShow(row.temporary_id, row.pay_amount)}
              >
                Move to unpaid
              </Button>
            </div>
          );
        },
        sortable: true,
        minWidth: "140px",
        center: true,
      },
      {
        id: "website_url",
        name: "Website",
        selector: (row) => row.website_url,
        sortable: true,
        omit: false,
        minWidth: "250px",
      },

      // {
      //   id: "visa_priority",
      //   name: "Visa Priority",
      //   selector: (row) => row.visa_priority,
      //   sortable: true,
      //   minWidth: "150px",
      // },
      // {
      //   id: "sales_status",
      //   name: "Sales Status",
      //   minWidth: "150px",
      //   omit: false,
      //   selector: (row) => {
      //     return (
      //       <div className="w-100">
      //         {selectStatus?.tran_id !== row.tran_id ? (
      //           <p
      //             style={{ cursor: "pointer" }}
      //             className="grid_select_value"
      //             onClick={() => setselectStatus(row)}
      //           >
      //             {row.sales_status}
      //           </p>
      //         ) : (
      //           <select
      //             name="sale Status"
      //             className="grid_select_input"
      //             value={selectStatus?.sales_status}
      //             onChange={updateSalesStatus}
      //           >
      //             {saleStatus.map((value) => (
      //               <option value={value.status_name}>
      //                 {value.status_name}
      //               </option>
      //             ))}
      //           </select>
      //         )}
      //       </div>
      //     );
      //   },
      //   width: "230px",
      //   sortable: true,
      // },

      {
        id: "doe_user_name",
        name: "Deo",
        omit: false,
        selector: (row) => {
          return (
            <div className="table__dttime">
              {row.doe_user_name}
              <span>
                {row.doe_assign_datetime
                  ? moment(row.doe_assign_datetime.replace("Z", "")).format(
                      "DD/MM/YYYY"
                    ) +
                    " " +
                    moment(row.doe_assign_datetime.replace("Z", "")).format(
                      "hh:mm:ss A"
                    )
                  : ""}
              </span>
            </div>
          );
        },
        sortable: true,
        minWidth: "130px",
      },

      // {
      //   id: "sales_manager_status",
      //   name: "Sales Manager Status",
      //   omit:false,
      //   selector: (row) => row.sales_manager_status,
      //   sortable: true,
      //   minWidth: "230px",
      // },

      // {
      //   id: "sales_user_name",
      //   name: "Sale Person Name",
      //   selector: (row) => row.sales_user_name,
      //   sortable: true,
      //   omit:false,
      //   minWidth: "230px",
      // },
      // {
      //   id: "pay_amount",
      //   name: "Pay Amount",
      //   selector: (row) => row.payment_status,
      //   sortable: true,
      //   omit:false,
      //   minWidth: "200px",
      // },
      {
        id: "Mail Send ",
        name: "Mail Send",

        selector: (row) => {
          return (
            <div className="tbl__mailsent">
              send<sup>{row.full_mail_count}</sup>
            </div>
          );
        },
        sortable: true,
        minWidth: "97px",
      },
      // {
      //   id: "Last Active",
      //   name: "Last Active",
      //   omit:false,
      //   selector: (row) => {
      //     return (
      //       <div className="table__dttime">
      //         {moment(
      //           row.last_active ? row.last_active.replace("Z", "") : null
      //         ).format("DD/MM/YYYY")}
      //         <span>
      //           {" "}
      //           {moment(
      //             row.last_active ? row.last_active.replace("Z", "") : null
      //           ).format("hh:mm:ss A")}
      //         </span>
      //       </div>
      //     );
      //   },
      //   sortable: true,
      //   minWidth: "150px",
      // },
      {
        id: "action",
        name: "Action",
        omit: false,
        selector: (row) => {
          return (
            <>
              <Dropdown className="action_dropdown">
                <Dropdown.Toggle variant="success" size="sm">
                  <FaCog />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => openRemarks(row.tran_id)}>
                    Remarks
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={(e) =>
                      handleButtonClick(row.temporary_id, row.tran_id)
                    }
                  >
                    View
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      governmentUpdate(row.temporary_id, row.tran_id);
                    }}
                  >
                    Update Application
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          );
        },
        sortable: true,
        width: "70px",
      },
    ],
    [selectStatusApplication, applicationStatus, saleStatus, selectStatus]
  );
  
  return (
    <div className="browse-list">
      <div className="application_form_wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 mb-1">
              <Form.Control
                 value={subBodyQuery.application_id}
                 onChange={handlechangeSub}
                 onKeyUp={handlechangeSub}
                type="text"
                name="application_id"
                placeholder="Application Id"
                size="sm"
              />
            </div>
            <div className="col-md-4">
              <Form.Control
                 value={subBodyQuery.email}
                 onChange={handlechangeSub}
                 onKeyUp={handlechangeSub}
                type="text"
                name="email"
                placeholder="Email"
                size="sm"
              />
            </div>
            <div className="col-md-2">
              <Form.Control
                 value={subBodyQuery.passport_no}
                 onChange={handlechangeSub}
                 onKeyUp={handlechangeSub}
                type="text"
                name="passport_no"
                placeholder="Passport No."
                size="sm"
              />
            </div>
            <div className="col">
              <Form.Control
                 value={subBodyQuery.nationality}
                 onChange={handlechangeSub}
                 onKeyUp={handlechangeSub}
                type="text"
                name="nationality"
                placeholder="Nationality"
                size="sm"
              />
            </div>
            <div className="col-md-2 mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Application"
                pickClass={"right"}
              />
            </div>
            <div className="col-md-2 mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Payment"
                pickClass={"right"}
              />
            </div>
            <div className="col-md-2 mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Travel"
                pickClass={"left"}
              />
            </div>
            <div className="col-md-2 mb-1">
              <Form.Select
                onChange={handleChange}
                name="visa_priority"
                size="sm"
              >
                <option value={""}>All Application Type</option>
                <option value={"Normal"}>Normal Application</option>
                <option value={"urgent"}>Urgent Application</option>
              </Form.Select>
            </div>
            {/* <div className="col-md-2 mb-1">
              <Form.Select
                name="sales_status"
                onChange={handleChange}
                size="sm"
              >
                <option value={""}>All Sales Status</option>
                {saleStatus.map((value) => (
                  <option value={value.status_name}>{value.status_name}</option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-2 mb-1">
              <Form.Select
                name="sales_manager_status"
                onChange={handleChange}
                size="sm"
              >
                <option value={""}>All Sales Manager Status</option>
                <option>Not Assigned</option>
                <option>Assigned</option>
              </Form.Select>
            </div> */}
            <div className="col-md-2 mb-1">
              <Form.Select size="sm" name="deo_status" onChange={handleChange}>
                <option value={""}> All Deo Status </option>
                {applicationStatus.map((value) => (
                  <option value={value.status_name}>{value.status_name}</option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-2 mb-1">
              <Form.Select
                name="deo_manager_status"
                onChange={handleChange}
                size="sm"
              >
                <option value={""}>All Deo Manager Status</option>
                <option>Not Assigned</option>
                <option>Assigned</option>
              </Form.Select>
            </div>
            {/* <div className="col-md-2 mb-1">
              <Form.Select
                name="payment_amount"
                onChange={handleChange}
                size="sm"
              >
                <option value={""}>All Payment Amount</option>
                {paymentData?.map((list) => (
                  <option>{list.pay_amount}</option>
                ))}
              </Form.Select>
            </div>
            <div className="col-md-2 mb-1">
              <Form.Select name="all_visa_days" onChange={handleChange}>
                <option value={""}>All Visa Days</option>
                <option>eTourist Visa(for 30 Days)</option>
                <option>eTourist Visa(for 1 Year)</option>
                <option>eTourist Visa(for 5 Years)</option>
              </Form.Select>
            </div> */}

            {/* <div className="col">
              <Form.Control
                size="sm"
                type="text"
                placeholder="Search"
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    handleSearch(e);
                  }
                }}
              />
            </div> */}
          </div>
        </div>
      </div>
      <div className="table-responsive">
       
          <BrijCustomDataTable
            columns={columns}
            url="/cm/main_Browse_Paid"
            apiBody={apibody}
            paramsBody={bodyQuery}
            assingUrl="/cm/CM_Application_assign_insert"
            type={"paid"}
          />
      
        {/* modal for move to unpaid */}
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title className="fs-5">
              Application Id : <span>{paidData.id}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center gap-3">
              <h5 className="flex-1">Amount:</h5>
              <Form.Control
                type="number"
                value={paidData.amount}
                onChange={(e) =>
                  setPaidData({ ...paidData, amount: e.target.value })
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" size="sm" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button variant="success" size="sm" onClick={handleSubmit}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        {/*  modal for Application update */}
        <Modal
          show={showr}
          onHide={() => setShowr(false)}
          dialogClassName="modal_body"
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title className="fs-5">
              Update ETA NO And Government Fees (Application ID :
              {tempid.temporary_id})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <Table bordered style={{ verticalAlign: "middle" }}>
                  <tbody>
                    <tr>
                      <td>ETA Number</td>
                      <td>
                        {" "}
                        <Form.Control
                          type="text"
                          name="govt_permanent_id"
                          onChange={handlechanger}
                          value={formdata.govt_permanent_id}
                          className="w-100 border p-1"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Government Fee</td>
                      <td>
                        {" "}
                        <Form.Control
                          type="text"
                          name="fees"
                          onChange={handlechanger}
                          value={formdata.fees}
                          className="w-100 border p-1"
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>

              <Col md={12} className="mt-4">
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>ETA Number</th>
                      <th>Government fees</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {etadata.length > 0 ? (
                      etadata.map((val, ind) => {
                        return (
                          <tr>
                            <td>{val.govt_permanent_id}</td>
                            <td>{val.fees}</td>
                            <td>{val.datetime}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={3} className="text-center mt-3">
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" size="sm" onClick={() => setShowr(false)}>
              Cancel
            </Button>
            <Button variant="success" size="sm" onClick={handleSubmitr}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <ComboRemark
        showtype={showType}
        handleshowType={handleShowType}
        data={application}
      />
    </div>
  );
};
export default PaidApplicationBrowse;
