import moment from "moment";
import React, { useState, useEffect, useMemo } from "react";
import { Form, Button, Row, Col, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import BrijCustomDataTable from "../../components/brijDataTable";
import axios from "axios";
import {
	BrijDateRange,
	showErrorToast,
	showSucessToast,
} from "../../components/common";
import ButtonGroup from "react-bootstrap/ButtonGroup";
const user_id = JSON.parse(localStorage.getItem("auth"))?.user_id;
const PaidApplicationBrowse = () => {
	const [value, setValue] = useState("");
	const validation = localStorage.getItem("department");
	const pageTitle = useLocation().pathname.split("/")[2];
	const [bodyQuery, setbodyQuery] = useState({
		application_id: "",
		order_id: "",
		nationality: "",
		email: "",
		visitor_id: "",
		passport_no: "",
		sales_manager_status: "",
		sales_status: "",
		deo_manager_status: "",
		deo_status: "",
		payment_amount: 0,
		filter_column: "",
		search: "",
		pageSize: 15,
		pageNo: 1,
	});
	const [apibody, setapibody] = useState({
		agent_id: "",
		travel_to_date: null,
		travel_from_date: null,
		from_date: null,
		to_date: null,
	});
	const handleChange = (e) => {
		setbodyQuery({ ...bodyQuery, [e.target.name]: e.target.value });
	};

	const handleChangess = (e) => {
		setValue({ ...value, [e.target.name]: e.target.value });
	};
	const handleSendMail = (email) => {
		axios
			.post(
				`${process.env.REACT_APP_LOCAL_DOMAIN}/mailformate/sendMailformate`,
				{ email: email, email_formate: value }
			)
			.then((result) => {
				if (result.status === 200) {
					showSucessToast(" email send successfully");
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};
	const columns = useMemo(
		() => [
			{
				id: "date",
				name: "Travel Date",
				selector: (row) => {
					return moment(row.date).format("DD/MM/YYYY");
				},
				sortable: true,
				minWidth: "150px",
			},
			{
				id: "date2",
				name: "Expire Date",
				selector: (row) => {
					return moment(row.date).format("DD/MM/YYYY");
				},
				sortable: true,
				minWidth: "150px",
			},
			{
				id: "Ticket Id",
				name: "Application ID",
				selector: (row) => {
					return (
						<div>
							<span> {row.temporary_id} </span>
						</div>
					);
				},
				sortable: true,
				minWidth: "250px",
			},
			{
				id: "email",
				name: "Email",
				selector: (row) => {
					return (
						<div>
							<span> {row.email} </span>
						</div>
					);
				},
				sortable: true,
				minWidth: "300px",
			},
			{
				id: "Mail Send ",
				name: "Mail Send",

				selector: (row) => {
					return (
						<div className="tbl__mailsent">
							send<sup>{row.full_mail_count}</sup>
						</div>
					);
				},
				sortable: true,
				minWidth: "100px",
			},
			{
				id: "Mail formate",
				name: "Mail formate",
				selector: (row) => {
					return (
						<Form.Select
							onChange={handleChangess}
							name="mail formate"
							size="md"
						>
							<option value={""}>mails</option>
							<option>mail-1</option>
							<option>mail-2</option>
							<option>mail-3</option>
							<option>mail-4</option>
						</Form.Select>
					);
				},
				sortable: true,
				minWidth: "200px",
			},
			{
				id: "action",
				name: "Action",
				selector: (row) => {
					return (
						<>
							<ButtonGroup>
								<Button onClick={(e) => handleSendMail({ email: row.email })}>
									send
								</Button>
							</ButtonGroup>
						</>
					);
				},
				sortable: true,
				width: "180px",
			},
		],
		[value]
	);

	return (
		<div className="browse-list">
			<div className="application_form_wrapper">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-4">
							<Form.Control
								value={bodyQuery.email}
								onChange={handleChange}
								type="text"
								name="email"
								placeholder="Email"
								size="md"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="table-responsive">
				<div className="table-container">
					<BrijCustomDataTable
						columns={columns}
						url="/cm/main_Browse_Paid"
						apiBody={apibody}
						paramsBody={bodyQuery}
					/>
				</div>
			</div>
		</div>
	);
};

export default PaidApplicationBrowse;
